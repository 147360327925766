import React from 'react';
import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import NotificationsProvider from '../notification-center/notifications-provider';

const ThemeLayout = ({children}) => (
	<ThemeProvider theme={theme}>
		<MuiThemeProvider theme={theme}>
			<NotificationsProvider>
				{children}
			</NotificationsProvider>
		</MuiThemeProvider>
	</ThemeProvider>
);

ThemeLayout.propTypes = {
	children: PropTypes.node.isRequired
};

export default ThemeLayout;
