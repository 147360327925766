import React from 'react';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import Notification from '../../common/notification-center';
import ThemeLayout from '../../common/theme';
import {screenSizes} from '../../common/theme/theme';
import Main from './main/index';
import Header from './header/index';
import Sidebar from './sidebar/index';
import BottomNavigation from './bottom-nav';
import {
	sideNavigationConfiguration,
	bottomNavigationConfiguration
} from './config';

class Layout extends React.Component {
	render() {
		const {children, pageName, user: {id, firstName, lastName, roles, level, myCommission, godsonsCommission, hasRoleToggle}} = this.props;
		const {sidebarOpen} = this.state;

		return (
			<ThemeLayout>
				<Header
					userId={id}
					pageName={pageName}
					userName={`${firstName} ${lastName}`}
					roles={roles}
					level={level}
					myCommission={myCommission}
					godsonsCommission={godsonsCommission}
					hasRoleToggle={hasRoleToggle}
					handleOpenSidebar={this.openSidebar}
					sidebarOpen={sidebarOpen}
				/>
				<Sidebar
					open={sidebarOpen}
					handleCloseSidebar={this.closeSidebar}
					data={sideNavigationConfiguration.reduce(this.navItemsReducer, [])}
				/>
				<Main sidebarOpen={sidebarOpen}>
					{children}
				</Main>
				{this.renderMobileNavigation()}
				<Notification/>
			</ThemeLayout>
		);
	}

	renderMobileNavigation() {
		if (!isMobile()) {
			return null;
		}

		return (
			<BottomNavigation
				data={bottomNavigationConfiguration.reduce(this.navItemsReducer, [])}
			/>
		);
	}

	openSidebar = () => this.setState({sidebarOpen: true});

	closeSidebar = () => this.setState({sidebarOpen: false});

	navItemsReducer = (result, item) => {
		const {user: {roles}} = this.props;

		const {disabledFor, hiddenFor, disabledAsHiddenInNav, children, ...rest} = item;

		const disabled = !!roles.find(value => disabledFor.includes(value));
		const hidden = hiddenFor && !!roles.find(value => hiddenFor.includes(value));
		
		if ((disabled && disabledAsHiddenInNav) || hidden) {
			return result;
		}

		const subItems = typeof children !== 'undefined' && children.reduce(this.navItemsReducer, []);
		
		result.push({
			disabled,
			hidden,
			children: subItems,
			...rest
		});

		return result;
	};

	state = {
		sidebarOpen: window.innerWidth > screenSizes.desktop
	};
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	user: PropTypes.object.isRequired,
	pageName: PropTypes.string
};

export default Layout;
