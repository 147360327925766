import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {isCustomForSellers} from '../custom-keys-helper';

export const RegularItem = React.memo(({t, classes, location, item, handleChangeLocation}) => {
	const {
		key, Icon, disabled, hidden, link, divider
	} = item;

	if (hidden) {
		return null;
	}

	const icon = Icon && (
		<ListItemIcon classes={{root: classes.iconRoot}}>
			<Icon classes={{root: classes.icon}} />
		</ListItemIcon>
	);

	const primaryItemText = isCustomForSellers(key) ? t(`sidebar.custom-for-sellers.${key}`) : t(`sidebar.${key}`);

	return (
		<div key={key} className={!!Icon ? classes.itemContainer : classes.subItemContainer}>
			<ListItem
				button
				selected={location.pathname === link}
				classes={{root: classes.item, selected: classes.selected}}
				onClick={disabled ? () => {} : () => handleChangeLocation(item)}
				disabled={disabled}
			>

				{icon}
				<ListItemText
					primary={primaryItemText}
					classes={{root: !!Icon ? classes.itemTextRoot : classes.subItemTextRoot, primary: classes.itemText}}
				/>
			</ListItem>
		</div>
	);
});