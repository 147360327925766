export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const login = loginData => ({
	type: AUTH_LOGIN,
	data: loginData
});

export const logout = () => ({
	type: AUTH_LOGOUT
});
