import {
	UserNavigationMenu,
	UserMobileNavigationBar
} from '../../../domain/navigation';
import {
	OutilsIcon,
	ProfileIcon,
	DocumentsIcon,
	PropertiesIcon,
	PropertiesIconAdd,
	SellersIcon,
	SellersPoolIcon,
	FinancialIcon,
	LevelIcon,
	GamificationIcon,
	FaqsIcon,
	UserManagementIcon,
	FamilyTreeIcon,
	CalendarIcon,
	ViewingsIcon,
	ZohoCustomersIcon,

	DashboardIcon,
	MyActivityIcon,
	MyAppsIcon,
	MyTeamIcon,
	MyTrainingIcon,
	MyCalendarIcon,
	SupportIcon,
	SupervisorAccountIcon,

	EmptyIcon,
	AlacarteIcon
} from './sidebar/icons';

const icons = {
	OutilsIcon,
	ProfileIcon,
	DocumentsIcon,
	PropertiesIcon,
	PropertiesIconAdd,
	SellersIcon,
	SellersPoolIcon,
	FinancialIcon,
	LevelIcon,
	GamificationIcon,
	FaqsIcon,
	UserManagementIcon,
	FamilyTreeIcon,
	CalendarIcon,
	ViewingsIcon,
	ZohoCustomersIcon,

	DashboardIcon,
	MyActivityIcon,
	MyAppsIcon,
	MyTeamIcon,
	MyTrainingIcon,
	MyCalendarIcon,
	SupportIcon,
	SupervisorAccountIcon,

	EmptyIcon,
	AlacarteIcon
};

const sideNavigationConfiguration = UserNavigationMenu.map((item) => { 
	if (typeof item.children !== 'undefined') {
		const {parentItem, children: unmappedChildren} = item;
		const {Icon: ParentIcon, ...parentNavItem} = parentItem;

		const children = unmappedChildren.map((subItem) => {
			const {Icon: SubItemIcon, ...navSubItem} = subItem;

			return {
				// Icon: icons[SubItemIcon],
				...navSubItem
			}
		});

		return {
			Icon: icons[ParentIcon],
			children,
			...parentNavItem
		}
	}

	const {Icon, ...navItem} = item;

	return {
		Icon: icons[Icon],
		...navItem
	}
});

const bottomNavigationConfiguration = UserMobileNavigationBar.map(({Icon, ...navItem}) => ({
	Icon: icons[Icon],
	...navItem
}));

export {sideNavigationConfiguration, bottomNavigationConfiguration};
