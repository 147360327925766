const config = {
	hosts: {
		base: process.env.REACT_APP_BASE_URL,
		identityServer: process.env.REACT_APP_SSO_AUTHORITY,
		webServerLocal: 'http://localhost:5001',
		keros: process.env.REACT_APP_KEROS_BASE_URL,
		pigesms: process.env.REACT_APP_PIGESMS_BASE_URL,
		virtualtour: process.env.REACT_APP_VIRTUALTOUR_BASE_URL,
		outils: process.env.REACT_APP_OUTILS_BASE_URL
	},
	googleKeys: {
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY
	},
	yousign: {
		url: process.env.REACT_APP_YOUSIGN_URL,
		uiIdentifier: process.env.REACT_APP_YOUSIGN_SIGNATURE_UIS
	}
};

if (process.env.NODE_ENV === 'production') {
	config.hosts.baseUrl = window.ssoConfiguration.baseUrl;
	config.hosts.identityServer = window.ssoConfiguration.identityServer;
	config.hosts.keros = window.ssoConfiguration.keros;
	config.hosts.pigesms = window.ssoConfiguration.pigesms;
	config.hosts.virtualtour = window.ssoConfiguration.virtualtour;
	config.hosts.outils = window.ssoConfiguration.outils;
	config.googleKeys.clientId = window.googleKeys.clientId;
	config.googleKeys.apiKey = window.googleKeys.apiKey;
	config.yousign.url = window.yousign.url;
	config.yousign.uiIdentifier = window.yousign.uiIdentifier;
}

Object.freeze(config.hosts);
Object.freeze(config.googleKeys);
Object.freeze(config.yousign);
Object.freeze(config);

export default config;
