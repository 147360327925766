import {showError} from '../actions/notifications';
import authClient from '../../utils/user-manager';
import makeRequest from '../../utils/http/make-request';

const downloadMiddleware = () => next => async action => {
	const {download} = action;

	if (!download) {
		return next(action);
	}

	if (!download.link) {
		throw new Error('download link should be provided');
	}

	const request = {
		method: 'get',
		url: `/api${download.link}`,
		responseType: 'blob'
	};
	const userInfo = authClient.getUser();

	if (download.withToken) {
		request.token = userInfo.token;
	}

	try {
		const res = await makeRequest(request);
		const {body, headers, header} = res;
		const contentType = download.contentType || body.type;
		const url = window.URL.createObjectURL(new Blob([body], {type: contentType}));
		const link = document.createElement('a');

		const contentDisposition = (headers && headers['content-disposition']) || (header && header['content-disposition']) || undefined;
		const matches = contentDisposition && contentDisposition.match(/filename\*=utf-8\'\'(?:")?(.*?)(?:")?(;|$)/)
		const filenameFromRes = matches && matches[1];
		const filename = download.filename || (filenameFromRes && decodeURIComponent(filenameFromRes)) || 'file';

		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);

		return next(action);
	} catch (error) {
		if (error.status === 500) {
			next(showError({message: `500:${error.response.statusText}`}));

			throw new Error(error.response.statusText);
		}

		if (error.status === 404) {
			next(showError({message: `404:${error.response.statusText}`}));

			throw new Error(error.response.statusText);
		}
	}
};

export default downloadMiddleware;
