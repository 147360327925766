import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	item: {
		fontSize: 16,
		lineHeight: '20px'
	}
}), {name: 'CardMenu'});
