export default ({
  "topText": "Vous souhaitez étendre votre réseau?<br/>Suivez ces quelques étapes et nous étudierons la candidature de votre filleul potentiel.",
  "title": "Ajouter un nouveau filleul",
  "fields": {
    "firstName": "Prénom",
    "lastName": "Nom",
    "email": "Email",
    "phoneNumber": "Téléphone",
    "commercialExperience": "A-t-il/elle une expérience commerciale?",
    "estateExperience": "A-t-il/elle une expérience dans l'immobilier?"
  },
  "checkbox": {
    "yes": "Oui",
    "no": "Non"
  },
  "buttons": {
    "next": "Suivant",
    "prev": "Retour",
    "submit": "Envoyer"
  },
  "success": "<strong>Toutes nos félicitations!</strong><br/>Votre candidature pour <strong><1>!#!name!#!</1></strong> a été envoyée.<br/><br/>Nous vous contacterons sous peu."
});

