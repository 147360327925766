export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';

export const forgottenPassword = data => ({
	type: FORGOTTEN_PASSWORD,
	data
});

export const restorePassword = data => ({
	type: RESTORE_PASSWORD,
	data
});
