import qs from 'qs';

const getViewingsBy = params => {
	const query = qs.stringify(params);
	const req = `/viewing?${query}`;

	return req;
};

export default {
	getViewingsBy
};
