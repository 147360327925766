import lodash from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router';
import Layout from '../components/client/layout';
import asyncComponent from '../utils/async-component';
import {defaultRouteFor, UserNavigationRoutes} from '../domain/navigation';
import backUrlManager from '../utils/back-url-helper';
import LayoutGroup from '../domain/navigation/helpers/layout-group';

const Components = {};
const Layouts = {};

function loadComponent(componentName) {
	return import(`../components/${componentName}`).then(module => module.default);
}

function loadRouteComponents(route) {
	if (route instanceof LayoutGroup) {
		const {layoutComponentName} = route;

		Layouts[layoutComponentName] = asyncComponent(() => loadComponent(layoutComponentName));
		route.routes.forEach(x => loadRouteComponents(x));
	} else {
		const {key, componentName} = route;

		Components[key] = asyncComponent(
			() => loadComponent(componentName)
		);
	}
}

UserNavigationRoutes.forEach(loadRouteComponents);

const redirectToUrl = (allowedRoutes, location) => {
	const backUrl = backUrlManager.getAndClearSoon();

	// we fall into redirectToUrl
	// when user tries to open any page that is not presented in allowedRoutes
	if (backUrl && backUrl === location.pathname) {
		const accessDenied = allowedRoutes.find(x => x.key === '403');

		if (typeof accessDenied !== 'undefined') {
			return accessDenied.link;
		}

		return allowedRoutes[0].link;
	}

	return backUrl || allowedRoutes[0].link;
};

const Tree = ({user, pageName, allowedRoutes, location}) => (
	<Layout user={user} pageName={pageName}>
		<Switch>
			{allowedRoutes.map(
				route => {
					if (route instanceof LayoutGroup) {
						const {layoutComponentName, master, routes} = route;
						const LayoutComponent = Layouts[layoutComponentName];

						return (
							<Route key={master.key} path={master.link}>
								<LayoutComponent key={layoutComponentName}>
									<Switch>
										{routes.map(({key, link}) => (
											<Route
												exact
												key={key}
												path={link}
												component={Components[key]}
											/>
										))}
									</Switch>
								</LayoutComponent>
							</Route>
						);
					}

					const {key, link} = route;

					return (
						<Route
							exact
							key={key}
							path={link}
							component={Components[key]}
						/>
					);
				}
			)}
			<Redirect to={redirectToUrl(allowedRoutes, location)}/>
		</Switch>
	</Layout>
);

class ClientRoutes extends React.Component {
	render() {
		const {user, location} = this.props;
		const {routes: allowedRoutes, defaultRedirectRoute} = this.state;
		const pageFound = UserNavigationRoutes.find(({link}) => link === location.pathname);
		const pageName = (pageFound && pageFound.title) || '';

		return (
			<Switch location={location}>
				<Route path="/:module/:submodule?/:item?">
					<Tree
						user={user}
						pageName={pageName}
						allowedRoutes={allowedRoutes}
						location={location}
					/>
				</Route>
				<Redirect to={defaultRedirectRoute.link}/>
			</Switch>
		);
	}

	static getDerivedStateFromProps(props, state) {
		if (lodash.isEqual(props.user.roles, state.roles)) {
			return null;
		}

		const defaultRedirectRoute = defaultRouteFor(props.user);

		const routes = UserNavigationRoutes.reduce((filteredRoutes, route) => {
			if (route instanceof LayoutGroup) {
				const {layoutComponentName, master, routes: groupRoutes} = route;
				const group = new LayoutGroup(
					layoutComponentName, master, groupRoutes.filter(x => x.allowedFor(props.user))
				);

				filteredRoutes.push(group);
				return filteredRoutes;
			}

			if (route.allowedFor(props.user)) {
				filteredRoutes.push(route);
			}

			return filteredRoutes;
		}, []);

		return {
			routes,
			defaultRedirectRoute,
			roles: props.user.roles
		};
	}

	state = {}
}

ClientRoutes.propTypes = {
	user: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

Tree.propTypes = {
	user: PropTypes.object.isRequired,
	pageName: PropTypes.string.isRequired,
	allowedRoutes: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired
};

export default ClientRoutes;
