/* eslint-disable no-console */
import oidc from 'oidc-client';
import request from 'superagent';
import {retryOnErrorAsync} from "./retry-on-error";
import {UserRoles} from '../domain/user/roles';

// logging
oidc.Log.logger = console;
oidc.Log.level = oidc.Log.INFO;

const START_DATE = new Date();
const getConsoleTime = () => {
	const END_TIME = new Date() - START_DATE;

	const minutes = Math.floor(END_TIME / 1000 / 60);
	const seconds = Math.floor(END_TIME / 1000) - (minutes * 60);

	return `${minutes}m ${seconds}s`;
};

let USER_INFO_URL = `${process.env.REACT_APP_BASE_URL}/api/user/info`;
const KEY_AUTH_TOKEN = 'mysxt_authtoken';
const KEY_SKIP_LANDING = 'mysxt_skiplanding';
const URL_SKIP_LANDIND = 'skiplanding';
const INITIAL_TOKEN = localStorage.getItem(KEY_AUTH_TOKEN) || null;
const INITIAL_SKIP_LANDING = window.location.search.indexOf(URL_SKIP_LANDIND) > -1
	|| !!localStorage.getItem(KEY_SKIP_LANDING)
	|| false;
const KEY_ROLE_SWITCH = 'mysxt_roleswitched';
const INITIAL_ROLE_SWITCHED = localStorage.getItem(KEY_ROLE_SWITCH) === 'true';

export const oidcConfig = {
	client_id: 'my-sextant',
	response_type: 'id_token token',
	scope: 'openid profile api1',
	authority: process.env.REACT_APP_SSO_AUTHORITY,
	redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-callback`,
	post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}`,
	loadUserInfo: false,
	revokeAccessTokenOnSignout: true,
	silent_redirect_uri: `${process.env.REACT_APP_BASE_URL}/silent-signin-callback`,
	accessTokenExpiringNotificationTime: 300, // 5 minutes before the end of access token life. token life: 1 hour
	automaticSilentRenew: false
};

if (process.env.NODE_ENV === 'production') {
	USER_INFO_URL = `${window.ssoConfiguration.baseUrl}/api/user/info`;
	oidcConfig.authority = window.ssoConfiguration.identityServer;
	oidcConfig.redirect_uri = `${window.ssoConfiguration.baseUrl}/signin-callback`;
	oidcConfig.post_logout_redirect_uri = window.ssoConfiguration.baseUrl;
	oidcConfig.silent_redirect_uri = `${window.ssoConfiguration.baseUrl}/silent-signin-callback`;
}

const oidcProvider = new oidc.UserManager(oidcConfig);

const user = {
	token: INITIAL_TOKEN,
	roles: undefined,
	skipLanding: INITIAL_SKIP_LANDING,
	hasRoleToggle: false
};

const filterRoles = role => !UserRoles[INITIAL_ROLE_SWITCHED ? 'isRoleRelatedToSeller' : 'isRoleRelatedToAgent'](role);

const setUser = ({
	id = -1, firstName = '', lastName = '', email = '', avatar = '', roles = [],
	myCommission = 0, godsonsCommission = 0, level = ''
}) => {
	// 1 person to blame...
	const sellerHasAgentRole = UserRoles.isAgent(roles) && UserRoles.isSeller(roles);

	user.id = parseInt(id, 10);
	user.firstName = firstName;
	user.lastName = lastName;
	user.email = email;
	user.avatar = avatar;
	user.allRoles = roles;
	user.hasRoleToggle = sellerHasAgentRole;
	user.roles = sellerHasAgentRole
		? roles.filter(filterRoles)
		: roles;
	user.level = level;
	user.myCommission = myCommission;
	user.godsonsCommission = godsonsCommission;
};

const switchUserRole = () => {
	if (user.hasRoleToggle) {
		localStorage.setItem(KEY_ROLE_SWITCH, !INITIAL_ROLE_SWITCHED);
		window.location.reload();
	}
};

const logOut = async () => {
	user.token = null;
	localStorage.removeItem(KEY_AUTH_TOKEN);
	localStorage.removeItem(KEY_ROLE_SWITCH);
	await oidcProvider.signoutRedirect();
};

const logIn = () => oidcProvider.signinRedirect();

function queryUserInfo() {
	const req = request.get(USER_INFO_URL);

	req.set('Authorization', `Bearer ${user.token}`);
	req.query();

	return req;
}

let silentSignInInProgress = false;
async function doSilentSignin() {
	if (silentSignInInProgress) {
		return;
	}

	silentSignInInProgress = true;
	let response;

	try {
		response = await retryOnErrorAsync(() => oidcProvider.signinSilent({
			scope: oidcConfig.scope,
			response_type: oidcConfig.response_type
		}), {retryCount: 5, retryTimeMillis: 500, jobName: 'signinSilent'});
	} catch (e) {
		console.log("Caught an error on silent sign-in", e)
		oidcProvider.getUser().then(userData => {
			localStorage.setItem(KEY_AUTH_TOKEN, userData.access_token);
			user.token = userData.access_token;
		});

		return;
	} finally {
		silentSignInInProgress = false;
	}

	localStorage.setItem(KEY_AUTH_TOKEN, response.access_token);
	user.token = response.access_token;
}

const getUserAsync = async forceToRemote => {
	if (typeof user.id !== 'undefined' && !forceToRemote) {
		return {...user};
	}

	if (!user.token) {
		return {};
	}

	const oidcUser = await oidcProvider.getUser();

	if (!oidcUser) {
		// test case: tab 1 - user logging in. tab 2 - user auto logged in.
		// tab 1 - user sign out. tab 2 - user has to be signed out in 2 sec
		// vise-versa could work well by the well.

		console.log(getConsoleTime(), 'SILENT SIGN IN');

		try {
			await oidcProvider.signinSilent();
		} catch (e) {
			console.error('Failed to make silent sign-in. Do log-in', e);
			return logIn();
		}
	}

	let response;
	try {
		response = await tryGetRemoteUserAsync();
	} catch (e) {
		console.error("Failed to get remote user", e);
		return {};
	}

	if (response && response.body) {
		setUser(response.body);
	} else {
		console.error('No response body')
		await logOut();
		return {};
	}

	return {...user};
};

async function tryGetRemoteUserAsync() {
	return await retryOnErrorAsync(async (cancel) => {
		try {
			return await queryUserInfo();
		} catch (queryUserInfoError) {
			if (queryUserInfoError.status !== 401) {
				throw queryUserInfoError;
			}

			try {
				await doSilentSignin()
				return await queryUserInfo();
			} catch (err) {
				console.log(getConsoleTime(), 'getUserAsync, user.token, oidcUser, USER_INFO_URL 401', err);
				await logOut();
				cancel();
				throw err;
			}
		}
	}, {retryCount: 5, retryTimeMillis: 500, jobName: 'queryUserInfo'});
}

const logInCallback = async () => {
	try {
		const userFromOidc = await oidcProvider.signinRedirectCallback();

		localStorage.setItem(KEY_AUTH_TOKEN, userFromOidc.access_token);
		localStorage.setItem(KEY_SKIP_LANDING, true);

		user.token = userFromOidc.access_token;
		user.skipLanding = true;

		await getUserAsync(true);

		return true;
	} catch (e) {
		// eslint-disable-next-line
		console.error(getConsoleTime(), 'LOGIN_CALLBACK', e);
		await logOut();
	}

	return false;
};

const signinSilentCallback = async () => {
	await oidcProvider.signinSilentCallback();
};

const getUser = () => ({...user});

const getToken = () => user.token;

oidcProvider.events.addAccessTokenExpired(async () => {
	console.log(getConsoleTime(), 'TOKEN expired...');

	await doSilentSignin();
});
oidcProvider.events.addAccessTokenExpiring(async () => {
	const oidcUser = await oidcProvider.getUser() || {};
	console.log(getConsoleTime(), 'TOKEN expiring in', oidcUser['expires_in'] || 'unknown');

	await doSilentSignin();
});

oidcProvider.events.addUserSignedOut(logOut);

const signUp = () => {
	window.location.href = `${oidcConfig.authority}/sign-up`;
};

const userManager = {
	getToken,
	getUser,
	getUserAsync,
	switchUserRole,
	logIn,
	logInCallback,
	signUp,
	signinSilentCallback,
	logOut,
	getStartingData: () => ({
		isAuthorized: user.token !== null,
		skipLanding: user.skipLanding
	})
};

export default userManager;
