export default () => ({
	root: {
		width: '100%',
		minHeight: '100%',
		background: 'url(./static/img/background.jpg) no-repeat 50%',
		backgroundSize: 'cover',
		position: 'relative',

		'&:before': {
			content: '""',
			display: 'block',
			width: '50%',
			height: '100%',
			position: 'absolute',
			top: 0,
			right: 0,
			backgroundColor: 'white'
		},
		'@media (max-width: 768px)': {
			'&:before': {
				display: 'none'
			}
		}
	},
	container: {
		position: 'relative'
	},
	column: {
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '50%',
		'@media (max-width: 768px)': {
			maxWidth: '100vw',
			display: 'block'
		}
	},
	wrapper: {
		width: '100%',
		maxWidth: 695,
		textAlign: 'center'
	},
	page: {
		width: '100%',
		height: '100vh',
		boxSizing: 'border-box',
		padding: '90px 25px 0 0',
		borderRadius: 0,
		overflow: 'auto',
		'@media (max-width:768px)': {
			padding: '0px 16px'
		}
	},
	logo: {
		width: 304,
		height: 80,
		marginTop: 90,
		cursor: 'pointer',
		'@media (max-width: 768px)': {
			width: '100%',
			margin: '20px 0px'
		}
	}
});
