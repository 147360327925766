import {PropertyTypes} from '../property-types';
import DocumentTypes from './types';

export default ({
	[PropertyTypes.MAISON]: [
		DocumentTypes.IDENTITY_DOCUMENT,
		DocumentTypes.PROPERTY_TITLE,
		DocumentTypes.PROPERTY_TAX,
		DocumentTypes.DIAGNOSTIC_TECHNIQUES,
		DocumentTypes.OTHERS,
		DocumentTypes.KBIS_EXCERPT,
		DocumentTypes.STATUTES
	],
	[PropertyTypes.APARTMENT]: [
		DocumentTypes.IDENTITY_DOCUMENT,
		DocumentTypes.PROPERTY_TITLE,
		DocumentTypes.DIAGNOSTIC_TECHNIQUES,
		DocumentTypes.PROPERTY_TAX,
		DocumentTypes.SHAREHOLDERS_MEETING_MINUTES,
		DocumentTypes.STATEMENT_OF_CHARGES,
		DocumentTypes.SURFACE_CARREZ,
		DocumentTypes.PLAN,
		DocumentTypes.OTHERS,
		DocumentTypes.KBIS_EXCERPT,
		DocumentTypes.STATUTES
	],
	[PropertyTypes.TERRAIN]: [
		DocumentTypes.PROPERTY_TITLE,
		DocumentTypes.IDENTITY_DOCUMENT,
		DocumentTypes.DIAGNOSTIC_TECHNIQUES,
		DocumentTypes.PROPERTY_TAX,
		DocumentTypes.OTHERS,
		DocumentTypes.KBIS_EXCERPT,
		DocumentTypes.STATUTES
	],
	[PropertyTypes.LMNP]: [
		DocumentTypes.QUESTIONNAIRE,
		DocumentTypes.PROPERTY_TITLE,
		DocumentTypes.IDENTITY_DOCUMENT,
		DocumentTypes.DIAGNOSTIC_TECHNIQUES,
		DocumentTypes.PROPERTY_TAX,
		DocumentTypes.SHAREHOLDERS_MEETING_MINUTES,
		DocumentTypes.RENT_INVOICES,
		DocumentTypes.PLAN,
		DocumentTypes.SURFACE_CARREZ,
		DocumentTypes.STATEMENT_OF_CHARGES,
		DocumentTypes.CIVIL_STATUS,
		DocumentTypes.COMMERCIAL_LEASE,
		DocumentTypes.APPENDICE_RENTAL_CONTRACT,
		DocumentTypes.OTHERS,
		DocumentTypes.KBIS_EXCERPT,
		DocumentTypes.STATUTES
	]
});

