import {Types as NotifTypes, Positions} from '../../components/common/notification-center';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export function showNotification({message, settings, actionsList = []}) {
	return {
		type: SHOW_NOTIFICATION,
		message,
		settings,
		actionsList
	};
}

export const showError = ({message = 'error:something-went-wrong', settings = {}, actionsList = [], oldError = false}) => ({
	type: SHOW_NOTIFICATION,
	oldError,
	message,
	settings: {
		variant: NotifTypes.ERROR,
		anchorOrigin: {
			vertical: Positions.Vertical.BOTTOM,
			horizontal: Positions.Horizontal.LEFT
		},
		...settings
	},
	actionsList
});

export const showSuccess = ({message, settings = {}, actionsList = []}) => ({
	type: SHOW_NOTIFICATION,
	message,
	settings: {
		variant: NotifTypes.SUCCESS,
		anchorOrigin: {
			vertical: Positions.Vertical.BOTTOM,
			horizontal: Positions.Horizontal.LEFT
		},
		...settings
	},
	actionsList
});

export const showWarning = ({message, settings = {}, actionsList = []}) => ({
	type: SHOW_NOTIFICATION,
	message,
	settings: {
		variant: NotifTypes.Warning,
		anchorOrigin: {
			vertical: Positions.Vertical.BOTTOM,
			horizontal: Positions.Horizontal.LEFT
		},
		...settings
	},
	actionsList
});

export const showInfo = ({message, settings = {}, actionsList = []}) => ({
	type: SHOW_NOTIFICATION,
	message,
	settings: {
		variant: NotifTypes.Info,
		anchorOrigin: {
			vertical: Positions.Vertical.BOTTOM,
			horizontal: Positions.Horizontal.LEFT
		},
		...settings
	},
	actionsList
});

export const showWorkHours = () => ({
	type: SHOW_NOTIFICATION,
	message: 'basic:notify.workHours',
	settings: {
		persist: true,
		variant: NotifTypes.INFO
	},
	actionsList: ['workHours', 'close']
});

export const resetNotifications = () => ({
	type: RESET_NOTIFICATION
});
