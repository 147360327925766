const PRIMARY_FORM = 'PRIMARY_FORM';
const SUPPLEMENTARY_FORM = 'SUPPLEMENTARY_FORM';
const AVATAR = 'AVATAR';
const DOCUMENT = 'DOCUMENT';

export default {
	DOCUMENT,
	PRIMARY_FORM,
	SUPPLEMENTARY_FORM,
	AVATAR
};
