import AuxiliaryField from './helpers/auxiliary';
import FieldType from '../../fields/field-type';

const KEYS = new AuxiliaryField('keys', FieldType.EMPTY);

const IN_DOCUMENTS = [KEYS];

export default ({
	KEYS, IN_DOCUMENTS
});
