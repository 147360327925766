import {UserRoles} from '../user/roles';
import Route, {NestedRoute} from './helpers/route';

const {
	UNVERIFIED_AGENT, AGENT, LMNP_AGENT, ADMIN, UNVERIFIED_SELLER, SELLER
} = UserRoles;

const sellerRoles = [UNVERIFIED_SELLER, SELLER];

const myCalendar = new Route('my-calendar', '/my-calendar', 'client/profile/calendar');

const agentCalendar = new Route('calendar', '/calendar', 'client/agent-calendar', {
	hiddenFor: [...sellerRoles, UserRoles.ADMIN],
	Icon: 'CalendarIcon'
});

const viewingsList = new Route('viewings', '/viewings', 'client/viewings-section', {
	disabledFor: [...sellerRoles, UNVERIFIED_AGENT],
	hiddenFor: [...sellerRoles],
	Icon: 'ViewingsIcon'
});

const viewingsReport = new Route('viewings-report', '/viewings-report', 'client/viewings-section', {
	disabledFor: [ADMIN, UNVERIFIED_AGENT, AGENT, UNVERIFIED_SELLER, LMNP_AGENT],
	hiddenFor: [ADMIN, UNVERIFIED_AGENT, AGENT, UNVERIFIED_SELLER, LMNP_AGENT],
	Icon: 'ViewingsIcon'
});

const sellersPool = new Route('sellers-pool', '/sellers-pool', 'client/sellers-pool', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles],
	hiddenFor: [...sellerRoles],
	Icon: 'SellersPoolIcon'
});

const propertiesRoot = new Route('properties', '/properties', 'client/property', {
	disabledFor: [UNVERIFIED_AGENT, UNVERIFIED_SELLER],
	hiddenFor: [UNVERIFIED_SELLER],
	i18nKey: 'properties.title'
});

const propertiesAdd = new NestedRoute(propertiesRoot, 'add', 'add', 'add', {
	disabledFor: [ADMIN, UNVERIFIED_AGENT, AGENT, LMNP_AGENT, UNVERIFIED_SELLER], 
	hiddenFor: [ADMIN, UNVERIFIED_AGENT, AGENT, LMNP_AGENT, UNVERIFIED_SELLER], 
	Icon: 'PropertiesIconAdd'
});
const propertiesAddAgentNotFound = new NestedRoute(propertiesRoot, 'agent-not-found', 'add/agent-not-found', 'agent-not-found', {
	disabledFor: [ADMIN, UNVERIFIED_AGENT, LMNP_AGENT, AGENT, UNVERIFIED_SELLER],
	hiddenFor: [ADMIN, UNVERIFIED_AGENT, LMNP_AGENT, AGENT, UNVERIFIED_SELLER]
});
const propertiesAddToSeller = new NestedRoute(propertiesRoot, 'add-to-seller', 'add-to-seller/:sellerId', 'add-to-seller', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles], hiddenFor: [ADMIN, UNVERIFIED_AGENT, LMNP_AGENT, AGENT, ...sellerRoles]
});

const sellersLounge = new Route('sellersLounge', '', '', {
	disabledFor: [...sellerRoles, UNVERIFIED_AGENT],
	hiddenFor: [...sellerRoles],
	Icon: 'PropertiesIcon'
});

const propertiesList = new NestedRoute(propertiesRoot, 'list', '', 'list', {
	disabledFor: [...sellerRoles, UNVERIFIED_AGENT],
	hiddenFor: [...sellerRoles],
	Icon: 'PropertiesIcon'
});

const lmnpPropertiesList = new Route('lmnp-area', '/lmnp-properties', 'client/property/lmnp-area', {
	disabledFor: [...sellerRoles, UNVERIFIED_AGENT, AGENT],
	hiddenFor: [...sellerRoles, UNVERIFIED_AGENT, AGENT, ADMIN],
	Icon: 'PropertiesIcon'
});

const sellersPropertiesList = new NestedRoute(propertiesRoot, 'list', '', 'list', {
	disabledFor: [ADMIN, UNVERIFIED_AGENT, LMNP_AGENT, AGENT, UNVERIFIED_SELLER],
	hiddenFor: [ADMIN, UNVERIFIED_AGENT, LMNP_AGENT, AGENT, UNVERIFIED_SELLER],
	Icon: 'PropertiesIcon'
});

const propertyRoot = new NestedRoute(propertiesRoot, 'id', ':propertyId', '', {
	ignoreInBreadcrumbs: true
});
const propertySummary = new NestedRoute(propertyRoot, 'summary', 'summary', 'summary');
const propertyOffers = new NestedRoute(propertyRoot, 'offers', 'offers', 'offers', {
	i18nKey: 'properties.id.offers.title', disabledFor: [UNVERIFIED_SELLER]
});
const propertyDocuments = new NestedRoute(propertyRoot, 'documents', 'documents', 'documents', {
	i18nKey: 'properties.id.documents.title', disabledFor: [UNVERIFIED_SELLER]
});
const propertyDocumentUpload = new NestedRoute(propertyDocuments, 'upload', ':documentType/upload/:sellerId?', 'upload');
const propertyDocumentMandat = new NestedRoute(propertyDocuments, 'mandat', 'mandat/:page?', 'mandat');
const propertyUploadMandat = new NestedRoute(propertyDocuments, 'mandat-upload', 'upload-mandat', 'mandat/upload');
const propertyDocumentReducingPrice = new NestedRoute(propertyDocuments, 'reduce', 'reduce', 'reduce');
const propertyDocumentVerify = new NestedRoute(propertyDocuments, 'verify', 'verify', 'verify');
const propertySurvey = new NestedRoute(propertyDocuments, 'survey', 'survey', 'client/survey', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles]
});
const propertySellers = new NestedRoute(propertyRoot, 'sellers', 'sellers', 'sellers', {
	i18nKey: 'properties.id.sellers', disabledFor: [UNVERIFIED_SELLER]
});
const propertyDetails = new NestedRoute(propertyRoot, 'details', 'details', 'details', {
	disabledFor: [UNVERIFIED_SELLER]
});
const propertyViewings = new NestedRoute(propertyRoot, 'viewings', 'viewings', 'viewings');
const propertyHistory = new NestedRoute(propertyRoot, 'history', 'history', 'history', {
	disabledFor: [UNVERIFIED_SELLER, SELLER],
	hiddenFor: [...sellerRoles]
});
const yousign = new Route('yousign', '/yousign/:id', 'client/yousign');

const sellerList = new Route('sellers', '/sellers', 'client/seller/seller-list', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles], hiddenFor: [...sellerRoles], i18nKey: 'sellers.list', Icon: 'SellersIcon'
});
const sellerProfile = new NestedRoute(sellerList, 'profile', 'profile/:userId', 'client/seller/profile');
const sellerAdd = new NestedRoute(sellerList, 'add', 'add', 'client/seller/seller-add', {
	disabledFor: [...sellerRoles]
});
const propertyContacts = new NestedRoute(propertyRoot, 'contacts', 'contacts', 'contacts');

export {
	myCalendar,
	agentCalendar,
	viewingsList,
	viewingsReport,
	propertiesRoot,
	propertiesList,
	sellersPropertiesList,
	propertiesAdd,
	propertiesAddAgentNotFound,
	propertiesAddToSeller,
	propertySummary,
	propertySurvey,
	propertyOffers,
	propertyViewings,
	propertyDocuments,
	propertyContacts,
	propertyDocumentUpload,
	propertyDocumentMandat,
	propertyUploadMandat,
	propertyDocumentReducingPrice,
	propertyDocumentVerify,
	propertySellers,
	propertyDetails,
	propertyHistory,
	propertyRoot,
	lmnpPropertiesList,
	yousign,
	sellersPool,
	sellerProfile,
	sellerAdd as addSeller,
	sellerList as listSeller,
	sellersLounge
};
