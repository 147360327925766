import {UserRoles} from '../user/roles';
import Route, {NestedRoute} from './helpers/route';
import AppConfig from '../../config';

const {
	UNVERIFIED_AGENT, LMNP_AGENT, AGENT, UNVERIFIED_SELLER, SELLER, ADMIN
} = UserRoles;


const agentRoles = [UNVERIFIED_AGENT, AGENT, LMNP_AGENT];
const sellerRoles = [UNVERIFIED_SELLER, SELLER];

const showProfileForAgent = new Route('profile', '/profile/:userId', 'client/profile/profile-show', {
	disabledFor: [ADMIN, UNVERIFIED_AGENT]
});

const myDocuments = new Route('my-documents', '/my-documents', 'client/documents/my-documents', {
	i18nKey: 'my-documents.title',
	Icon: 'DocumentsIcon',
	disabledFor: [ADMIN, ...sellerRoles],
	hiddenFor: [ADMIN, ...sellerRoles]
});

const myDocumentsUpload = new NestedRoute(myDocuments, 'upload', 'upload/:key', 'upload');

// const zohoSubscription = new Route('zoho-subscription', '/zoho-subscription', 'client/zoho-subscription', {
// 	i18nKey: 'zohoSubscription',
// 	Icon: 'ZohoCustomersIcon',
// 	disabledFor: [ADMIN, ...sellerRoles],
// 	hiddenFor: [ADMIN, ...sellerRoles]
// });

const myFamilyTreeOld = new Route('my-family-tree-old', '/my-family-tree-old', 'client/family-tree-old', {
	disabledFor: [UNVERIFIED_AGENT, LMNP_AGENT, ADMIN, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ...sellerRoles],
	Icon: 'FamilyTreeIcon'
});

const myFamilyTree = new Route('my-family-tree', '/my-family-tree', 'client/family-tree', {
	disabledFor: [UNVERIFIED_AGENT, LMNP_AGENT, ADMIN, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ...sellerRoles],
	Icon: 'FamilyTreeIcon'
});

const addNewMentee = new Route('add-new-mentee', '/add-new-mentee', 'client/add-new-mentee', {
	disabledFor: [UNVERIFIED_AGENT, LMNP_AGENT, ADMIN, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ADMIN, ...sellerRoles]
});

const teamSimulator = new Route('team-simulator', '/team-simulator', 'client/not-found', {
	disabledFor: [...agentRoles, ADMIN, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ...sellerRoles]
});

const createTicket = new Route('create-ticket', '/create-ticket', 'client/not-found', {
	disabledFor: [...agentRoles, ADMIN, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ...sellerRoles]
});

const invoicesKeros = new Route('invoicesKeros', AppConfig.hosts.keros, 'client/not-found', {
	Icon: 'MyTrainingIcon',
	target: '_blank',
	disabledFor: [UNVERIFIED_AGENT, LMNP_AGENT, ...sellerRoles],
	hiddenFor: [LMNP_AGENT, ...sellerRoles]
});

export {
	showProfileForAgent,
	myFamilyTreeOld,
	myFamilyTree,
	myDocuments,
	myDocumentsUpload,
	invoicesKeros,
	addNewMentee,
	teamSimulator,
	createTicket
	// zohoSubscription
};
