import {AgentLevel} from '../../domain/user/types';
import {menuActions} from '../../components/client/family-tree/components/card-menu';

export default ({
  "treeSelect": {
    "title": "Family tree list :",
    "placeholder": "Select",
    "primaryItem": "Main tree",
    "item": "Card №!#!cardId!#! tree"
  },
  "level": {
    [AgentLevel.unset2018]: "2018",
    [AgentLevel.N1]: "N1",
    [AgentLevel.N2]: "N2",
    [AgentLevel.N3]: "N3"
  },
  "cardId": "Unique<br/>card №<br/>!#!cardId!#!",
  "saleLabel": "Card for sale,<br/>Keros report activated",
  "agent": "<i><strong><1>!#!name!#!</1></strong><strong><2>!#!level!#!</2></strong></i>",
  "available": "<strong>Position available</strong>previous agent :<i><1>!#!name!#!</1></i>",
  "grantee": "<strong>Position Grantee</strong><i>Current agent :</i><i><1>!#!name!#!</1></i>",
  "locked": "<strong>Position locked</strong><i><1>!#!name!#!</1> <2>!#!level!#!</2></i>",
  "archived": "<strong>Position archived</strong>previous agent :<i><1>!#!name!#!</1></i>",
  "mentor": "<i><strong><1>!#!name!#!</1></strong><strong><2>!#!level!#!</2></strong></i>GODFATHER",
  "plus-button": {
    "text": "Add<br/>!#!count!#! more",
    "tooltip": {
      "n1": "You need to become N2 agent to add godsons",
      "add-more-fixed": "+ You can now add <1>!#!count!#!</1> more agent on your level 1",
      "add-more": "+ Add <1>!#!count!#!</1> more",
      "add-more_plural": "Add <1>!#!count!#!</1> more",
      "become-n3": "You need to become Agent N3 to add more agents",
      "n3-expand": "To add 3 agents on your first level, one of your godsons needs to add one more godson"
    }
  },
  "turnover": {
    "paid": {
      "text": "TO paid: €!#!count!#! VAT",
      "tooltip": "Turnover collected during the last 12 months"
    },
    "pending": {
      "text": "pending: €!#!count!#! VAT",
      "tooltip": "Turnover under current offers and compromise of sale on the date of the day"
    }
  },
  "popup": {
    "actions": {
      "yes": "yes",
      "no": "no"
    },
    "pages": {
      [menuActions.replaceGodfather]: {
        "title": "Replace Godfather",
        "body": "Please select new godfather or replaceable blocked agent<br/>for <strong>!#!agent!#!</strong> instead of <strong>!#!oldGodfather!#!</strong>"
      },
      [menuActions.detachGodfather]: {
        "title": "Detach Godfather",
        "body": "Future godsons commissions will be lost!<br/>Are you sure you want to <br/>detach <strong>!#!agent!#!</strong> from <strong>!#!godfather!#!</strong>?"
      },
      [menuActions.attachGodfather]: {
        "title": "Attach Godfather",
        "body": "Please select new godfather or replaceable blocked agent<br/>for <strong>!#!name!#!</strong>"
      },
      [menuActions.archiveAgent]: {
        "title": "Archive Agent",
        "body": "This action will make this position available and create a new card for the archived agent and also attach it to the parent<br/>Are you sure you want to archive <strong>!#!name!#!</strong>?",
        "body-agent-with-no-parent-and-children": "Are you sure you want to archive <strong>!#!name!#!</strong>?"
      },
      [menuActions.unarchiveAgent]: {
        "title": "Unarchive Agent",
        "body": "Are you sure you want to unarchive <strong>!#!name!#!</strong>?"
      },
      [menuActions.blockAgent]: {
        "title": "Block Agent",
        "body": "Are you sure you want to block <strong>!#!name!#!</strong>?"
      },
      [menuActions.unblockAgent]: {
        "title": "Unblock Agent",
        "body": "Are you sure you want to unblock <strong>!#!name!#!</strong>?"
      },
      [menuActions.cardNftSold]: {
        "title": "NFT Card sold",
        "body": "Please select the agent who bought the card",
        "checkbox-text": "Also archive !#!name!#!"
      },
      [menuActions.cutBranch]: {
        "title": "Prepare branch for sale",
        "body": "Are you sure you want to prepare branch <strong>!#!name!#!</strong> for sale?"
      },
      [menuActions.assignParentCard]: {
        "title": "Assign previous godfather",
        "body": "Are you sure you want to assign previous godfather <br/>card for <strong>!#!name!#!</strong>?<br/><br/>Please write card number in the textfield below"
      }
    }
  }
});


