/* eslint-disable import/prefer-default-export */

import FieldStatus from '../field-status';
import FieldType from '../field-type';
import {UserRoles} from '../../user/roles';
import Position from '../position';

/**
 * Creates configured field for either documents or profile
 * @param key field identifier
 * @param type type of field
 * @param position describe where field should be displayed
 * @param roles array of users who have this field
 * @param disabledFor array of users who has no access to edit this field
 * @param hiddenFor
 * @param renewal
 * @param isReadOnly - is field is read-only
 */
export function createField(
	key,
	type,
	position = Position.PRIMARY_FORM,
	roles = UserRoles.asArray,
	disabledFor = [],
	hiddenFor = [],
	renewal = false,
	isReadOnly = false
) {
	return {
		key,
		type,
		roles,
		position,
		disabledFor,
		hiddenFor,
		status: FieldStatus.EMPTY,
		renewal,
		readonly: isReadOnly
	};
}

export function createLinkDocument(
	key,
	link,
	roles,
	disabledFor = [],
	hiddenFor = [],
	renewal = false
) {
	const field = createField(key, FieldType.LINK, Position.DOCUMENT, roles, disabledFor, hiddenFor, renewal);

	field.link = link;
	return field;
}
