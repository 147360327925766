export default theme => ({
	header: {
		backgroundColor: theme.palette.layout.main,
		boxShadow: 'none',
		height: theme.spacing.header,
		position: 'fixed',
		zIndex: theme.zIndex.drawer + 1
	},
	pageName: {
		marginRight: 'auto',
		marginLeft: theme.spacing.unit * 3
	},
	toolbar: {
		display: 'flex',
		padding: `0 0 0 ${theme.spacing.unit * 3}px`,
		flexDirection: 'row',
		justifyContent: 'space-between',
		flex: 1,
		boxSizing: 'border-box'
	},
	toolbarStart: {
		display: 'flex',
		height: '100%',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	toolbarEnd: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '100%',
		alignItems: 'center'
	},
	sidebarControlButton: {
		color: 'white',
		marginRight: theme.spacing.unit * 3,
		width: 48,
		height: 48
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	sidebarOpen: {
		marginLeft: theme.spacing.sidebar,
		[`@media(max-width: ${theme.screenSizes.desktop}px)`]: {
			marginLeft: 0
		}
	},
	sidebarClosed: {
		marginLeft: 0
	},
	hide: {
		display: 'none'
	},
	userInfoContainer: {
		position: 'relative',
		display: 'flex',
		width: 288,
		height: '100%',
		boxSizing: 'border-box',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.layout.dark,
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3,
		cursor: 'pointer',

		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			width: 'auto'
		}
	},
	userInfoPanel: {
		position: 'absolute',
		top: '100%',
		right: 0,
		width: 288,
		background: theme.palette.layout.main,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			left: 0,
			width: 'auto'
		}
	},
	userInfoPanelContainer: {
		padding: 0
	},
	userName: {
		color: '#ffffff',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	userRole: {
		color: '#ffffff'
	},
	userNameIcon: {
		borderRadius: '50%',
		width: 40,
		minWidth: 40,
		height: 40,
		backgroundColor: theme.palette.grey.light,
		color: theme.palette.grey.dark,
		paddingTop: 10,
		boxSizing: 'border-box',
		fontSize: 18
	},
	userNameAvatar: {
		borderRadius: '50%',
		width: 40,
		minWidth: 40,
		height: 40,
		backgroundColor: theme.palette.grey.light,
		boxSizing: 'border-box',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: 'solid 2px #FFF',
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			display: 'none'
		}
	},
	userNameAvatarImage: {
		display: 'block',
		maxHeight: 40,
		minHeight: 40,
		objectFit: 'cover',
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			maxHeight: 'unset'
		}
	},
	userInfo: {
		marginLeft: theme.spacing.unit * 2,
		color: '#ffffff',
		maxWidth: 140,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			display: 'none'
		}
	},
	userInfoButtonContainer: {
		marginLeft: theme.spacing.unit * 3,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			marginLeft: 0
		}
	},
	userInfoButton: {
		color: '#ffffff',
		padding: theme.spacing.unit / 2,
		background: theme.palette.layout.main,
		'&:hover': {
			background: theme.palette.layout.main
		}
	},
	menuPaper: {
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			paddingTop: 0,
			borderTopLeftRadius: 0
		}
	},
	menuItem: {
		fontSize: 16,
		color: '#fff'
	},
	pageInfo: {
		height: '100%',
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			display: 'flex',
			flexFlow: 'row nowrap',
			alignItems: 'center'
		}
	},
	pageInfoTitle: {
		color: '#ffffff',
		whiteSpace: 'nowrap',
		lineHeight: '24px',
		fontWeight: 700,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			fontSize: 24,
			whiteSpace: 'pre-wrap'
		}
	},
	tabletMenuList: {
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			'& li': {
				height: 64,
				boxSizing: 'border-box'
			}
		}
	},
	tabletProfile: {
		display: 'none',
		color: 'white',
		position: 'relative',
		marginTop: -theme.spacing.unit,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			display: 'block'
		}
	},
	tabletProfileLabel: {
		position: 'absolute',
		left: 64,
		top: 13,
		right: 0,
		'& $userName': {
			width: '100%',
			color: '#333',
			fontSize: '16px',
			overflowX: 'hidden',
			textOverflow: 'ellipsis'
		},
		'& $userRole': {
			width: '100%',
			color: '#333',
			fontSize: '12px',
			overflowX: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
	desktopInfo: {
		display: 'flex',
		alignItems: 'center'
	},
	userLevel: {
		display: 'flex',
		position: 'relative',
		height: 72,
		backgroundColor: theme.palette.layout.dark,
		justifyContent: 'center',
		alignItems: 'center',
		userSelect: 'none'
	},
	userLevelLetter: {
		position: 'absolute',
		color: 'rgba(255, 255, 255, 0.12)',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		textAlign: 'center',
		fontSize: 72,
		lineHeight: 1,
		fontWeight: 700,
		textTransform: 'uppercase',
		fontFamily: 'sans-serif'
	},
	userLevelMark: {
		color: theme.palette.layout.light,
		textTransform: 'uppercase',
		fontSize: 24,
		lineHeight: '20px',
		fontWeight: 700
	},
	userLevelText: {
		color: '#fff',
		textTransform: 'uppercase',
		fontSize: 24,
		lineHeight: '20px',
		fontWeight: 700,
		marginLeft: theme.spacing.unit / 2
	},
	userComissions: {
		padding: theme.spacing.unit * 3
	},
	userComission: {
		marginBottom: theme.spacing.unit * 3,
		'&:last-child': {
			marginBottom: 0
		}
	},
	userComissionTitle: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 10,
		userSelect: 'none'
	},
	userComissionTitleText: {
		color: '#fff',
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: 700
	},
	userComissionIcon: {
		marginRight: theme.spacing.unit,
		fill: theme.palette.layout.light
	},
	userComissionValue: {
		display: 'flex',
		alignItems: 'center',
		height: 32,
		padding: '0 20px',
		backgroundColor: theme.palette.layout.dark,
		borderRadius: 6,
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: 500,
		color: '#fff',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	userInfoLinks: {
		backgroundColor: theme.palette.layout.dark,
		padding: theme.spacing.unit * 3
	},
	userInfoNoTextDecoration: {
		textDecoration: 'none'
	},
	userInfoLink: {
		backgroundColor: theme.palette.layout.light,
		color: '#fff',
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: 700,
		textAlign: 'center',
		height: 48,
		marginBottom: theme.spacing.unit * 3,
		minWidth: 'auto',
		'&:hover': {
			backgroundColor: theme.palette.layout.light
		}
	},
	userInfoLogout: {
		backgroundColor: theme.palette.layout.main,
		color: '#fff',
		fontSize: 16,
		lineHeight: '20px',
		fontWeight: 700,
		textAlign: 'center',
		height: 48,
		marginBottom: 0,
		minWidth: 'auto',
		'&:hover': {
			backgroundColor: theme.palette.layout.main
		}
	}
});
