import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import styles from './styles';

import {UserNavigation} from '../../../domain/navigation';

export const CloseButton = ({notifKey}) => {
	const {closeSnackbar} = useSnackbar();
	const closeNotification = React.useCallback(() => closeSnackbar(notifKey));

	return (
		<IconButton style={{color: 'white', padding: '20px'}} onClick={closeNotification}>
			<CloseIcon />
		</IconButton>
	);
};

const SetWorkHours = withStyles(styles)(({classes, notifKey}) => {
	const history = useHistory();
	const {t} = useTranslation();

	const {closeSnackbar} = useSnackbar();
	const openCalendar = React.useCallback(() => {
		history.push(UserNavigation.agentCalendar.constructPath());
		closeSnackbar(notifKey);
	}, [notifKey]);

	return (
		<Button onClick={openCalendar} className={classes.textButton}>{t('basic:notify.openWorkHours')}</Button>
	);
});

const actionsMap = {
	close: CloseButton,
	workHours: SetWorkHours
};

const Actions = ({notifKey, actionIds = []}) => {
	return (
		<React.Fragment>
			{actionIds.length > 0 && actionIds.map(action => {
				const Component = actionsMap[action];

				if (Component) {
					return <Component key={action} notifKey={notifKey} />;
				}

				return null;
			})}
		</React.Fragment>
	);
};

export default Actions;
