import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom';
import {screenSizes} from '../../../common/theme/theme';
import Logo from './logo';
import styles from './styles';
import {RegularItem} from './regular-item';
import {ItemWithNested} from './item-with-nested';
import {LinkItem} from './link-item';

class Sidebar extends React.Component {
	render() {
		const {classes, data, open, handleCloseSidebar} = this.props;

		return (
			<Drawer
				open={open}
				variant={Sidebar.getVariant()}
				anchor="left"
				onClose={handleCloseSidebar}
				classes={{
					paper: classes.sidebar
				}}
			>
				<div className={classes.header}>
					<Link to="/" className={classes.logoLink}>
						<Logo classes={{root: classes.logo}}/>
					</Link>
					<IconButton
						className={classes.sidebarControlButton}
						onClick={handleCloseSidebar}
					>
						<ChevronLeftIcon/>
					</IconButton>
				</div>
				<List component="nav" classes={{root: classes.nav}}>
					{data.map(this.renderListItem)}
				</List>
			</Drawer>
		);
	}

	renderListItem = item => {
		const {t, classes, location} = this.props;


		if (item.children && item.children.length > 0) {
			return (
				<ItemWithNested
					key={item.key} 
					t={t} 
					classes={classes}
					location={location} 
					item={item}
					renderListItem={this.renderListItem}
				/>
			)
		}

		if (item.target === '_blank') {
			return (
				<LinkItem
					key={item.key}
					t={t}
					classes={classes}
					item={item}
				/>
			);
		}

		return <RegularItem key={item.key} t={t} classes={classes} location={location} item={item} handleChangeLocation={this.handleChangeLocation} />;
	}

	static getVariant() {
		if (window.innerWidth > screenSizes.wideDesktop) {
			// show always
			return 'permanent';
		}

		if (window.innerWidth > screenSizes.desktop) {
			// hide & show with content shift
			return 'persistent';
		}

		// hide overlay
		return 'temporary';
	}

	handleChangeLocation = item => {
		const {history} = this.props;

		history.push(item.link);
	}
}

Sidebar.propTypes = {
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	handleCloseSidebar: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	data: PropTypes.array.isRequired
};

export default withStyles(styles)(Sidebar);
