export default ({
  "Keros": {
    "title": "KEROS",
    "text": "Gérez vos factures en quelques secondes. Suivez vos ventes et celles de vos filleuls.",
    "button": "Commencer"
  },
  "FormezVous": {
    "title": "Formez vous ",
    "text": "Notre site d'e-learning immobilier pour mieux vendre"
  },
  "VirtualTours": {
    "title": "Visites virtuelles Sextant",
    "text": "Le site pour créer vos visites virtuelles en quelques minutes"
  },
  "PigeSMS": {
    "title": "Pige SMS",
    "text": "Envoi automatisé de SMS aux vendeurs particuliers grâce à un robot intelligent"
  },
  "MyAdapt": {
    "title": "MyAdapt",
    "text": "CRM immobilier Adapt immo en version web complète"
  },
  "extranet": {
    "title": "Extranet Sextant",
    "text": "Tous les documents dont vous aurez besoin avec vos clients"
  },
  "LeonardAnalytics": {
    "title": "Leonard Analytics",
    "text": "Des statistiques pour vous et vos clients"
  },
  "MyNotary": {
    "title": "MyNotary",
    "text": "Outil 2.0 pour préparer et signer vos mandats"
  },
  "PriceHubble": {
    "title": "PriceHubble",
    "text": "Solution premium d'estimation immobilière"
  },
  "TakTikimmo": {
    "title": "TakTikimmo",
    "text": "Solution avancée pour prospecter des vendeurs immobiliers"
  },
  "Vistaprint": {
    "title": "Vistaprint",
    "text": "Pour imprimer vos supports marketing"
  },
  "Imprimerie-immo": {
    "title": "Imprimerie Immo",
    "text": "Imprimeur spécialisé pour vos supports marketing"
  },
  "facebook": {
    "title": "Groupe Facebook pour les conseillers Sextant",
    "text": "Echangeons ensemble sur Facebook"
  },

  "googleMyBusiness": {
    "title": "Google My Business",
    "text": "Gérer votre présence sur Google et Google maps ainsi que vos avis clients"
  },
  "Trustpilot": {
    "title": "Trustpilot",
    "text": "Le site numéro 1 des avis clients"
  },
  "Immodvisor": {
    "title": "Immodvisor",
    "text": "Site d'avis clients dédié à l'immobilier"
  },

  "Meero": {
    "title": "Meero",
    "text": "Commander les services d'un photographe pour valoriser vos mandats"
  },
  "Matterport": {
    "title": "Matterport",
    "text": "Photographes qui réalisent des visites virtuelles premium"
  },
  "Solen": {
    "title": "Solen",
    "text": "Solution qui permet de mesurer l'exposition solaire d'un bien et de mieux le valoriser"
  },

  "Toolimo": {
    "title": "Toolimo",
    "text": "Réseau de diagnostiqueurs pour réaliser des certificats DPE etc"
  },
  "ArtemisCourtage": {
    "title": "Artémis Courtage",
    "text": "Courtier national en prêts immobiliers"
  },

  "groups": {
    "customers": "AVIS CLIENTS",
    "partners": "NOS PARTENAIRES"
  },
  "coming-soon": "Coming soon"
});
