import lodash from 'lodash';
import LayoutGroup from './helpers/layout-group';
import {
	myProfile,
	supportGroup,
	support,
	accessDenied,
	notFound,
	dashboard,
	myActivity,
	myApps,
	myTeam,
	myTraining
} from './routes-common';
import {
	extranet,
	faqs,
	outils,
	loiAlur,
	myOptions
} from './routes-msxt-common';
import {
	showProfileForAgent,
	myFamilyTreeOld,
	myFamilyTree,
	myDocuments,
	myDocumentsUpload,
	invoicesKeros,
	addNewMentee,
	teamSimulator,
	createTicket
	// zohoSubscription
} from './routes-msxt-agent';
import {
	userManagement,
	showProfileForAdmin,
	verifyProfile,
	verifyDocuments,
	commissionSettings,
	addProfile,
	adminDocumentsUpload,
	familyTreeOld,
	familyTree,
	zohoList,
	adminCategory
} from './routes-msxt-admin';
import {
	myCalendar,
	agentCalendar,
	viewingsList,
	viewingsReport,
	propertiesRoot,
	propertiesAdd,
	propertiesAddAgentNotFound,
	propertiesAddToSeller,
	propertiesList,
	sellersPropertiesList,
	propertySummary,
	propertyViewings,
	propertyDocuments,
	propertyOffers,
	propertyDocumentUpload,
	propertyDocumentMandat,
	propertyUploadMandat,
	propertyDocumentReducingPrice,
	propertyDocumentVerify,
	propertySellers,
	propertyDetails,
	propertyHistory,
	propertyRoot,
	propertySurvey,
	propertyContacts,
	lmnpPropertiesList,
	yousign,
	sellersPool,
	sellerProfile,
	addSeller,
	listSeller,
	sellersLounge
} from './routes-sellers';

const list = {
	myProfile,
	verifyProfile,
	verifyDocuments,
	commissionSettings,
	showProfileForAdmin,
	myDocuments,
	myDocumentsUpload,
	outils,
	myFamilyTreeOld,
	myFamilyTree,
	loiAlur,
	faqs,
	myOptions,
	supportGroup,
	support,
	userManagement,
	// zohoSubscription,
	showProfileForAgent,
	familyTreeOld,
	familyTree,
	accessDenied,
	notFound,
	adminDocumentsUpload,
	extranet,
	myCalendar,
	sellersPool,
	addProfile,
	addSeller,
	listSeller,
	sellerProfile,
	propertiesAdd,
	propertiesAddAgentNotFound,
	propertiesAddToSeller,
	propertiesList,
	sellersPropertiesList,
	propertySummary,
	propertyDocuments,
	propertyOffers,
	propertyViewings,
	propertyDocumentUpload,
	propertyDocumentMandat,
	propertyUploadMandat,
	propertyDocumentReducingPrice,
	propertyDocumentVerify,
	propertySurvey,
	propertySellers,
	propertyDetails,
	propertyHistory,
	propertiesRoot,
	propertyRoot,
	propertyContacts,
	yousign,
	viewingsList,
	viewingsReport,
	agentCalendar,
	lmnpPropertiesList,
	zohoList,

	dashboard,
	myActivity,
	myApps,
	myTeam,

	myTraining,

	adminCategory,
	addNewMentee,
	teamSimulator,
	createTicket
};

const navbar = [
	dashboard,
	{
		parentItem: myActivity,
		children: [
			listSeller,
			sellersPool,
			propertiesList,
			lmnpPropertiesList,
			agentCalendar,
			viewingsList,
			sellersPropertiesList,
			viewingsReport,
			propertiesAdd,
			invoicesKeros
		]
	},
	myApps,
	myOptions,
	{
		parentItem: myTeam,
		children: [
			myFamilyTree,
			addNewMentee
			// teamSimulator
		]
	},
	myTraining,
	{
		parentItem: supportGroup,
		children: [
			support,
			faqs,
			createTicket
			// careerProgression
		]
	},
	{
		parentItem: adminCategory,
		children: [
			userManagement,
			zohoList
		]
	}
];

// const navbar = [
// 	myProfile,
// 	myDocuments,
// 	outils,
// 	{
// 		parentItem: sellersLounge,
// 		children: [
// 			propertiesList,
// 			lmnpPropertiesList,
// 			viewingsList,
// 			listSeller,
// 			sellersPool
// 		]
// 	},
// 	sellersPropertiesList,
// 	viewingsReport,
// 	propertiesAdd,
// 	myFamilyTree,
// 	agentCalendar,
// 	// zohoSubscription,
// 	faqs,
// 	support,
// 	zohoList,
// 	userManagement
// ];

const mobilebar = [
	myProfile,
	myDocuments,
	outils,
	myOptions,
	propertiesList,
	lmnpPropertiesList,
	sellersPropertiesList,
	propertiesAdd,
	listSeller,
	myFamilyTree,
	agentCalendar,
	viewingsList,
	viewingsReport,
	faqs,
	support,
	// zohoSubscription,
	zohoList,
	userManagement
];

const propertyNav = [
	propertySummary,
	propertySellers,
	propertyDocuments,
	propertyViewings,
	propertyOffers,
	propertyContacts,
	propertyDetails,
	propertyHistory
];

const keyToRoute = lodash.keyBy(Object.values(list), 'key');

// sorting below is important!
// first allowed item will be as homepage
// i.e. redirected from '/'
const routes = [
	userManagement,
	outils,
	extranet,
	myProfile,
	myCalendar,
	agentCalendar,
	viewingsList,
	viewingsReport,
	yousign,
	myDocuments,
	myDocumentsUpload,
	myFamilyTreeOld,
	myFamilyTree,
	familyTreeOld,
	familyTree,
	loiAlur,
	faqs,
	myOptions,
	support,
	addNewMentee,
	addProfile,
	verifyDocuments,
	commissionSettings,
	verifyProfile,
	showProfileForAdmin,
	showProfileForAgent,
	adminDocumentsUpload,
	propertiesAdd,
	propertiesAddAgentNotFound,
	propertiesAddToSeller,
	propertiesList,
	lmnpPropertiesList,
	sellersPropertiesList,

	propertyDocumentUpload,
	propertyDocumentMandat,
	propertyUploadMandat,
	propertyDocumentReducingPrice,
	new LayoutGroup('client/property/common/layout', propertyRoot, [
		propertySummary,
		propertyDocuments,
		propertyViewings,
		propertyOffers,
		propertySurvey,
		propertySellers,
		propertyDocumentVerify,
		propertyContacts,
		propertyDetails,
		propertyHistory,
		propertySellers
	]),
	propertiesRoot,
	propertyRoot,

	sellersPool,
	sellerProfile,
	addSeller,
	listSeller,
	// zohoSubscription,
	zohoList,
	accessDenied,
	notFound
];

export {
	list, navbar, mobilebar, routes, propertyNav, keyToRoute
};
