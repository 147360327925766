import {UserRole} from './roles/types';

export type UserId = number;

export type CurrentUser = {
	roles: UserRole[]
}

export enum AgentLevel {
	unset2018 = 'unset2018',
	N1 = 'n1',
	N2 = 'n2',
	N3 = 'n3',
}

export type UserListModel = {
	id: UserId,
	name: string,
	postalcode: string,
	roles: UserRole[],
	avatar: string,
	blocked: boolean,
	isArchived: boolean,
	hasChildren: boolean,
	hasPassword: boolean,
	hasParent: boolean,
	parentId?: UserId,
	parentName?: string,
	documentVerificationStatus: unknown, // TODO find out this and other types marked as unknown
	profileVerificationStatus: unknown,
	verificationDate: string | null,
	startDate: string | null,
	agentType: unknown,
	agentLevel?: AgentLevel,
	recommendedAgentLevelUpgrade?: AgentLevel,
};
