import React from 'react';
import {useSnackbar} from 'notistack';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Actions, {CloseButton} from './custom-actions';
import {transError} from './helpers';

import {resetNotifications} from '../../../redux/actions/notifications';

export const Types = {
	ERROR: 'error',
	INFO: 'info',
	SUCCESS: 'success',
	WARNING: 'warning'
};

export const Positions = {
	Vertical: {
		TOP: 'top',
		BOTTOM: 'bottom'
	},
	Horizontal: {
		CENTER: 'center',
		LEFT: 'left',
		RIGHT: 'right'
	}
};

const closeAction = key => <CloseButton notifKey={key} />;

const getActions = actionIds => key => <Actions notifKey={key} actionIds={actionIds} />;

const Text = ({message, oldError}) => {
	const {t} = useTranslation('error');
	// old error prop is needed to keep handling the old style of BE errors: not all of them are sending error key yet
	const messageTranslated = oldError ? transError(t, message) : t(message);

	return (
		<span>
			{t(messageTranslated)}
		</span>
	);
};

const Notification = () => {
	const dispatch = useDispatch();
	const resetNotif = React.useCallback(() => {
		dispatch(resetNotifications());
	}, [dispatch]);

	const defaultSettings = {
		variant: Types.INFO,
		anchorOrigin: {
			vertical: Positions.Vertical.BOTTOM,
			horizontal: Positions.Horizontal.LEFT
		},
		action: closeAction,
		autoHideDuration: 3000,
		persist: false,
		preventDuplicate: true
	};

	const {enqueueSnackbar: showNotif} = useSnackbar();

	const {
		message,
		settings = {},
		oldError = false,
		actionsList = []
	} = useSelector(state => state.notifications);

	const customActions = actionsList.length > 0 ? {action: getActions(actionsList)} : {};

	const combinedSettings = {
		...defaultSettings,
		...settings,
		...customActions
	};

	React.useEffect(() => {
		if (message && message.length > 0) {
			showNotif(<Text message={message} oldError={oldError} />, combinedSettings);
		}

		resetNotif();
	}, [message]);

	return null;
};

export default Notification;
