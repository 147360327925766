export default theme => ({
	breadcrumbs: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
		lineHeight: 1,
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			display: 'none'
		}
	},
	pathNode: {
		fontSize: 14,
		lineHeight: '17px',
		color: '#eeeeee',
		cursor: 'default',
		'&:first-child > a': {
			marginLeft: 0
		}
	},
	pathNodeLink: {
		color: '#eeeeee',
		textDecoration: 'none',
		marginRight: (theme.spacing.unit / 2),
		marginLeft: (theme.spacing.unit / 2)
	},
	pathNodeLinkText: {
		fontSize: 14,
		lineHeight: '17px',
		'&:hover': {
			color: '#FFF',
			textDecoration: 'underline'
		}
	}
});
