import basic from './basic.json';
import controls from './controls.json';
import dialogs from './dialogs.json';
import navigation from './navigation.json';
import error from './error.json';
import aux from './auxiliary.json';
import loiAlur from './loi-alur.json';
import pages from './pages.json';
import validation from './validation';
import mandateInstruction from './mandate-instruction';
import propertyMessages from './property-messages.json';
import propertySummary from './property-summary';
import commissionSettings from './commission-settings';
import familyTree from './family-tree';
import outils from './outils';
import addNewMentee from './add-new-mentee';

export default {
	aux,
	basic,
	controls,
	dialogs,
	navigation,
	pages,
	error,
	validation,
	mandateInstruction,
	propertySummary,
	outils,
	'property-messages': propertyMessages,
	'loi-alur': loiAlur,
	'commission-settings': commissionSettings,
	'family-tree': familyTree,
	'add-new-mentee': addNewMentee
};
