// import 'react-virtualized-select/styles.css';
// import 'react-select/dist/react-select.css';

import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
// import {ConnectedRouter} from 'react-router-redux';
import {ConnectedRouter} from 'connected-react-router';
import {pdfjs} from 'react-pdf';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import i18next from 'i18next';
import {StylesProvider, createGenerateClassName} from '@material-ui/styles';
import 'react-image-gallery/styles/css/image-gallery.css';

import Routes from './routes';
import I18NProvider from './i18n';
import createStore from './redux/store';
import history from './redux/history';
import backUrlManager from './utils/back-url-helper';

import './app.css';

const store = createStore();

const ignoredPaths = ['/', '/sign-in', '/signin-callback', '/silent-signin-callback'];

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// backUrl
if (!ignoredPaths.includes(window.location.pathname)) {
	backUrlManager.setOnce(window.location.pathname);
}

const generateClassName = createGenerateClassName({
	productionPrefix: 'c',
	disableGlobal: true
  });

// need to the week starts from Monday to fix jumping bugs on edit viewing calendar
moment.updateLocale('en', {
	week: {
		dow: 1,
		doy: 1
	}
});

moment.updateLocale('fr', {
	week: {
		dow: 1,
		doy: 1
	}
});

const currentLng = i18next.language || 'fr';

const App = () => (
	<I18NProvider>
		<MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng}>
			<ReduxProvider store={store}>
				<ConnectedRouter history={history}>
					<StylesProvider generateClassName={generateClassName}>
						<Routes />
					</StylesProvider>
				</ConnectedRouter>
			</ReduxProvider>
		</MuiPickersUtilsProvider>
	</I18NProvider>
);

export default App;
