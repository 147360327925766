import PropTypes from 'prop-types';

const VALUATION = 'valuation';
const DOCUMENTS = 'documents';
const MANDAT = 'mandat';
const SALE = 'sale';
const SOLD = 'sold';
const EXCHANGE = 'exchangePending';
const CANCELLED = 'canceled';

const asArray = [VALUATION, DOCUMENTS, MANDAT, SALE, SOLD];

export default ({
	VALUATION,
	DOCUMENTS,
	MANDAT,
	SALE,
	SOLD,
	CANCELLED,
	EXCHANGE,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
