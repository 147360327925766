import UserRoles from '../../domain/user/roles/user-roles';
import * as Types from '../actions/sellers';
import setIn from './helpers/set-in';

const initialState = {
	pool: [],
	list: {
		content: [],
		totalCount: 0,
		pageSize: 10,
		pageNumber: 0,
		roles: UserRoles.asArray.filter(UserRoles.isRoleRelatedToSeller),
		searchString: null,
		orderBy: null,
		orderDirection: null,
		showHidden: false
	},
	comments: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Types.GET_SELLERS_POOL:
			return setIn(state, action.data, 'pool');
		case Types.ASSIGN_AGENT_SELLERS_POOL:
			return setIn(state, action.data, 'pool');
		case Types.CANCEL_ASSIGN_AGENT_SELLERS_POOL:
			return setIn(state, action.data, 'pool');
		case Types.RESET_SELLERS_POOL:
			return setIn(state, initialState.list, 'pool');
		case Types.UPDATE_SELLER_BLOCK:
			return setIn(state, action.data, 'list', action.sellerId);
		case Types.UPDATE_ARCHIVE_SELLER:
			return setIn(state, action.data, 'list', action.sellerId);
		case Types.GET_SELLERS_LIST:
			return setIn(state, action.data, 'list');
		case Types.RESET_SELLERS_LIST:
			return setIn(state, initialState.list, 'list');
		case Types.GET_COMMENTS_OF_SELLER:
			return setIn(state, action.data, 'comments', action.sellerId);
		case Types.RESET_COMMENTS_OF_SELLER:
			return setIn(state, undefined, 'comments', action.sellerId);
		case Types.ADD_COMMENT_TO_SELLER:
			return setIn.withOptions(state, action.data, {
				insert: (array, value) => array.unshift(value)
			}, 'comments', action.sellerId, {
				key: 'id',
				value: action.data.id
			});
		case Types.DELETE_COMMENT_OF_SELLER:
			return setIn(state, undefined, 'comments', action.sellerId, {
				key: 'id',
				value: action.commentId
			});
		case Types.UPDATE_HIDDEN_STATUS:
			return {
				...state,
				list: {
					...state.list,
					content: [
						...state.list.content.map(u => {
							if (u.id === action.id) {
								return {
									...u,
									hidden: action.http.response.body.hidden
								};
							}

							return u;
						})
					]
				}
			};
		default:
			return state;
	}
};

