import findIndex from 'lodash/findIndex';

function setOrDelete(where, key, what, customInsert) {
	if (customInsert) {
		return customInsert(where, what, key);
	}

	if (typeof what === 'undefined') {
		if (Array.isArray(where)) {
			where.splice(key, 1);
		} else {
			delete where[key];
		}
	} else {
		where[key] = what;
	}
}

function insertIntoArray(where, value, index, customInsert) {
	if (customInsert) {
		customInsert(where, value, index);
	} else if (index === -1) {
		where.push(value);
	} else {
		setOrDelete(where, index, value);
	}
}

function copy(currentStatePart, key) {
	if (Array.isArray(currentStatePart[key])) {
		currentStatePart[key] = [...currentStatePart[key]];
	} else {
		currentStatePart[key] = {...currentStatePart[key]};
	}
}

function setInGeneral(state, value, options = {}, ...path) {
	const newState = {...state};

	let currentStatePart = newState;

	path.forEach((key, i, array) => {
		if (array.length - 1 === i) {
			if (array.length > 1 && Array.isArray(currentStatePart)) {
				const {key: keyToSearch, value: keyValue} = key;
				const index = findIndex(currentStatePart, x => x[keyToSearch] === keyValue);

				insertIntoArray(currentStatePart, value, index, options.insert);
			} else {
				setOrDelete(currentStatePart, key, value, options.insert);
			}
		} else {
			copy(currentStatePart, key);
			currentStatePart = currentStatePart[key];
		}
	});

	return newState;
}

function setIn(state, value, ...path) {
	return setInGeneral(state, value, undefined, ...path);
}

setIn.withOptions = setInGeneral;

export default setIn;
