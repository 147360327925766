const updateSellerArchive = id => `/user/${id}/archive`;
const updateSellerBlock = id => `/user/${id}/block`;
const sellersPool = () => '/sellers-pool';
const assignSellersPool = id => `/sellers-pool/${id}/assignment`;
const listSellers = () => '/seller';
const updateSellerRoles = id => `/seller/${id}/roles`;
const resendSellerWelcomeEmail = id => `/seller/${id}/resend-welcome-email`;

const addCommentTo = id => `/seller/${id}/comments`;
const getCommentsOf = id => `/seller/${id}/comments`;
const deleteComment = id => `/seller/comments/${id}`;

const updateHiddenStatus = id => `/seller/${id}/update-hidden`;

export default ({
	sellersPool,
	assignSellersPool,
	listSellers,
	updateSellerRoles,
	addCommentTo,
	getCommentsOf,
	updateSellerArchive,
	updateSellerBlock,
	deleteComment,
	resendSellerWelcomeEmail,
	updateHiddenStatus
});
