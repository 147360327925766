import {showError, showWorkHours} from '../actions/notifications';
import makeRequest from '../../utils/http/make-request';
import CaledarApiRoutes from '../../api/calendar';
import {SIGN_IN} from '../../components/guest/signin-callback';
import {UserRoles} from '../../domain/user/roles';

const workHoursPopup = () => next => async action => {
	if (action.type === SIGN_IN && UserRoles.isAgent(action.user.roles)) {
		const {workHours} = CaledarApiRoutes;
		const criteriaApi = userId => `api/user/${userId}/criteria`;

		const workHoursReq = {
			method: 'get',
			url: `api${workHours(action.user.id)}`
		};

		const criteriaReq = {
			method: 'get',
			url: criteriaApi(action.user.id)
		};

		try {
			const {body: criterias} = await makeRequest(criteriaReq);
			const {doNotReceiveProperties} = criterias;

			if (!doNotReceiveProperties) {
				const {body: workHoursArr} = await makeRequest(workHoursReq);

				if (!workHoursArr) {
					next(showWorkHours());
				}

				if (workHoursArr && workHoursArr.length === 0) {
					next(showWorkHours());
				}
			}
		} catch (error) {
			if (error.status === 500) {
				next(showError({message: `500:${error.response.statusText}`}));
				throw new Error(error.response.statusText);
			}

			if (error.status === 404) {
				next(showWorkHours());
			}
		}
	}

	next(action);
};

export default workHoursPopup;
