import {showError, showSuccess} from '../actions/notifications';
import authClient from '../../utils/user-manager';
import makeRequest from '../../utils/http/make-request';

const httpMiddleware = store => next => async action => {
	const {http} = action;

	if (!http) {
		return next(action);
	}

	if (!http.request) {
		throw new Error('http request should be provided');
	}

	const request = {
		method: http.request.method,
		url: http.request.url,
		data: http.request.data
	};
	const userInfo = authClient.getUser();

	request.url = `/api${request.url}`;
	if (http.withToken) {
		request.token = userInfo.token;
	}

	try {
		const res = await makeRequest(request);

		action.http.response = res;

		next(action);

		if (http.request.onSuccess && http.request.onSuccess.notify) {
			const {message, settings, actionList} = http.request.onSuccess.notify;

			store.dispatch(showSuccess({message, settings, actionList}));
		}

		if (http.request.onSuccess && http.request.onSuccess.dispatchAction) {
			const actionOnSuccess = http.request.onSuccess.dispatchAction;

			store.dispatch(actionOnSuccess);
		}
	} catch (error) {
		const errdata = error.response.body && error.response.body.errors[0];

		if (action.http.defaultErrorsDisabled) {
			throw error;
		}

		if (errdata && errdata.key && errdata.key.length > 0) {
			next(showError({message: errdata.key}));
			throw error;
		}

		if (error.status === 500) {
			next(showError({message: `500:${error.response.statusText}`, oldError: true}));

			throw new Error(error.response.statusText);
		}

		if (error.status === 400) {
			next(showError({message: error.response.body.errors.map(item => `${item.name}:${item.message}`), oldError: true}));
			if (typeof error.response.body === 'string') {
				throw new Error(error.response.body);
			} else if (typeof error.response.body.errors !== 'undefined') {
				throw error.response.body;
			} else {
				throw new Error(error.response.body);
			}
		}

		if (error.status === 401) {
			await authClient.getUserAsync(true); // try to re-login and re-fetch data
			throw new Error(error.response.body);
		}

		if (error.message) {
			console.error(`${error.message} ${error.stack}`); // eslint-disable-line
		}

		throw error;
	}
};

export default httpMiddleware;
