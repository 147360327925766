import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function getLetters(userName) {
	return userName.split(' ').map(n => n[0]).join('').substr(0, 2);
}

class Avatar extends Component {
	render() {
		const {avatar} = this.props;

		return avatar ? this.renderImage() : this.renderTextCircle();
	}

	renderTextCircle() {
		const {classes, userName} = this.props;

		return (
			<Typography
				variant="subheading"
				color="inherit"
				className={classes.userNameIcon}
				align="center"
			>
				{getLetters(userName)}
			</Typography>
		);
	}

	renderImage() {
		const {classes, userName, avatar} = this.props;

		return (
			<Typography
				className={classes.userNameAvatar}
				align="center"
			>
				<img className={classes.userNameAvatarImage} src={avatar} alt={getLetters(userName)}/>
			</Typography>
		);
	}
}

Avatar.propTypes = {
	classes: PropTypes.object.isRequired,
	userName: PropTypes.string.isRequired,
	avatar: PropTypes.string
};

export default Avatar;
