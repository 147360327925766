import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useStyles} from './styles';
import menuActions from './actions';

function CardMenu({
	state: {
		anchorEl = null,
		contextAgent,
		contextCard,
		contextChildrenCount,
		variant,
		type,
		isViewerAdmin,
		isViewerSuperUser
	},
	...handlers
}) {
	const classes = useStyles();
	const {t} = useTranslation();
	const {toggleMenu} = handlers;
	const onClose = useCallback(() => toggleMenu(), [toggleMenu]);
	const onSelect = action => {
		toggleMenu();

		if (handlers[action]) {
			handlers[action](contextAgent.id);
		}
	};

	const openPopup = action => {
		toggleMenu();
		handlers.openPopup({
			page: action,
			contextAgent,
			contextCard
		});
	};

	return (
		<Menu
			id="card-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onClose}
		>
			{getListToRender({
				contextAgent,
				contextCard,
				contextChildrenCount,
				variant,
				type,
				isViewerAdmin,
				isViewerSuperUser,
				onSelect,
				openPopup,
			}).map(({fn, title}, index) => (
				<MenuItem key={`line_${index}`} className={classes.item} onClick={fn}>{title}</MenuItem>
			))}
		</Menu>
	);
};

function getListToRender({
	contextAgent,
	contextCard,
	contextChildrenCount,
	variant,
	type,
	openPopup,
	onSelect,
	isViewerAdmin,
	isViewerSuperUser,
	className
}) {
	const cardHasParent = contextCard?.parentId;
	const list = [];

	if (variant !== 'available' && isViewerAdmin) {
		list.push({fn: () => onSelect(menuActions.openProfile), title: 'Open profile'});
		list.push({fn: () => onSelect(menuActions.openProfileTree), title: 'Open profile`s tree'});
	}

	if (type === 'parent') return list;

	if(isViewerSuperUser) {
		if (cardHasParent ) {
			list.push({fn: () => openPopup(menuActions.replaceGodfather), title: 'Replace Godfather'});
			list.push({fn: () => openPopup(menuActions.detachGodfather), title: 'Detach Godfather'});
		} else {
			list.push({fn: () => openPopup(menuActions.attachGodfather), title: 'Assign Godfather'});
		}


		if (variant !== 'available' && contextAgent ) {
			list.push(
				!contextAgent.isArchived
					? {fn: () => openPopup(menuActions.archiveAgent), title: 'Archive agent'}
					: {fn: () => openPopup(menuActions.unarchiveAgent), title: 'Unarchive agent'}
			);

			list.push(
				!contextAgent.isBlocked
					? {fn: () => openPopup(menuActions.blockAgent), title: 'Block agent'}
					: {fn: () => openPopup(menuActions.unblockAgent), title: 'Unblock agent'}
			);
		}


		list.push({fn: () => openPopup(menuActions.cardNftSold), title: 'Card NFT sold'});


		if (type === 'card' && contextChildrenCount > 0) {
			list.push({fn: () => openPopup(menuActions.cutBranch), title: 'Prepare branch for sale'});
		}

		list.push({fn: () => openPopup(menuActions.assignParentCard), title: 'Assign parent card'});
	}

	return list;
};

CardMenu.displayName = 'CardMenu';

CardMenu.propTypes = {
	state: PropTypes.object.isRequired,
	toggleMenu: PropTypes.func.isRequired
};

export default memo(CardMenu);
