import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Notification from '../../common/notification-center';
import ThemeLayout from '../../common/theme';
import Logo from './logo';
import styles from './styles';

const Layout = ({children, classes, history}) => (
	<ThemeLayout>
		<div className={classes.root}>
			<Grid container classes={{container: classes.container}} direction="row" spacing={0}>
				<Grid item classes={{item: classes.column}}>
					<Logo classes={{root: classes.logo}} onClick={() => history.push('/')} />
				</Grid>
				{children}
			</Grid>
			<Notification/>
		</div>
	</ThemeLayout>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	history: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
