import {UserRoles} from '../user/roles';
import Route, {NestedRoute} from './helpers/route';

const {
	UNVERIFIED_AGENT, UNVERIFIED_SELLER, SELLER
} = UserRoles;

const sellerRoles = [UNVERIFIED_SELLER, SELLER];

const outils = new Route('outils-sextant', '/outils-sextant', 'client/outils', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles],
	hiddenFor: [...sellerRoles],
	i18nKey: 'outils-sextant.title',
	Icon: 'OutilsIcon'
});

const loiAlur = new NestedRoute(outils, 'loi-alur', 'loi-alur', 'client/loi-alur', {
	i18nKey: 'outils-sextant.loi-alur'
});

const faqs = new Route('faqs', '/faqs', 'client/faqs', {
	Icon: 'FaqsIcon'
});

const myOptions = new Route('alacarte', '/alacarte', 'client/my-options', {
	Icon: 'AlacarteIcon'
});

const extranet = new Route('extranet', '/extranet', 'client/extranet', {
	disabledFor: [UNVERIFIED_AGENT, ...sellerRoles],
	Icon: 'ExtranetIcon'
});

export {
	extranet,
	faqs,
	outils,
	loiAlur,
	myOptions
};
