import {createMuiTheme, Theme} from '@material-ui/core/styles';
import {FontWeightAbsolute, TextTransformProperty} from 'csstype';
import {PaletteColor} from '@material-ui/core/styles/createPalette';

// Settings for material-ui
//  h2 = variant="headline"
// 	h3 = variant="title"
// 	summary = variant="subheading"
// 	paragraph = variant="body1"
// 	paragraphSmall = variant="body2"
// 	button = variant="button"
// 	small = variant="subtitle1"
// 	smaller = variant="caption"

const settings = {
	typography: {
		h1: {
			color: '#222222',
			fontSize: '72px',
			lineHeight: '1'
		},
		h2: {
			color: '#222222',
			fontSize: '52px',
			lineHeight: '1.25'
		},
		h3: {
			color: '#333333',
			fontSize: '32px',
			lineHeight: '1.25'
		},
		h4: {
			color: '#333333',
			fontSize: '24px',
			lineHeight: '1.25'
		},
		summary: {
			color: '#333333',
			fontSize: '24px',
			lineHeight: '1.25',
			fontWeight: 300
		},
		paragraph: {
			color: '#333333',
			fontSize: '18px',
			lineHeight: '1.5',
			fontWeight: 300
		},
		paragraphSmall: {
			color: '#333333',
			fontSize: '16px',
			lineHeight: '1.5',
			fontWeight: 500
		},
		small: {
			color: '#333333',
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 300
		},
		smaller: {
			color: '#333333',
			fontSize: '12px',
			lineHeight: '18px'
		},
		button: {
			color: '#ffffff',
			fontSize: '18px',
			lineHeight: '1.5',
			fontWeight: 'bold' as FontWeightAbsolute,
			textTransform: 'none' as TextTransformProperty,
			height: 48,
			borderRadius: '2px'
		}
	}
};

export const verificationStatusColors = {
	filled: '#F2C94C',
	rejected: '#EB5757',
	verified: '#6FCF97'
};

export const screenSizes = {
	tablet: 768,
	desktop: 1156,
	wideDesktop: 1600
};

const theme = createMuiTheme({
	// @ts-ignore
	shadows: Array(25).fill('none') as Shadows,
	palette: {
		type: 'light',
		background: {
			default: '#F2F6F8',
			// @ts-ignore
			mobile: '#FFF'
		},
		primary: {
			light: '#0FBACD',
			// main: '#35B9CC',
			main: '#07636D',
			600: '#31B7C4',
			700: '#2A99A6',
			// 800: '#00899B',
			// dark: '#00899B'
			800: '#04454C',
			dark: '#04454C'
		},
		layout: {
			light: '#0FBACD',
			main: '#07636D',
			dark: '#04454C'
		},
		secondary: {
			light: '#ef7878',
			main: '#EB5757',
			dark: '#a43c3c'
		},
		keros: {
			light: '#BA4065',
			main: '#BA4065',
			dark: '#92314E'
		},
		grey: {
			// @ts-ignore
			light: '#F2F6F8',
			main: '#BBBBBB',
			dark: '#696969',
			darker: '#4E4E4E',
			heading: '#222222',
			text: '#7E8697'
		},
		divider: 'rgba(238, 238, 238, 0.3)',
		action: {
			selected: 'rgba(187, 187, 187, 0.3)'
		},
		status: verificationStatusColors,
		custom: {
			error: {
				light: '#f7d7da',
				dark: '#dc3545',
			},
			warning: {
				light: '#fff3cd',
				dark: '#f0ad4e',
			}
		}
	},
	typography: {
		fontFamily: [
			'Montserrat',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif'
		].join(','),
		headline: {...settings.typography.h2},
		title: {...settings.typography.h3},
		subheading: {...settings.typography.summary},
		body1: {...settings.typography.paragraph},
		body2: {...settings.typography.paragraphSmall},
		button: {...settings.typography.button},
		subtitle1: {...settings.typography.small},
		caption: {...settings.typography.smaller},
		h4: {...settings.typography.h4}
	},
	screenSizes,
	spacing: {
		unit: 8,
		// @ts-ignore
		landing: {
			top: 90
		},
		// @ts-ignore
		sidebar: 230,
		header: 80,
		bottomNavigation: 72
	},
	// TODO: remove this when it will be fixed
	overrides: {
		MuiButton: {
			contained: {
				height: 48,
				fontWeight: 'normal'
			},
			raisedPrimary: {
				background: '#0FBACD',
				height: 48,
				minWidth: 264,
				fontWeight: 'bold'
			}
		},
		MuiInputLabel: {
			outlined: {
				zIndex: 0,
				transform: 'translate(14px, 14px) scale(1)',
				'&$shrink': {
					zIndex: 1
				}
			},
			shrink: {}
		},
		MuiOutlinedInput: {
			input: {
				padding: '10.5px 14px'
			},
			multiline: {
				boxSizing: 'border-box'
			},
			adornedEnd: {
				boxSizing: 'border-box'
			}
		},
		// @ts-ignore
		MuiPickersCalendarHeader: {
			transitionContainer: {
				height: '24px'
			},
			dayLabel: {
				width: 'calc(100% / 7)',
				padding: '5px',
				margin: '3px',
				fontSize: '12px',
				lineHeight: '15px',
				fontWeight: 300,
				color: '#555555',
				textTransform: 'uppercase'
			}
		},
		MuiPickersCalendar: {
			week: {
				'& > div': {
					width: 'calc(100% / 7)',
					textAlign: 'center'
				}
			},
			transitionContainer: {
				minHeight: '154px'
			}
		},
		MuiPickersDay: {
			day: {
				fontFamily: [
					'Montserrat',
					'"Helvetica Neue"',
					'Arial',
					'sans-serif'
				].join(','),
				borderRadius: '3px',
				fontSize: '14px',
				lineHeight: '16px',
				width: '100%',
				height: '24px',
				padding: '5px',
				margin: '3px',
				boxSizing: 'border-box',
				fontWeight: 500
			}
		}
	}
});

export type MySextantTheme = Theme & {
	palette: {
		custom: {
			error: Partial<PaletteColor>,
			warning: Partial<PaletteColor>,
		}
	}
};

export default theme;
