import PropTypes from 'prop-types';

const AGENT = 'agent';
const PROPERTY = 'property';
const asArray = [AGENT, PROPERTY];

export default {
	AGENT,
	PROPERTY,
	propType: PropTypes.oneOf(asArray)
};
