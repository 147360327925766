import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router';
import Layout from '../components/guest/layout';
import asyncComponent from '../utils/async-component';

const Landing = asyncComponent(() => import('../components/guest/landing')
	.then(module => module.default)
);

const Login = asyncComponent(() => import('../components/guest/signin')
	.then(module => module.default)
);

const EasySteps = asyncComponent(() => import('../components/guest/easy-steps')
	.then(module => module.default)
);

const Registration = asyncComponent(() => import('../components/guest/sign-up')
	.then(module => module.default)
);

const AccessDenied = asyncComponent(() => import('../components/guest/access-denied')
	.then(module => module.default)
);

const GuestRoutes = ({skipLanding, location}) => (
	<Layout>
		<Switch location={location}>
			{!skipLanding && <Route exact path="/" component={Landing}/>}
			{!skipLanding && <Route exact path="/easy-steps" component={EasySteps}/>}
			<Route exact path="/sign-in" component={Login}/>
			{skipLanding && location.pathname === '/' && <Redirect to="/sign-in"/>}
			<Route exact path="/sign-up" component={Registration}/>
			<Route exact path="/403" component={AccessDenied}/>
			<Redirect to="/"/>
		</Switch>
	</Layout>
);

GuestRoutes.propTypes = {
	skipLanding: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired
};

export default GuestRoutes;
