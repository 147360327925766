import Route from './route';

export default class LayoutGroup {
	/**
	 *  Name of the layout used for this group.
	 */
	layoutComponentName;

	/**
	 * Route which's link is used for layout group.
	 */
	master;

	/**
	 * Array of routes displayed inside specified layout.
	 */
	routes;

	constructor(layoutComponentName, masterRoute, routes) {
		if (!(masterRoute instanceof Route)) {
			throw new Error('Configuration error: masterRoute argument should be instance of route');
		}

		if (!routes.every(x => x instanceof Route)) {
			throw new Error('Configuration error: routes argument should be instance of route');
		}

		this.layoutComponentName = layoutComponentName;
		this.routes = routes;
		this.master = masterRoute;
	}
}
