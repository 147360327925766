const MANDATE_NUMBER = 'mandateNumber';
const POSTAL_CODE = 'postalCode';
const SELLER = 'seller';
const AGENT = 'agent';

const asArray = [MANDATE_NUMBER, POSTAL_CODE, SELLER, AGENT];

export default ({
	asArray,
	MANDATE_NUMBER,
	POSTAL_CODE,
	SELLER,
	AGENT
});
