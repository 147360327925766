import React from 'react';
import {Switch, Route} from 'react-router';
import asyncComponent from '../utils/async-component';
import WithAuth from './with-auth';
import RoutingContext from './routing-context';
import ClientRoutes from './client';
import GuestRoutes from './guest';

const SiginInCallback = asyncComponent(() => import('../components/guest/signin-callback')
	.then(module => module.default)
);

const SilentSigninCallback = asyncComponent(() => import('../components/guest/signin-silent-callback')
	.then(module => module.default)
);

class Routes extends React.Component {
	render() {
		return (
			<RoutingContext.Provider>
				<Switch>
					<Route exact path="/signin-callback" component={SiginInCallback}/>
					<Route exact path="/silent-signin-callback" component={SilentSigninCallback}/>
					<WithAuth
						areaAuth={ClientRoutes}
						areaNonAuth={GuestRoutes}
					/>
				</Switch>
			</RoutingContext.Provider>
		);
	}
}

export default Routes;
