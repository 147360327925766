import {actionTypes} from 'redux-resource';
import {showNotification, showError} from '../actions/notifications';
import parseHeaders from '../../utils/http/parse-headers';
import makeRequest from '../../utils/http/make-request';
import userManager from '../../utils/user-manager';
import {Types as NotifTypes} from '../../components/common/notification-center';

function getProperMethod(resourceType) {
	switch (resourceType) {
		case 'READ':
			return 'get';
		case 'CREATE':
			return 'post';
		case 'UPDATE':
			return 'put';
		case 'DELETE':
			return 'delete';
		default:
			return resourceType;
	}
}

function transformResource(body, responseId, postTransform) {
	if (postTransform) {
		body = postTransform(body);
	}

	if (responseId) {
		body = {
			...body,
			id: responseId
		};
	}

	return body instanceof Array ? body : [body];
}

export default () => next => action => {
	const {
		resourceType,
		resources,
		type,
		request,
		requestType = 'json',
		responseType,
		responseId, // for media
		postTransform,
		list = 'all',
		url = '',
		remoteApi,
		data = {}
	} = action;

	if (typeof resourceType === 'undefined') {
		return next(action);
	}

	// set status pending
	next(action);
	const requestPrefix = type.split('_')[0];

	if (requestPrefix === 'RESET') {
		return;
	}

	const {token} = userManager.getUser();

	const req = makeRequest({
		url: remoteApi || `/api/${url}`,
		requestType,
		method: action.method || getProperMethod(type.split('_')[0]),
		token,
		data,
		responseType
	});

	return req
		.then(res => ({
			body: res.body,
			headers: parseHeaders(res.header || {})
		}))
		.then(res => {
			if (requestPrefix === 'DELETE') {
				next({
					type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
					resourceType,
					resources
				});
				return res;
			}

			const isImage = /^image\//.test(res.headers.contentType);
			const isPdf = res.headers.contentType === 'application/octet-stream';

			if (isImage || isPdf) {
				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					list,
					request,
					resources: [{id: responseId, src: res.body}],
					count: 1
				});
				return res;
			}

			next({
				type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
				resourceType,
				list,
				request,
				resources: transformResource(res.body, responseId, postTransform),
				count: res.body.count || res.body.length || 0
			});
			return res;
		})
		.catch(async error => {
			const errdata = error.response.body && error.response.body.errors[0];

			next({
				type: actionTypes[`${requestPrefix}_RESOURCES_FAILED`],
				request,
				resourceType,
				resources
			});

			if (errdata && errdata.key && errdata.key.length > 0) {
				next(showError({message: errdata.key}));
				return;
			}

			if (error.status === 500) {
				next(showNotification({
					message: error.response.statusText,
					settings: {
						variant: NotifTypes.ERROR
					}
				}));

				return Promise.reject(error.response.statusText);
			}

			if (error.status === 400) {
				next(showNotification({
					message: error.response.body.errors.map(item => `${item.name}: ${item.message}`),
					settings: {
						variant: NotifTypes.ERROR
					}
				}));

				return Promise.reject(error.response.body);
			}

			if (error.status === 401) {
				console.error("Failed to get resource. SignOut", error);

				userManager.logOut();
				// await next(logout());
				// next(showNotification('signed-out', 'error'));

				return Promise.reject(error.response.body);
			}

			if (error.message) {
				if (!(action.list === 'avatar' && error.status === 404)) {
					console.error(`${error.message} ${error.stack}`); // eslint-disable-line
				}
			}

			return Promise.reject(error);
		});
};
