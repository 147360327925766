/* eslint-disable no-console */

const logger = store => next => action => {
	if (process.env.REDUX_LOGGING !== 'verbose') {
		return next(action);
	}

	console.group(action.type);
	console.info('dispatching', action);

	const result = next(action);

	console.log('next state', store.getState());
	console.groupEnd();

	return result;
};

export default logger;
