import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Languages from '../../../../../domain/languages';
import BritishFlag from './icons/british-flag';
import FrenchFlag from './icons/france-flag';
import styles from './styles';

const FLAGS_ICONS = {
	[Languages.ENGLISH]: <BritishFlag/>,
	[Languages.FRENCH]: <FrenchFlag/>
};

function getCurrentLanguage(i18n) {
	if (Languages.asArray.includes(i18n.language)) {
		return i18n.language;
	}

	return Languages.DEFAULT;
}

const LangSelectMenu = withStyles(styles)(({
	classes,
	anchorEl,
	handleSetLang,
	handleClose
}) => {
	const open = Boolean(anchorEl);
	const {t, i18n} = useTranslation();
	const currentLang = getCurrentLanguage(i18n);

	return (
		<Menu
			className={classes.langMenu}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			open={open}
			PaperProps={{
				style: {
					width: 200
				}
			}}
			onClose={handleClose}
		>
			{Languages.asArray.map(lang => (
				<MenuItem
					key={lang}
					selected={currentLang === lang}
					className={classes.menuItem}
					onClick={() => handleSetLang(lang)}
				>
					<ListItemIcon>
						{FLAGS_ICONS[lang]}
					</ListItemIcon>
					<ListItemText className={classes.labelLang} disableTypography primary={t(`basic:languages.${lang}`)} />
				</MenuItem>
			))}
		</Menu>
	);
});

const LanguageSwitch = ({
	classes,
	onClose
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const {i18n} = useTranslation();
	const currentLang = getCurrentLanguage(i18n);

	const handleOpen = event => {
		if (!anchorEl) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		if (typeof onClose === 'function') {
			onClose();
		}
	};

	const handleSetLang = lang => {
		handleClose();
		i18n.changeLanguage(lang);

		if (typeof onClose === 'function') {
			onClose();
		}
	};

	return (
		<>
			<Button
				className={classes.root}
				onClick={handleOpen}
			>
				<div className={classes.buttonContent}>
					<ListItemIcon className={cn(classes.currentLangIcon, classes[currentLang])} title={currentLang.toUpperCase()}>
						{FLAGS_ICONS[currentLang]}
					</ListItemIcon>
				</div>
			</Button>
			<LangSelectMenu
				anchorEl={anchorEl}
				handleSetLang={handleSetLang}
				handleClose={handleClose}
			/>
		</>
	);
};

LanguageSwitch.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func
};

export default withStyles(styles)(LanguageSwitch);
