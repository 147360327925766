import React from 'react';
import {SnackbarProvider} from 'notistack';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
	info: {
		backgroundColor: theme.palette.primary.main
	},
	contentRoot: {
		flexWrap: 'nowrap',
		'@media (max-width: 767px)': {
			flexWrap: 'wrap'
		}
	},
	action: {
		flexWrap: 'nowrap'
	},
	message: {
		fontSize: 15
	}
});

const NotificationsProvider = withStyles(styles)(({classes, children}) => {
	return (
		<SnackbarProvider
			maxSnack={4}
			ContentProps={{classes: {
				message: classes.message,
				action: classes.action,
				root: classes.contentRoot
			}}}
			classes={{
				variantInfo: classes.info
			}}
		>
			{children}
		</SnackbarProvider>
	);
});

export default NotificationsProvider;
