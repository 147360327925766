export const transError = (t, message) => {
	const isArray = Array.isArray(message);

	if (isArray) {
		return message.reduce((res, curr) => {
			const delimI = curr.indexOf(':');
			const field = t(`basic:fields.${curr.slice(0, delimI)}`);
			const mess = t(`error:${curr.slice(delimI + 1)}`);

			res += `${field}: ${mess}`;

			return res;
		}, '');
	}

	const isInternal = message.indexOf('500') > -1;

	if (isInternal) {
		const delimI = message.indexOf(':');

		return `${message.slice(delimI + 1)}: ${t('error:something-went-wrong')}`;
	}

	return t(`error:${message}`);
};

export default transError;
