import {UserRoles} from '../user/roles';
import Route, {NestedRoute} from './helpers/route';

const {
	UNVERIFIED_AGENT, AGENT, LMNP_AGENT, UNVERIFIED_SELLER, SELLER
} = UserRoles;

const agentRoles = [UNVERIFIED_AGENT, AGENT, LMNP_AGENT];
const sellerRoles = [UNVERIFIED_SELLER, SELLER];

const userManagement = new Route('user-management', '/user-management', 'client/user-management', {
	disabledFor: [...agentRoles, ...sellerRoles],
	disabledAsHiddenInNav: true,
	Icon: 'UserManagementIcon',
	i18nKey: 'user-management.title'
});

const showProfileForAdmin = new NestedRoute(userManagement, 'profile', 'profile/:userId', 'client/profile/profile-show');

const verifyProfile = new NestedRoute(userManagement, 'verify-profile', 'verify-profile/:userId', 'client/profile/profile-review');
const verifyDocuments = new NestedRoute(userManagement, 'verify-documents', 'verify-documents/:userId', 'client/documents/verify-documents');
const commissionSettings = new NestedRoute(userManagement, 'commission-settings', 'commission-settings/:userId', 'client/profile/commission-settings');

const addProfile = new NestedRoute(userManagement, 'profile-add', 'profile-add', 'client/profile/profile-add');

const adminDocumentsUpload = new NestedRoute(
	userManagement,
	'document-upload',
	'document-upload/:key/:userId',
	'client/documents/verify-documents/upload'
);

const familyTreeOld = new NestedRoute(userManagement, 'family-tree-old', 'family-tree-old/:userId', 'client/family-tree-old');
const familyTree = new NestedRoute(userManagement, 'family-tree', 'family-tree/:userId', 'client/family-tree');

const zohoList = new Route('zoho-list', '/zoho', 'client/zoho-list', {
	disabledFor: [...agentRoles, ...sellerRoles],
	hiddenFor: [...agentRoles, ...sellerRoles],
	Icon: 'ZohoCustomersIcon'
});

const adminCategory = new Route('admin', '/admin', 'client/not-found', {
	Icon: 'SupervisorAccountIcon',
	disabledFor: [...agentRoles, ...sellerRoles],
	disabledAsHiddenInNav: true,
	i18nKey: 'sidebar.admin'
});

export {
	userManagement,
	showProfileForAdmin,
	verifyProfile,
	verifyDocuments,
	commissionSettings,
	addProfile,
	adminDocumentsUpload,
	familyTreeOld,
	familyTree,
	zohoList,
	adminCategory
};
