export const detachAgent = id => `/user/${id}/parent/detach`;
export const getZohoPlanCodes = () => '/subscriptions/plan-codes';
export const getZohoDetails = id => `/subscriptions/pack-gold-periods?AgentId=${id}`;
export const getZohoCustomers = () => '/customer';
export const getZohoSubSummary = () => '/subscriptions/summary';
export const cancelZohoOption = optionId => `/subscriptions/options/cancel/${optionId}`;
export const addZohoOption = () => '/subscriptions/options/subscribe';
export const changeZohoSub = () => '/subscriptions/change';
export const getZohoUnpaidInvoices = () => '/invoices/unpaid';

export default {
	detachAgent,
	getZohoDetails,
	getZohoCustomers,
	getZohoSubSummary,
	cancelZohoOption,
	addZohoOption,
	changeZohoSub,
	getZohoUnpaidInvoices
};
