import {CommissionSettings} from '../../domain/fields';
import {REACT_TEXTFIELD_EMPTY_VALUE} from '../../components/client/profile/commission-settings/components/start-boost';

export default ({
  [CommissionSettings.AGENT_TYPE]: {
    "title": "Agent type:",
    [CommissionSettings.AGENT_TYPE_PRO]: "Professional",
    [CommissionSettings.AGENT_TYPE_SEMIPRO]: "Semi-pro",
    [CommissionSettings.AGENT_TYPE_BEGINNER]: "Beginner"
  },
  [CommissionSettings.COMMISSIONS]: {
    "title": "Present <strong>commission</strong> rate",
    "turnover": "Total turnover done<br/>this year<br/><strong><1>!#!turnover!#!</1></strong>",
    "hisSales": "Agent<br/>sales",
    "childrenSales": "The sales<br/>of children",
    "allSales": "All<br/>sales",
  },
  [CommissionSettings.FIDELITY_BONUS]: {
    "title": "Fidelity bonus (after 12 months):",
    [CommissionSettings.FIDELITY_BONUS_MEMBERSHIP]: "N° of years with Sextant:",
    [CommissionSettings.FIDELITY_BONUS_TURNOVER]: "With a turnover above:",
    "goals": "Goal reached for the year:",
    [CommissionSettings.FIDELITY_BONUS_GOALACHIEVED2017]: "2017",
    [CommissionSettings.FIDELITY_BONUS_GOALACHIEVED2018]: "2018",
    [CommissionSettings.FIDELITY_BONUS_TOTALBONUS]: "Total fidelity bonus:",
	[CommissionSettings.FIDELITY_BONUS_ACTIVE]: {
		"true": "Yes",
		"false": "No"
	  },
  },
  [CommissionSettings.AGENT_SUBSCRIPTION]: {
    "title": "Freebies management (monthly subscriptions):",
    [CommissionSettings.SUBSCRIPTION_FREE_PERIOD]: {
      "title": "Initial free period:",
      "value": "!#!value!#! month",
      "values": "!#!value!#! months"
    },
    [CommissionSettings.SUBSCRIPTION_FREE_PERIOD_END]: "Exceptionally free until:",
    [CommissionSettings.SUBSCRIPTION_FREE_DISCOUNT]: "6 month coupon for 50% off"
  },
  [CommissionSettings.HISTORY_BONUS]: {
    "title": "History bonus valid until !#!until!#! (1st year only)",
    [CommissionSettings.HISTORY_BONUS_COMMISSION]: "Starting commission depending on history with competitor",
  },
  [CommissionSettings.INDIVIDUAL_COMMISSION]: {
    "title": "Individual commission",
    "to": "to",
    "totf": "TO TF",
    "infinity": "Infinity",
    [CommissionSettings.INDIVIDUAL_COMMISSION_DEFAULT]: {
      "standard": "Standard version",
	  "eightyPercent": "80% 3 levels version",
      "custom": "Custom version"
    }
  },
  [CommissionSettings.INDIVIDUAL_TURNOVER]: {
    "title": "Individual turnover achieved: !#!turnover!#! HT"
  },
  [CommissionSettings.AGENT_INTEGRATION_DATE]: {
    "title": "Administrative integration date (Super admin only)"
  },
  [CommissionSettings.MLM_RATE]: {
    "title": "MLM rates",
    [CommissionSettings.MLM_RATE_VALUES]: "LEVEL !#!level!#!",
    [CommissionSettings.MLM_RATE_DEFAULT]: {
      "true": "2022 version",
      "false": "Custom version"
    }
  },
  [CommissionSettings.AGENT_ZOHOPACK]: {
    "title": "Monthly pack:",
    [CommissionSettings.AGENT_ZOHOPACK_GOLD]: "Gold pack",
    [CommissionSettings.AGENT_ZOHOPACK_SILVER]: "Silver pack",
    [CommissionSettings.AGENT_ZOHOPACK_BRONZE]: "Bronze pack",
    [CommissionSettings.AGENT_ZOHOPACK_UNLIMITED]: "Unlimited Pack"
  },
  [CommissionSettings.AGENT_CONTRACT]: {
    "title": "Present status:",
    [CommissionSettings.AGENT_CONTRACT_2022]: "2022 contract",
    [CommissionSettings.AGENT_CONTRACT_2018]: "Old 2018 contract"
  },
  [CommissionSettings.START_BOOST]: {
    "title": "Welcome Bonus:",
    [CommissionSettings.START_BOOST_ACTIVE]: {
      "true": "Yes",
      "false": "No"
    },
    [CommissionSettings.START_BOOST_COMMISSION_RATE]: "Commission rate:",
    [CommissionSettings.START_BOOST_SIGNMANDATES_PERIOD]: {
      "title": "Welcome Bonus eligibility duration:",
      "value": "!#!value!#! day",
      "values": "!#!value!#! days",
      [REACT_TEXTFIELD_EMPTY_VALUE]: "Not admissible"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_PERIOD]: {
      "title": "Maximum limit to <br/>close the sale:",
      "value": "!#!value!#! day",
      "values": "!#!value!#! days",
      [REACT_TEXTFIELD_EMPTY_VALUE]: "No end date"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_MANDATES_LIMIT]: {
      "title": "Max number of mandates <br/>allowed during period:",
      "value": "!#!value!#! mandate",
      "values": "!#!value!#! mandates"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_MANDATES]: "Mandates:",
    [CommissionSettings.START_BOOST_MANUAL_MANDATES]: "Manual mandates:"
  }
});
