export default ({
	langMenu: {
		zIndex: 10000
	},
	root: {
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		marginRight: 8
	},
	buttonContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexFlow: 'row nowrap'
	},
	label: {
		fontSize: 16,
		color: 'white'
	},
	labelLang: {
		fontSize: 16
	},
	currentLangIcon: {
		marginRight: 0
	},
	en: {
		width: 24,
		height: 16,
		'& svg': {
			width: 24,
			height: 16
		}
	},
	fr: {
		width: 24,
		height: 18,
		'& svg': {
			width: 24,
			height: 18
		}
	}
});
