const OFFER = 'offer';
const COMPROMIS = 'compromis';
const SOLD = 'sold';
const CANCELLED = 'cancelled';
const asArray = [OFFER, COMPROMIS, SOLD, CANCELLED];

export default ({
	asArray,
	OFFER,
	COMPROMIS,
	SOLD,
	CANCELLED
});
