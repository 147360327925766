class AuxiliaryField {
	constructor(key, type, editDisabled = true) {
		this.isAuxiliary = true;
		this.key = key;
		this.type = type;
		this.editDisabled = editDisabled;
	}
}

export default AuxiliaryField;
