import {PortalTypes} from '../../domain/property';

export default ({
  "activity": {
    "title": {
      "simple": "Simple Mandate Activity - Beta Version",
      "satisfaction": "Satisfaction Mandate Activity - Beta Version",
      "exclusive": "Exclusive Mandate Activity - Beta Version",
      "null": "Activity - Beta Version"
    },
    "views-average": "average daily views\nfor this property",
    "displays-on-details": "displays on\nproperty details",
    "views-department": "average daily views\nin the department",
    "email-contacts": "email contacts",
    "phone-contacts": "phone contacts",
    "attractivity-rate": "rate of attractivity",
    "non-premium-info": "Content is only accessible for Exclusive mandate",
    "contact-button": "Contact my agent for more info",
    "loading": "Loading...",
    "no-data-yet": "Data is not available yet",
    "email-to-agent": {
      "title": "Email to",
      "sent": "The email has been sent" 
    }
  },
  "graph": {
    "vertical-text": "View of your property online",
    "legend": {
      "online": "Online views",
      "price-reduction": "Price reduction",
      "viewings": "Viewings",
      "offers": "Offers"
    },
    "from": "from",
    "to": "to",
    "tooltip": {
      "online": "Online",
      "offer": "offer",
      "offers": "offers",
      "viewing": "viewing",
      "viewings": "viewings",
      "price-reduced": "Price reduced"
    }
  },
  "filters": {
    "hint": "Select portals for viewing analysis",
    "hint-seller": "Selected portals for viewing analysis",
    "to-be-continued": "To be continued..."
  },
  "websites": {
    "title": "Property websites",
    "summary": "Your property will be displayed on the following websites",
    "no-platforms": "You have not selected any ad websites yet",
    "edit-tooltip": "Select portals"
  },
  "modal": {
    "title1": "Select portals",
    "subtitle1": "Display the websites where the property is published",
    "title2": "Publish property on websites",
    "subtitle2": "Activate the property’s publication on the following websites\n(The user will be given direct access to the property’s location on the website)",
    "view-other": "View all the other portals"
  },
  "portals": {
    [PortalTypes.FRENCH]: {
      "title": "French portals"
    },
    [PortalTypes.INTERNATIONAL]: {
      "title": "International portals"
    },
    [PortalTypes.LUXURY]: {
      "title": "Luxury portals"
    },
    [PortalTypes.TRADE]: {
      "title": "Trade and enterprises portals"
    },
    [PortalTypes.OTHER]: {
      "title": "Other Advert websites"
    }
  }
});
