function defaultConstructPath(...args) {
	if (!this.link.includes(':')) {
		return this.link;
	}

	const parts = this.link.split('/');
	const path = parts.map(el => {
		if (el.startsWith(':')) {
			return args.shift();
		}

		return el;
	}).join('/');

	return path;
}

export default class Route {
	constructor(key, link, componentName, {
		disabledFor = [],
		hiddenFor = [],
		disabledAsHiddenInNav,
		constructPath,
		Icon,
		i18nKey = key,
		ignoreInBreadcrumbs = false,
		target = false
	} = {}) {
		this.key = key;
		this.link = link;
		this.componentName = componentName;

		this.disabledFor = disabledFor;
		this.hiddenFor = hiddenFor;
		this.disabledAsHiddenInNav = disabledAsHiddenInNav;
		this.Icon = Icon;
		this.i18nKey = i18nKey;
		this.ignoreInBreadcrumbs = ignoreInBreadcrumbs;
		this.target = target;

		if (typeof constructPath === 'function') {
			this.constructPath = constructPath;
		} else {
			this.constructPath = defaultConstructPath.bind(this);
		}

		Object.freeze(this);
	}

	allowedFor(user) {
		const {roles = []} = user;

		return !roles.find(x => this.disabledFor.includes(x));
	}

	visibleFor(user) {
		const {roles = []} = user;

		return !roles.find(x => this.hiddenFor.includes(x));
	}
}

export class NestedRoute extends Route {
	constructor(parent, key, link, componentName, other = {}) {
		key = key ? `${parent.key}.${key}` : parent.key;
		link = link ? `${parent.link}/${link}` : parent.link;

		if (!(componentName.startsWith('client/')
			|| componentName.startsWith('guest/')
			|| componentName.startsWith('common/'))) {
			componentName = componentName ? `${parent.componentName}/${componentName}` : parent.componentName;
		}

		if (!other.disabledFor) {
			other.disabledFor = parent.disabledFor;
		}

		if (!other.hiddenFor) {
			other.hiddenFor = parent.hiddenFor;
		}

		super(key, link, componentName, other);
	}
}
