import {AgentLevel} from '../../domain/user/types';
import {menuActions} from '../../components/client/family-tree/components/card-menu';

export default ({
  "treeSelect": {
    "title": "Liste des arbres :",
    "placeholder": "Choisir",
    "primaryItem": "Arbre principal",
    "item": "Card №!#!cardId!#! arbre"
  },
  "level": {
    [AgentLevel.unset2018]: "2018",
    [AgentLevel.N1]: "N1",
    [AgentLevel.N2]: "N2",
    [AgentLevel.N3]: "N3"
  },
  "cardId": "Carte <br/>unique №<br/>!#!cardId!#!",
  "agent": "<i><strong>!#!name!#!</strong><strong>!#!level!#!</strong></i>",
  "saleLabel": "Carte à vendre,<br/>rapport Keros activé",
  "available": "<strong>Position disponible</strong>Agent précédent :<i>!#!name!#!</i>",
  "grantee": "<strong>Position Grantee</strong><i>Agent actuel :</i><i>!#!name!#!</i>",
  "locked": "<strong>Position bloquée</strong><i>!#!name!#! !#!level!#!</i>",
  "archived": "<strong>Position archivée</strong>Agent précédent :<i>!#!name!#!</i>",
  "mentor": "<i><strong>!#!name!#!</strong><strong>!#!level!#!</strong></i>Parain",
  "plus-button": {
    "text": "Ajouter<br/>!#!count!#! filleul(s)",
    "tooltip": {
      "n1": "Vous devez devenir conseiller N2 pour ajouter des filleuls",
      "add-more-fixed": "Vous pouvez ajouter !#!count!#! filleul(s) sur votre niveau 1",
      "add-more": "+ Ajouter !#!count!#! conseiller(s)",
      "add-more_plural": "+ Ajoute !#!count!#! conseillers",
      "become-n3": "Vous devez devenir agent N3 pour ajouter 3 autres filleuls",
      "n3-expand": "Pour ajouter 3 conseillers sur votre 1er niveau, l'un de vos filleuls doit avoir 1 filleul"
    }
  },
  "turnover": {
    "paid": {
      "text": "CA encaissé: !#!count!#!€ TTC",
      "tooltip": "CA encaissé durant les 12 derniers mois"
    },
    "pending": {
      "text": "CA encours: !#!count!#!€ TTC",
      "tooltip": "CA sous offres et compromis de vente en cours à la date du jour"
    }
  },
  "popup": {
    "actions": {
      "yes": "Oui",
      "no": "Non"
    },
    "pages": {
      [menuActions.replaceGodfather]: {
        "title": "Remplacer parrain",
        "body": "Sélectionner le nouveau parrain ou le nouveau conseiller pour<br/><strong>!#!agent!#!</strong> en remplacement de <strong>!#!oldGodfather!#!</strong>"
      },
      [menuActions.detachGodfather]: {
        "title": "Détacher le parrain",
        "body": "Les commissions futures des filleuls seront perdues!<br/>Etes vous sur de vouloir <br/>détacher <strong>!#!agent!#!</strong> de <strong>!#!godfather!#!</strong>?"
      },
      [menuActions.attachGodfather]: {
        "title": "Attacher parrain",
        "body": "Sélectionner le nouveau parrain ou agent<br/>pour <strong>!#!name!#!</strong>"
      },
      [menuActions.archiveAgent]: {
        "title": "Archiver ce conseiller",
        "body": "Etes vous sur de vouloir archiver <strong>!#!name!#!</strong>?"
      },
      [menuActions.unarchiveAgent]: {
        "title": "Désarchiver le conseiller",
        "body": "Etes vous sur de vouloir désarchiver <strong>!#!name!#!</strong>?"
      },
      [menuActions.blockAgent]: {
        "title": "Bloquer ce conseiller",
        "body": "Etes vous sur de vouloir bloquer <strong>!#!name!#!</strong>?"
      },
      [menuActions.unblockAgent]: {
        "title": "Débloquer ce conseiller",
        "body": "Etes vous sur de vouloir débloquer <strong>!#!name!#!</strong>?"
      },
      [menuActions.cardNftSold]: {
        "title": "Carte NFT vendue",
        "body": "Sélectionner le conseiller qui a acheté cette carte",
        "checkbox-text": "Aussi archiver !#!name!#!"
      },
      [menuActions.cutBranch]: {
        "title": "Préparer la vente de cette branche",
        "body": "Etes vous sur de vouloir préparer la vente de la branche <strong>!#!name!#!</strong>?"
      },
      [menuActions.assignParentCard]: {
        "title": "Rétablir ancien parain",
        "body": "Etes vous sur de vouloir attribuer cette carte <br/>à <strong>!#!name!#!</strong>?<br/><br/>Renseigner le numéro de carte dans le champ ci-dessous"
      }
    }
  }
});
