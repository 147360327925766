import * as Types from '../actions/calendar';
import setIn from './helpers/set-in';

const initialState = {
	events: [],
	workHours: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Types.GET_CALENDAR_EVENTS:
			return setIn(state, action.data, 'events');

		case Types.CREATE_EVENT:
			return setIn(state, action.data, 'events', {key: 'id', value: action.data.id});
		case Types.EDIT_EVENT:
			const stateWithoutoldEvent = setIn(state, undefined, 'events', {key: 'id', value: action.data.id});
			return setIn(stateWithoutoldEvent, action.data, 'events', {key: 'id', value: action.data.eventId});
		case Types.DELETE_EVENT:
			return setIn(state, undefined, 'events', {key: 'id', value: action.data.eventId})

		case Types.RESET_CALENDAR_EVENTS:
			return setIn(state, initialState.events, 'events');

		case Types.GET_WORK_HOURS:
			return setIn(state, action.data, 'workHours');

		case Types.SET_WORK_HOURS:
			return setIn(state, action.data, 'workHours');

		case Types.RESET_WORK_HOURS:
			return setIn(state, initialState.workHours, 'workHours');

		default:
			return state;
	}
};
