import * as Types from '../actions/properties';
import setIn from './helpers/set-in';

const initialState = {
	list: [],
	data: {},
	offers: {},
	offerDetails: {},
	documents: {},
	status: {},
	stage: {},
	viewings: {},
	sellers: {},
	comments: {},
	contacts: {},
	details: {},
	valuationAppointments: {},
	survey: {},
	mandat: {},
	reducePrice: {},
	history: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Types.GET_PROPERTIES_LIST:
			return setIn(state, action.data, 'list');
		case Types.RESET_PROPERTIES_LIST:
			return setIn(state, initialState.list, 'list');
		case Types.GET_PROPERTY:
			return setIn(state, action.data, 'data', action.id);
		case Types.EDIT_PROPERTY:
			return setIn(state, action.data, 'data', action.id);
		case Types.EDIT_PROPERTY_STAGE:
			return setIn(state, action.http.request.data.stage, 'data', action.id, 'stage');
		case Types.EDIT_PROPERTY_STATUS:
			return setIn(state, action.http.request.data.status, 'data', action.id, 'status');
		case Types.RESET_PROPERTY:
			return setIn(state, undefined, 'data', action.id);

		case Types.GET_SURVEY:
			return setIn(state, action.data, 'survey');
		case Types.SIGN_SURVEY:
			return setIn(state, action.data, 'survey');
		case Types.CREATE_SURVEY:
			return setIn(state, action.data, 'survey');
		case Types.UPDATE_SURVEY:
			return setIn(state, action.data, 'survey');
		case Types.RESET_SURVEY:
			return setIn(state, undefined, 'survey');

		case Types.GET_MANDAT:
			return setIn(state, action.data, 'mandat');
		case Types.SIGN_MANDAT:
			return setIn(state, action.data, 'mandat');
		case Types.RESET_MANDAT:
			return setIn(state, undefined, 'mandat');
		case Types.CANCEL_MANDAT:
			return setIn(state, undefined, 'mandat');

		case Types.GET_REDUCE_PRICE:
			return setIn(state, action.data, 'reducePrice');
		case Types.SIGN_REDUCE_PRICE:
			return setIn(state, action.data, 'reducePrice');
		case Types.RESET_REDUCE_PRICE:
			return setIn(state, undefined, 'reducePrice');

		case Types.GET_PROPERTY_OFFERS_LIST:
			return setIn(state, action.data, 'offers', action.id);
		case Types.RESET_PROPERTY_OFFERS_LIST:
			return setIn(state, undefined, 'offers', action.id);
		case Types.GET_PROPERTY_OFFERS_DETAILS:
			return setIn(state, action.data, 'offerDetails');
		case Types.CHANGE_STATUS_PROPERTY_OFFER:
			return setIn(state, action.status, 'offerDetails', 'status');
		case Types.RESET_PROPERTY_OFFERS_DETAILS:
			return setIn(state, {}, 'offerDetails');
		case Types.GET_VALUATION_APPOINTMENT:
			return setIn(state, action.data, 'valuationAppointments', action.id);
		case Types.RESET_VALUATION_APPOINTMENT:
			return setIn(state, undefined, 'valuationAppointments', action.id);
		case Types.EDIT_VALUATION_APPOINTMENT:
			return setIn(state, action.data, 'valuationAppointments', action.id);
		case Types.EDIT_STATUS_VALUATION_APPOINTMENT:
			return setIn(state, action.data, 'valuationAppointments', action.id);
		case Types.GET_APPOINTMENT_STATUS:
			return setIn(state, action.data.status, 'valuationAppointments', action.id, 'agentResponse');
		case Types.UPDATE_APPOINTMENT_STATUS:
			return setIn(state, action.data.status, 'valuationAppointments', action.id, 'agentResponse');
		case Types.RESET_APPOINTMENT_STATUS:
			return setIn(state, undefined, 'valuationAppointments', action.id, 'agentResponse');
		case Types.GET_PROPERTY_DOCUMENTS_LIST:
			return setIn(state, action.data, 'documents', action.id);
		case Types.RESET_PROPERTY_DOCUMENTS_LIST:
			return setIn(state, undefined, 'documents', action.id);
		case Types.GET_PROPERTY_STATUS:
			return setIn(state, action.data, 'status', action.id);
		case Types.GET_PROPERTY_VIEWINGS_LIST:
			return setIn(state, action.data, 'viewings', action.id);
		case Types.RESET_PROPERTY_VIEWINGS_LIST:
			return setIn(state, undefined, 'viewings', action.id);
		case Types.FILL_VIEWING_FEEDBACK:
			return setIn(state, action.data, 'viewings', action.propertyId, {
				key: 'id', value: action.viewingId
			});
		case Types.CANCEL_VIEWING:
			return setIn(state, undefined, 'viewings', action.propertyId, {
				key: 'id', value: action.viewingId
			});
		case Types.EDIT_VIEWING_SCHEDULE:
			return setIn(state, action.data, 'viewings', action.propertyId, {
				key: 'id', value: action.viewingId
			});
		case Types.CREATE_VIEWING:
			return setIn(state, action.data, 'viewings', action.propertyId, {key: 'id', value: action.data.id});
		case Types.GET_PROPERTY_SELLERS:
			return setIn(state, action.data, 'sellers', action.id);
		case Types.ADD_CO_SELLER:
			return setIn(state, action.data, 'sellers', action.propertyId, {
				key: 'id'
			});
		case Types.REMOVE_SELLER_FROM_PROPERTY:
			return setIn(state, undefined, 'sellers', action.propertyId, {
				key: 'id',
				value: action.sellerId
			});
		case Types.RESET_PROPERTY_SELLERS:
			return setIn(state, undefined, 'sellers', action.id);
		case Types.GET_PROPERTY_COMMENTS:
			return setIn(state, action.data, 'comments', action.id);
		case Types.RESET_PROPERTY_COMMENTS:
			return setIn(state, undefined, 'comments', action.id);
		case Types.ADD_COMMENT_TO_PROPERTY:
			return setIn.withOptions(
				state,
				action.data,
				{
					insert: (array, value) => array.unshift(value)
				},
				'comments',
				action.propertyId,
				{
					key: 'id',
					value: action.data.id
				}
			);
		case Types.DELETE_COMMENT_OF_PROPERTY:
			return setIn(state, undefined, 'comments', action.propertyId, {
				key: 'id',
				value: action.commentId
			});
		case Types.GET_PROPERTY_CONTACTS:
			return setIn(state, action.data, 'contacts', action.id);
		case Types.RESET_PROPERTY_CONTACTS:
			return setIn(state, undefined, 'contacts', action.id);
		case Types.CREATE_PROPERTY_CONTACT:
			return setIn(state, action.data, 'contacts', action.id, {key: 'id', value: action.data.id});
		case Types.UPDATE_PROPERTY_CONTACT:
			return setIn(state, action.data, 'contacts', action.id, {key: 'id', value: action.data.id});
		case Types.GET_PROPERTY_DETAILS:
			return setIn(state, action.data, 'details', action.id);
		case Types.RESET_PROPERTY_DETAILS:
			return setIn(state, undefined, 'details', action.id);
		case Types.GET_HISTORY:
			return setIn(state, action.data, 'history', action.id);
		case Types.GET_PORTALS:
			return setIn(state, action.data, 'portalsData');
		case Types.UPDATE_PORTALS:
			return setIn(state, action.data, 'portalsData');
		case Types.RESET_PORTALS:
			return setIn(state, undefined, 'portalsData');
		case Types.GET_LMNP_AREA:
			return setIn(state, action.data, 'lmnpArea');
		case Types.RESET_LMNP_AREA:
			return setIn(state, undefined, 'lmnpArea');
		case Types.GET_ACTIVITY:
			return setIn(state, [action.data], 'activity', action.id);
		case Types.RESET_ACTIVITY:
			return setIn(state, undefined, 'activity', action.id);
		default:
			return state;
	}
};
