import React from 'react';
import * as PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import userManager from '../../../utils/user-manager';

export const SIGN_IN = 'SIGN_IN';

const SigninCallback = ({history}) => {
	const dispatch = useDispatch();

	const onLogin = async () => {
		const success = await userManager.logInCallback();

		if (success) {
			history.replace('/');
			const user = userManager.getUser();

			dispatch({
				type: SIGN_IN,
				user
			});
		}
	};

	React.useEffect(() => {
		onLogin();
	}, []);

	return null;
};

SigninCallback.propTypes = {
	history: PropTypes.object.isRequired
};

export default SigninCallback;
