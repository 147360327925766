import PropTypes from 'prop-types';

const VERIFIED = 'verified';
const REJECTED = 'rejected';
const FILLED = 'pending';
const EMPTY = 'none';
const asArray = [VERIFIED, REJECTED, FILLED, EMPTY];

export default ({
	VERIFIED,
	REJECTED,
	FILLED,
	EMPTY,
	asArray,
	propType: PropTypes.oneOf(asArray)
});

