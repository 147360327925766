import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {withTranslation as translate} from 'react-i18next';
import {
	compose, withHandlers, lifecycle
} from 'recompose';
import Header from './header';
import userManager from '../../../../utils/user-manager';

const mapStateToProps = () => {
	const {id: userId, avatar, token} = userManager.getUser();

	return {
		userId,
		token,
		avatar
	};
};

const enhance = compose(
	withRouter,
	connect(mapStateToProps),
	withHandlers(() => ({
		logout: () => () => userManager.logOut(),
		switchRole: () => () => userManager.switchUserRole()
	})),
	lifecycle({
		async componentDidMount() {
			this._isMounted = true;
			const {getRemoteAvatar, setDataState, needFetchAvatar} = this.props;

			if (needFetchAvatar) {
				const {body: avatarBlob} = await getRemoteAvatar();

				if (this._isMounted) {
					setDataState({
						avatarBlob,
						avatarFetched: true
					});
				}
			}
		},
		componentWillUnmount() {
			this._isMounted = false;
		}
	}),
	translate('navigation')
);

export default enhance(Header);
