import {CommissionSettings} from '../../domain/fields';
import {REACT_TEXTFIELD_EMPTY_VALUE} from '../../components/client/profile/commission-settings/components/start-boost';

export default ({
  [CommissionSettings.AGENT_TYPE]: {
    "title": "Type de conseiller:",
    [CommissionSettings.AGENT_TYPE_PRO]: "Professionnel",
    [CommissionSettings.AGENT_TYPE_SEMIPRO]: "Semi-pro",
    [CommissionSettings.AGENT_TYPE_BEGINNER]: "Débutant"
  },
  [CommissionSettings.COMMISSIONS]: {
    "title": "Taux de <strong>commission</strong> actuel",
    "turnover": "CA HT réalisé<br/>cette année<br/><strong><1>!#!turnover!#!</1></strong>",
    "hisSales": "Ses<br/>ventes",
    "childrenSales": "Les ventes<br/>de ses filleuls",
    "allSales": "Toutes<br/>les ventes",
  },
  [CommissionSettings.FIDELITY_BONUS]: {
    "title": "Bonus de fidélité (après 12 mois):",
    [CommissionSettings.FIDELITY_BONUS_MEMBERSHIP]: "Années chez Sextant:",
    [CommissionSettings.FIDELITY_BONUS_TURNOVER]: "Avec CA au dessus de:",
    "goals": "Objectif atteint pour:",
    [CommissionSettings.FIDELITY_BONUS_GOALACHIEVED2017]: "2017",
    [CommissionSettings.FIDELITY_BONUS_GOALACHIEVED2018]: "2018",
    [CommissionSettings.FIDELITY_BONUS_TOTALBONUS]: "Bonus de fidélité total:",
	[CommissionSettings.FIDELITY_BONUS_ACTIVE]: {
		"true": "Oui",
		"false": "Non"
	  },
  },
  [CommissionSettings.AGENT_SUBSCRIPTION]: {
    "title": "Gestion des gratuités (Cotisations):",
    [CommissionSettings.SUBSCRIPTION_FREE_PERIOD]: {
      "title": "Gratuité initiale:",
      "value": "!#!value!#! mois",
      "values": "!#!value!#! mois"
    },
    [CommissionSettings.SUBSCRIPTION_FREE_PERIOD_END]: "Gratuité exceptionnelle jusqu’au:",
    [CommissionSettings.SUBSCRIPTION_FREE_DISCOUNT]: "Coupon 6 mois à 50%"
  },
  [CommissionSettings.HISTORY_BONUS]: {
    "title": "Bonus historique !#!until!#! (1ère année chez Sextant)",
    [CommissionSettings.HISTORY_BONUS_COMMISSION]: "Commission de départ (historique chez concurrent):",
  },
  [CommissionSettings.INDIVIDUAL_COMMISSION]: {
    "title": "Commission individuellle",
    "to": "à",
    "totf": "CA HT",
    "infinity": "Infini",
    [CommissionSettings.INDIVIDUAL_COMMISSION_DEFAULT]: {
		"standard": "Version standard",
		"eightyPercent": "80% version 3 niveaux",
		"custom": "Version personnalisée"
    }
  },
  [CommissionSettings.INDIVIDUAL_TURNOVER]: {
    "title": "CA individuel en attente: !#!turnover!#! HT"
  },
  [CommissionSettings.AGENT_INTEGRATION_DATE]: {
    "title": "Date d'intégration administrative (Super admin only)"
  },
  [CommissionSettings.MLM_RATE]: {
    "title": "MLM rates",
    [CommissionSettings.MLM_RATE_VALUES]: "NIVEAU !#!level!#!",
    [CommissionSettings.MLM_RATE_DEFAULT]: {
      "true": "Version 2022",
      "false": "Version personnalisée"
    }
  },
  [CommissionSettings.AGENT_ZOHOPACK]: {
    "title": "Pack mensuel:",
    [CommissionSettings.AGENT_ZOHOPACK_GOLD]: "Pack Gold",
    [CommissionSettings.AGENT_ZOHOPACK_SILVER]: "Pack Silver",
    [CommissionSettings.AGENT_ZOHOPACK_BRONZE]: "Pack Bronze",
    [CommissionSettings.AGENT_ZOHOPACK_UNLIMITED]: "Pack Illimité"
  },
  [CommissionSettings.AGENT_CONTRACT]: {
    "title": "Statut actuel:",
    [CommissionSettings.AGENT_CONTRACT_2022]: "Nouveau contrat 2022",
    [CommissionSettings.AGENT_CONTRACT_2018]: "Ancien contrat 2018"
  },
  [CommissionSettings.START_BOOST]: {
    "title": "Welcome Bonus:",
    [CommissionSettings.START_BOOST_ACTIVE]: {
      "true": "Yes",
      "false": "No"
    },
    [CommissionSettings.START_BOOST_COMMISSION_RATE]: "Taux de commission:",
    [CommissionSettings.START_BOOST_SIGNMANDATES_PERIOD]: {
      "title": "Durée d'éligibilité du Welcome Bonus:",
      "value": "!#!value!#! jour",
      "values": "!#!value!#! jours",
      [REACT_TEXTFIELD_EMPTY_VALUE]: "Pas éligible"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_PERIOD]: {
      "title": "Durée max signature<br/>mandats:",
      "value": "!#!value!#! jour",
      "values": "!#!value!#! jours",
      [REACT_TEXTFIELD_EMPTY_VALUE]: "Pas de date de fin"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_MANDATES_LIMIT]: {
      "title": "Nombre de mandats <br/>acte des mandats éligibles:",
      "value": "!#!value!#! mandat",
      "values": "!#!value!#! mandats"
    },
    [CommissionSettings.START_BOOST_DISCOUNT_MANDATES]: "Mandats:",
    [CommissionSettings.START_BOOST_MANUAL_MANDATES]: "Mandats manuels:"
  }
});
