import {FORGOTTEN_PASSWORD, RESTORE_PASSWORD} from '../actions/forgotten-password';

export default () => next => async action => {
	// const {type, data} = action;
	const {type} = action;

	if (type === FORGOTTEN_PASSWORD) {
		return new Promise(resolve => {
			setTimeout(() => resolve(next({
				type
			})), 0);
		});
	}

	if (type === RESTORE_PASSWORD) {
		return new Promise(resolve => {
			setTimeout(() => resolve(next({
				type
			})), 0);
		});
	}

	return next(action);
};
