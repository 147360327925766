const ENGLISH = 'en';
const FRENCH = 'fr';

const DEFAULT = FRENCH;
const asArray = [ENGLISH, FRENCH];

export default ({
	ENGLISH,
	FRENCH,

	DEFAULT,
	asArray
});
