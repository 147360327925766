import {UserRoles} from '../user/roles';
import {userManagement} from './routes-msxt-admin';
import {outils} from './routes-msxt-common';
import {myProfile} from './routes-common';
import {sellersPropertiesList} from './routes-sellers';

export default function defaultRouteFor(user) {
	if (UserRoles.isAdmin(user.roles)) {
		return userManagement;
	}

	if (user.roles.includes(UserRoles.UNVERIFIED_AGENT)) {
		return myProfile;
	}

	if (user.roles.includes(UserRoles.AGENT) || user.roles.includes(UserRoles.LMNP_AGENT)) {
		return outils;
	}

	if (UserRoles.isSeller(user.roles)) {
		return sellersPropertiesList;
	}

	throw new Error(`Unknown user roles: ${user.roles.join(',')}`);
}
