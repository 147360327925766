/* eslint-disable no-multi-spaces */
import {createField} from './helpers';

export const AGENT_CONTRACT 			= 'agentContract';
export const AGENT_CONTRACT_2022 		= 'contract2022';
export const AGENT_CONTRACT_2018 		= 'contract2018';
export const AGENT_ZOHOPACK 			= 'agentZohoPack';
export const AGENT_ZOHOPACK_GOLD 		= 'gold';
export const AGENT_ZOHOPACK_SILVER 		= 'silver';
export const AGENT_ZOHOPACK_BRONZE 		= 'bronze';
export const AGENT_ZOHOPACK_UNLIMITED 	= 'unlimited';
export const AGENT_INTEGRATION_DATE 	= 'agentIntegrationDate';
export const AGENT_TYPE 				= 'agentType';
export const AGENT_TYPE_PRO 			= 'professional';
export const AGENT_TYPE_SEMIPRO 		= 'semiProfessional';
export const AGENT_TYPE_BEGINNER 		= 'beginner';
export const AGENT_SUBSCRIPTION 		= 'agentSubscription';
export const SUBSCRIPTION_FREE_PERIOD 		= 'freePeriod';
export const SUBSCRIPTION_FREE_PERIOD_END 	= 'freePeriodEnd';
export const SUBSCRIPTION_FREE_DISCOUNT		= 'discount';
export const HISTORY_BONUS 				= 'historyBonus';
export const HISTORY_BONUS_COMMISSION	= 'commission';
export const HISTORY_BONUS_TILL			= 'till';
export const START_BOOST 							= 'startBoost';
export const START_BOOST_ACTIVE						= 'active';
export const START_BOOST_COMMISSION_RATE			= 'commissionRate';
export const START_BOOST_SIGNMANDATES_PERIOD		= 'signMandatesPeriod';
export const START_BOOST_DISCOUNT_PERIOD			= 'discountPeriod';
export const START_BOOST_DISCOUNT_MANDATES_LIMIT	= 'discountMandatesLimit';
export const START_BOOST_DISCOUNT_MANDATES			= 'discountMandates';
export const START_BOOST_MANUAL_MANDATES			= 'manualMandates';
export const FIDELITY_BONUS 					= 'fidelityBonus';
export const FIDELITY_BONUS_ACTIVE						= 'active';
export const FIDELITY_BONUS_MEMBERSHIP 			= 'membership';
export const FIDELITY_BONUS_TURNOVER 			= 'turnover';
export const FIDELITY_BONUS_TURNOVERS 			= 'turnovers';
export const FIDELITY_BONUS_GOALACHIEVED2017 	= 'goalAchieved2017';
export const FIDELITY_BONUS_GOALACHIEVED2018 	= 'goalAchieved2018';
export const FIDELITY_BONUS_TOTALBONUS 			= 'totalBonus';
export const COMMISSIONS 							= 'commissions';
export const COMMISSIONS_COMMISSION_RATE 			= 'commissionRate';
export const COMMISSIONS_TOTAL_TUROVER_THIS_YEAR	= 'totalTuroverThisYear';
export const INDIVIDUAL_TURNOVER 				= 'individualTurnover';
export const INDIVIDUAL_TURNOVER_PENDING		= 'pending';
export const INDIVIDUAL_COMMISSION					= 'individualCommission';
export const INDIVIDUAL_COMMISSION_DEFAULT			= 'kind';
export const INDIVIDUAL_COMMISSION_VALUES			= 'values';
export const INDIVIDUAL_COMMISSION_FROM				= 'from';
export const INDIVIDUAL_COMMISSION_COMISSION		= 'commission';
export const INDIVIDUAL_COMMISSION_VERSIONS		= 'versions';
export const INDIVIDUAL_COMMISSION_DEFAULT_VERSIONS		= { Standard: 'standard', EightyPercent: 'eightyPercent', Custom: 'custom' };
export const MLM_RATE				= 'mlmRate';
export const MLM_RATE_DEFAULT		= 'default';
export const MLM_RATE_DEFAULTVALUES	= 'defaultValues';
export const MLM_RATE_VALUES		= 'values';

export default {
	AGENT_CONTRACT,
	AGENT_CONTRACT_2022,
	AGENT_CONTRACT_2018,
	AGENT_ZOHOPACK,
	AGENT_ZOHOPACK_GOLD,
	AGENT_ZOHOPACK_SILVER,
	AGENT_ZOHOPACK_BRONZE,
	AGENT_ZOHOPACK_UNLIMITED,
	AGENT_INTEGRATION_DATE,
	AGENT_TYPE,
	AGENT_TYPE_PRO,
	AGENT_TYPE_SEMIPRO,
	AGENT_TYPE_BEGINNER,
	AGENT_SUBSCRIPTION,
	SUBSCRIPTION_FREE_PERIOD,
	SUBSCRIPTION_FREE_PERIOD_END,
	SUBSCRIPTION_FREE_DISCOUNT,
	HISTORY_BONUS,
	HISTORY_BONUS_COMMISSION,
	HISTORY_BONUS_TILL,
	START_BOOST,
	START_BOOST_ACTIVE,
	START_BOOST_COMMISSION_RATE,
	START_BOOST_SIGNMANDATES_PERIOD,
	START_BOOST_DISCOUNT_PERIOD,
	START_BOOST_DISCOUNT_MANDATES_LIMIT,
	START_BOOST_DISCOUNT_MANDATES,
	START_BOOST_MANUAL_MANDATES,
	FIDELITY_BONUS,
	FIDELITY_BONUS_ACTIVE,
	FIDELITY_BONUS_MEMBERSHIP,
	FIDELITY_BONUS_TURNOVER,
	FIDELITY_BONUS_TURNOVERS,
	FIDELITY_BONUS_GOALACHIEVED2017,
	FIDELITY_BONUS_GOALACHIEVED2018,
	FIDELITY_BONUS_TOTALBONUS,
	COMMISSIONS,
	COMMISSIONS_COMMISSION_RATE,
	COMMISSIONS_TOTAL_TUROVER_THIS_YEAR,
	INDIVIDUAL_TURNOVER,
	INDIVIDUAL_TURNOVER_PENDING,
	INDIVIDUAL_COMMISSION,
	INDIVIDUAL_COMMISSION_DEFAULT,
	INDIVIDUAL_COMMISSION_VALUES,
	INDIVIDUAL_COMMISSION_FROM,
	INDIVIDUAL_COMMISSION_COMISSION,
	INDIVIDUAL_COMMISSION_VERSIONS,
	INDIVIDUAL_COMMISSION_DEFAULT_VERSIONS,
	MLM_RATE,
	MLM_RATE_DEFAULT,
	MLM_RATE_DEFAULTVALUES,
	MLM_RATE_VALUES
}
