import React, {Component} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	content: {
		backgroundColor: theme.palette.background.default,
		padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px ${theme.spacing.unit * 8.5}px ${theme.spacing.unit * 3}px`,
		boxSizing: 'border-box',
		height: `calc(100vh - ${theme.spacing.header}px)`,
		marginTop: theme.spacing.header,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		overflow: 'hidden',
		overflowY: 'auto',
		[theme.breakpoints.down('md')]: {
			overflowX: 'auto',
			padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 8.5}px ${theme.spacing.unit * 3}px`
		},
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 2}px`
		}
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	sidebarOpen: {
		marginLeft: theme.spacing.sidebar,
		[`@media(max-width: ${theme.screenSizes.desktop}px)`]: {
			// no content shift on tight screens
			marginLeft: 0
		}
	},
	sidebarClosed: {
		marginLeft: 0
	}
});

class Main extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false
		};

		this.scrollContainerRef = React.createRef();
	}

	componentDidMount() {
		window.scrollInstance = this.scrollContainerRef.current;
	}

	componentWillUnmount() {
		window.scrollInstance = null;
	}

	render() {
		const {children, classes, sidebarOpen} = this.props;
		const {hasError} = this.state;

		if (hasError) {
			return (
				<Typography
					variant="display1"
					align="center"
				>
					Something went wrong.
				</Typography>
			);
		}

		return (
			<main
				className={cn(
					classes.content,
					classes.contentShift,
					sidebarOpen ? classes.sidebarOpen : classes.sidebarClosed
				)}
				ref={this.scrollContainerRef}
			>
				{children}
			</main>
		);
	}

	componentDidCatch() {
		this.setState({hasError: true});
	}
}

Main.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	sidebarOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(Main);
