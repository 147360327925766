export default ({
  "topText": "You want to extend your network?<br/>Follow those few steps and we will study your potential Mentee’s application",
  "title": "Add new mentee",
  "fields": {
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "phoneNumber": "Phone",
    "commercialExperience": "Does he/she has commercial experience?",
    "estateExperience": "Does he/she has real estate experience?"
  },
  "checkbox": {
    "yes": "Yes",
    "no": "No"
  },
  "buttons": {
    "next": "Next",
    "prev": "Back",
    "submit": "Send it"
  },
  "success": "<strong>Congratulations!</strong><br/>Your application for <strong><1>!#!name!#!</1></strong> has been sent.<br/><br/>We will get in touch shortly."
});
