export default theme => ({
	sidebar: {
		width: theme.spacing.sidebar,
		background: theme.palette.layout.dark,
		zIndex: theme.zIndex.drawer + 2,
		paddingTop: theme.spacing.unit * 3,
		border: 'none',
		[`@media(max-width: ${theme.screenSizes.tablet}px)`]: {
			width: '100%'
		}
	},
	overlay: {
		display: 'none'
	},
	header: {
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%'
	},
	logoLink: {
		marginLeft: theme.spacing.unit * 3,
		width: 152,
		height: 40
	},
	sidebarControlButton: {
		color: 'white',
		marginRight: theme.spacing.unit,
		width: 40,
		height: 40,
		// no need to show sidebar control button when sidebar is always shown
		[`@media(min-width: ${theme.screenSizes.wideDesktop}px)`]: {
			display: 'none'
		}
	},
	logo: {
		width: 152,
		height: 40
	},
	nav: {
		padding: 0,
		marginTop: theme.spacing.unit * 2
	},
	itemContainer: {
		// padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`
		// padding: `${theme.spacing.unit / 2}px 0`
	},
	subItemContainer: {
		// padding: `${theme.spacing.unit / 2}px 0px`
	},
	subContainer: {
		backgroundColor: theme.palette.layout.main
	},
	divider: {
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: `${theme.spacing.unit * 2}px 0`,
		marginTop: theme.spacing.unit * 2
	},
	item: {
		border: 'none',
		borderRadius: 2,
		padding: `0 ${theme.spacing.unit * 3}px`,
		minHeight: theme.spacing.unit * 6,

		'$subItemContainer &': {
			minHeight: theme.spacing.unit * 4
		},

		'&$selected, &:hover, &:active, &:focus': {
			backgroundColor: 'transparent !important'
		}
	},
	selected: {
		'& $icon': {
			fill: theme.palette.primary.light
		}
	},
	itemTextRoot: {
		paddingRight: 0,
		paddingLeft: 0
	},
	subItemTextRoot: {
		// marginLeft: theme.spacing.unit * 2
		display: 'flex',
		minHeight: theme.spacing.unit * 4,
		alignItems: 'center',

		// '&$selected $itemText': {
		// 	color: theme.palette.primary.light
		// },
	},
	itemText: {
		...theme.typography.subtitle1,
		color: 'white',
		marginLeft: 0,
		transition: 'color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

		'$selected &': {
			color: theme.palette.primary.light
		}
	},
	iconRoot: {
		width: 36,
		height: 36,
		marginRight: theme.spacing.unit
	},
	icon: {
		// width: 36,
		display: 'inline-flex',
		alignSelf: 'center',
		fill: theme.palette.grey.light
	},
	nestedIcon: {
		fill: theme.palette.primary.light
	},
	toggleList: {
		color: 'white',
		padding: theme.spacing.unit / 2,
		marginRight: -theme.spacing.unit
	},
	subItem: {
		padding: 8
	}
});
