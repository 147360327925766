/* eslint-disable no-multi-spaces */
import FieldTypes from './field-type';
import {UserRoles} from '../user/roles';
import {createField, createLinkDocument} from './helpers';
import Position from './position';

export const {UNVERIFIED_AGENT, AGENT, LMNP_AGENT, ADMIN} = UserRoles;

export const adminRule = [ADMIN];
export const agentRule = [UNVERIFIED_AGENT, AGENT, LMNP_AGENT];
export const sellerRule = [UserRoles.SELLER, UserRoles.UNVERIFIED_SELLER];
export const renewalAllowed = true;

export const ZOHO_CCI_LINK = 'https://subscriptions.zoho.com/subscribe/e79a40fd4a18f7fd2241dbd6847588e96a2bba0f6b053217afe8ded60e8a6aa6/CCI';

export const FIRSTNAME         = createField('firstName',        FieldTypes.INPUT,     Position.PRIMARY_FORM, UserRoles.asArray, [AGENT]);
export const LASTNAME          = createField('lastName',         FieldTypes.INPUT,     Position.PRIMARY_FORM, UserRoles.asArray, [AGENT]);
export const EMAIL             = createField('email',            FieldTypes.INPUT,     Position.PRIMARY_FORM, UserRoles.asArray, [AGENT]);
export const TELEPHONE         = createField('phoneNumber',      FieldTypes.INPUT,     Position.PRIMARY_FORM, UserRoles.asArray, [AGENT]);

export const BIRTH_DATE        = createField('birthDate',        FieldTypes.DATE,      Position.PRIMARY_FORM, sellerRule, [AGENT]);
export const BIRTH_PLACE       = createField('birthPlace',       FieldTypes.INPUT,     Position.PRIMARY_FORM, sellerRule, [AGENT]);
export const NATIONALITY       = createField('nationality',      FieldTypes.INPUT,     Position.PRIMARY_FORM, sellerRule, [AGENT]);

export const JOB               = createField('job',              FieldTypes.INPUT,     Position.PRIMARY_FORM, adminRule);
export const POSTCODE          = createField('postalCode',       FieldTypes.INPUT,     Position.PRIMARY_FORM, [...agentRule, ...sellerRule],         [AGENT]);
export const ADDRESS           = createField('address',          FieldTypes.MULTILINE, Position.PRIMARY_FORM, [...agentRule, ...sellerRule], [AGENT]);
export const AVATAR            = createField('avatar',           FieldTypes.IMAGE,     Position.AVATAR,       UserRoles.asArray, [AGENT]);
export const SEXTANT_EMAIL     = createField('sextantEmail',     FieldTypes.INPUT,     Position.PRIMARY_FORM, agentRule,         agentRule);
export const AGENT_LEVEL 	   = createField('agentType', 		 FieldTypes.SELECT,     Position.PRIMARY_FORM, agentRule,         agentRule, agentRule);
export const VERIFICATION_DATE = createField('verificationDate', FieldTypes.DATE,      Position.PRIMARY_FORM, agentRule,         agentRule, agentRule, false, true);
export const START_DATE = createField('startDate', FieldTypes.DATE,      Position.PRIMARY_FORM, agentRule,         agentRule, agentRule);
export const LANDSCAPE_PHOTO   = createField('landscapePhoto',   FieldTypes.IMAGE,     Position.SUPPLEMENTARY_FORM, agentRule);
export const BIOGRAPHY         = createField('biography',        FieldTypes.MULTILINE, Position.SUPPLEMENTARY_FORM, agentRule);
export const DESCRIPTION       = createField('description',      FieldTypes.MULTILINE, Position.SUPPLEMENTARY_FORM, agentRule);

export const RECEIVE_NEWSLETTERS = createField('receiveNewsletters', FieldTypes.CHECKBOX,  Position.SUPPLEMENTARY_FORM, [...agentRule, ...sellerRule]);

export const IDCARD             = createField('idcard',           FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const BILL               = createField('bill',             FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const RSAC               = createField('rsac',             FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const RSI                = createField('rsi',              FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const RCP                = createField('rcp',              FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const VAT                = createField('vatNumber',        FieldTypes.INPUT,    Position.DOCUMENT);
export const ZOHO_CCI           = createLinkDocument('zohoCci',          {external: true, href: ZOHO_CCI_LINK});
export const ZOHO_SUBSCRIPTIONS = createLinkDocument('zohoSubscription', {external: false, href: '/my-documents/zoho-subscription'});
// export const LOI_ALUR           = createField('loiAlur',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR1821           = createField('loiAlur1821',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR2124           = createField('loiAlur2124',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR2427           = createField('loiAlur2427',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR2730           = createField('loiAlur2730',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR3033           = createField('loiAlur3033',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const LOI_ALUR3336           = createField('loiAlur3336',          FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);

export const CAR_INSURANCE      = createField('carInsurance',     FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);
export const ESTIMATED_PRICE    = createField('estimatedPrice',   FieldTypes.INPUT_MONEY);
export const PROPERTY_TYPE    	 = createField('propertyType',     FieldTypes.SELECT);
export const URSSAF             = createField('urssaf',           FieldTypes.DOCUMENT, Position.DOCUMENT, undefined, undefined, undefined, renewalAllowed);

export const SCI            				   = createField('SCI',              FieldTypes.CHECKBOX);
export const PROPERTY_ADDRESS = 					createField('propertyAddress',					FieldTypes.INPUT);
export const PROPERTY_PRICE = 						createField('propertyPrice',					FieldTypes.INPUT);
export const LOAN_AMOUNT = 						createField('loanAmount',						FieldTypes.INPUT);
export const PASSPORT_OR_CARD_ID = 				createField('passportOrIdCard',					FieldTypes.RADIO);
export const CERTIFIED_PASSPORT_COPY = 			createField('certifiedPassportCopy',			FieldTypes.RADIO);
export const PHOTO_VERIFIED = 						createField('photoVerified',					FieldTypes.RADIO);
export const SIGNATURE_VERIFIED = 					createField('signatureVerified',				FieldTypes.RADIO);
export const PROOF_OF_RESIDENCY = 					createField('proofOfResidency',					FieldTypes.RADIO);
export const LIVE_IN_FRANCE = 						createField('liveInFrance',						FieldTypes.RADIO);
export const VERIFIED_BY_GAFI = 					createField('verifiedByGAFI',					FieldTypes.RADIO);
export const ADDRESS_VERIFIED = 					createField('addressVerified',					FieldTypes.RADIO);
export const JOB_TITLE = 							createField('jobTitle',							FieldTypes.INPUT);
export const KBIS = 								createField('kbis',								FieldTypes.RADIO);
export const COMPANY_CHARTER_PROVIDED = 			createField('companyCharterProvided',			FieldTypes.RADIO);
export const CERTIFICATE_OF_REGISTRYP_ROVIDED = 	createField('certificateOfRegistryProvided',	FieldTypes.RADIO);
export const CERTIFICATE_OF_CUSTOMS_PROVIDED = 	createField('certificateOfCustomsProvided',		FieldTypes.RADIO);

export const LOCATION  = createField('location',   FieldTypes.RATING);
export const FINISHANDLAYOUT  = createField('finishAndLayout',   FieldTypes.RATING);
export const VALUE  = createField('value',   FieldTypes.RATING);
export const COMMENT  = createField('comment',   FieldTypes.MULTILINE);

export const OFFER_PRICE  = createField('price',   FieldTypes.INPUT);
export const MANDATE_NUMBER = createField('mandateNumber', FieldTypes.INPUT);
export const BUYERS = createField('buyers', FieldTypes.INPUT);
export const BUYERS_POSITION  = createField('buyersPosition',   FieldTypes.INPUT);
export const OFFER_CONDITIONS  = createField('conditions',   FieldTypes.INPUT);
export const OFFER_AGENT_THOUGHTS  = createField('agentThoughts',   FieldTypes.INPUT);

export const PROPERTY_STATUS = createField('propertyStatus', FieldTypes.SELECT);
export const PROPERTY_STAGE = createField('propertyStage', FieldTypes.SELECT);
export const PROPERTY_ARCHIVED	= createField('propertyArchived',	FieldTypes.CHECKBOX);

// All below is unnecessary and should be refactored.
// PLEASE DO NOT ADD NEW FIELDS HERE
// CREATE FIELDS LOCALLY!
export const addUser = [FIRSTNAME, LASTNAME, POSTCODE, EMAIL, TELEPHONE];
export const myProfile = [FIRSTNAME, LASTNAME, EMAIL, TELEPHONE, SEXTANT_EMAIL, JOB, ADDRESS, POSTCODE, BIRTH_DATE, BIRTH_PLACE, NATIONALITY, AGENT_LEVEL, START_DATE, VERIFICATION_DATE, AVATAR, BIOGRAPHY, LANDSCAPE_PHOTO, RECEIVE_NEWSLETTERS];
export const addSeller = [FIRSTNAME, LASTNAME, TELEPHONE, EMAIL, BIRTH_DATE, BIRTH_PLACE, NATIONALITY];

export const addViewingFeedback = [LOCATION, FINISHANDLAYOUT, VALUE, COMMENT];
export const survey = [PROPERTY_ADDRESS, PROPERTY_PRICE, PASSPORT_OR_CARD_ID, CERTIFIED_PASSPORT_COPY, PHOTO_VERIFIED, SIGNATURE_VERIFIED, PROOF_OF_RESIDENCY, LIVE_IN_FRANCE, VERIFIED_BY_GAFI, ADDRESS_VERIFIED, JOB_TITLE, KBIS, COMPANY_CHARTER_PROVIDED, CERTIFICATE_OF_REGISTRYP_ROVIDED, CERTIFICATE_OF_CUSTOMS_PROVIDED];
export const addPropety = [PROPERTY_TYPE, POSTCODE, PROPERTY_ADDRESS, ESTIMATED_PRICE, SCI];
export const addPropetyBySeller = [PROPERTY_TYPE, POSTCODE, PROPERTY_ADDRESS, DESCRIPTION, SCI];
export const editProperty = [PROPERTY_ADDRESS, PROPERTY_PRICE, POSTCODE, PROPERTY_TYPE, PROPERTY_STATUS, SCI];
export const myDocuments1 = [IDCARD, BILL, RSAC];
// export const myDocuments2 = [RSI, RCP, VAT, ZOHO_SUBSCRIPTIONS, CAR_INSURANCE, ZOHO_CCI, URSSAF];
export const myDocuments2 = [RCP, RSI, URSSAF, CAR_INSURANCE, VAT];
export const loiAlurs = [LOI_ALUR1821,LOI_ALUR2124,LOI_ALUR2427,LOI_ALUR2730,LOI_ALUR3033,LOI_ALUR3336];
// export const myDocuments2 = [RSI, RCP, VAT, ZOHO_SUBSCRIPTIONS, LOI_ALUR, CAR_INSURANCE, ZOHO_CCI];
export const myDocuments = [].concat(myDocuments1, myDocuments2);
export const asArray = [].concat(myProfile, myDocuments);

export default ({
	FIRSTNAME,
	LASTNAME,
	EMAIL,
	TELEPHONE,
	BIRTH_DATE,
	BIRTH_PLACE,
	AGENT_LEVEL,
	NATIONALITY,
	POSTCODE,
	ADDRESS,
	SEXTANT_EMAIL,
	VERIFICATION_DATE,
	START_DATE,
	RECEIVE_NEWSLETTERS,
	AVATAR,
	IDCARD,
	BILL,
	RSAC,
	RSI,
	RCP,
	VAT,
	ZOHO_CCI,
	ZOHO_SUBSCRIPTIONS,
	JOB,
	// LOI_ALUR,
	LOI_ALUR1821,
	LOI_ALUR2124,
	LOI_ALUR2427,
	LOI_ALUR2730,
	LOI_ALUR3033,
	LOI_ALUR3336,
	CAR_INSURANCE,
	LANDSCAPE_PHOTO,
	BIOGRAPHY,
	PROPERTY_ADDRESS,
	PROPERTY_PRICE,
	LOAN_AMOUNT,
	PASSPORT_OR_CARD_ID,
	CERTIFIED_PASSPORT_COPY,
	PHOTO_VERIFIED,
	SIGNATURE_VERIFIED,
	PROOF_OF_RESIDENCY,
	LIVE_IN_FRANCE,
	VERIFIED_BY_GAFI,
	ADDRESS_VERIFIED,
	JOB_TITLE,
	KBIS,
	COMPANY_CHARTER_PROVIDED,
	CERTIFICATE_OF_REGISTRYP_ROVIDED,
	CERTIFICATE_OF_CUSTOMS_PROVIDED,
	asArray,
	myProfile,
	addUser,
	addSeller,
	addViewingFeedback,
	addPropety,
	addPropetyBySeller,
	myDocuments1,
	myDocuments2,
	loiAlurs,
	myDocuments,
	survey
});
