import userManager from '../../../utils/user-manager';
import {UserRoles} from '../../../domain/user/roles';

export const isCustomForSellers = key => {
	const {roles} = userManager.getUser();
	const isSeller = UserRoles.isSeller(roles);
	const customForSellers = ['properties.list'];

	return isSeller && customForSellers.includes(key);
};
