import {compose} from 'recompose';
import {withRouter} from 'react-router';
import {withTranslation as translate} from 'react-i18next';
import Sidebar from './sidebar';

const enhance = compose(
	withRouter,
	translate('navigation')
);

export default enhance(Sidebar);
