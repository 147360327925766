import request from 'superagent';
import userManager from '../user-manager';

function makeRequest(params) {
	const {
		url = '',
		requestType = 'json',
		method = 'get',
		token,
		data = {},
		responseType = 'json'
	} = params;

	if (!url) return;

	const req = request[method](url);

	if (requestType !== 'multipart/form-data') {
		req.type(requestType);
	}

	const bearerToken = token || userManager.getToken();

	if (bearerToken) {
		req.set('Authorization', `Bearer ${bearerToken}`);
	}

	req.responseType(responseType);

	if (requestType === 'multipart/form-data') {
		const formData = new FormData();

		Object.keys(data).forEach(key => formData.append(key, data[key]));

		req.send(formData);

		return req;
	}

	if (data) {
		if (method === 'get') {
			req.query(data);
		} else {
			req.send(data);
		}
	}

	return req;
}

export default makeRequest;
