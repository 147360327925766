import Document from './helpers/document';
import FieldType from '../../fields/field-type';

const IDENTITY_DOCUMENT = new Document('identityDocument', FieldType.DOCUMENT, true);

const KBIS_EXCERPT = new Document('kbisExcerpt', FieldType.DOCUMENT);
const STATUTES = new Document('statutes', FieldType.DOCUMENT);
const PROPERTY_TITLE = new Document('propertyTitle', FieldType.DOCUMENT);
const DIAGNOSTIC_TECHNIQUES = new Document('diagnosticTechniques', FieldType.DOCUMENT);
const PROPERTY_TAX = new Document('propertyTax', FieldType.DOCUMENT);
const SHAREHOLDERS_MEETING_MINUTES = new Document('shareholdersMeetingMinutes', FieldType.DOCUMENT);
const STATEMENT_OF_CHARGES = new Document('statementOfCharges', FieldType.DOCUMENT);
const RENT_INVOICES = new Document('rentInvoices', FieldType.DOCUMENT);
const PLAN = new Document('floorPlan', FieldType.DOCUMENT);

const SURFACE_CARREZ = new Document('surfaceLoiCarrez', FieldType.DOCUMENT);
const COMMERCIAL_LEASE = new Document('commercialLease', FieldType.DOCUMENT);
const CIVIL_STATUS = new Document('ficheRenseignementsEtatCivil', FieldType.DOCUMENT);
const OTHERS = new Document('others', FieldType.DOCUMENT);
const APPENDICE_RENTAL_CONTRACT = new Document('appendiceToRentalContract', FieldType.DOCUMENT);
const QUESTIONNAIRE = new Document('questionnaire', FieldType.DOCUMENT, true);

export default ({
	IDENTITY_DOCUMENT,

	KBIS_EXCERPT,
	STATUTES,
	PROPERTY_TITLE,
	DIAGNOSTIC_TECHNIQUES,
	PROPERTY_TAX,
	SHAREHOLDERS_MEETING_MINUTES,
	STATEMENT_OF_CHARGES,
	RENT_INVOICES,
	PLAN,

	SURFACE_CARREZ,
	COMMERCIAL_LEASE,
	CIVIL_STATUS,
	OTHERS,
	APPENDICE_RENTAL_CONTRACT,
	QUESTIONNAIRE
});
