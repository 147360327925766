import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './Client/app';

ReactDOM.render(
	React.createElement(App),
	document.getElementById('root')
);
