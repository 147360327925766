import PropTypes from 'prop-types';

const UNVERIFIED_AGENT = 'unverifiedAgent';
const LMNP_AGENT = 'lMNPAgent';
const AGENT = 'agent';
const ADMIN = 'admin';
const SELLER = 'seller';
const UNVERIFIED_SELLER = 'unverifiedSeller';
const SUPER_USER = 'superuser';
const KEROS_ADMIN = 'kerosAdmin';
const ARCHIVED = 'archived';
const asArray = [UNVERIFIED_AGENT, AGENT, LMNP_AGENT, ADMIN, KEROS_ADMIN, SUPER_USER, ARCHIVED, SELLER, UNVERIFIED_SELLER];

function isRoleRelatedToSeller(role) {
	return role === SELLER || role === UNVERIFIED_SELLER;
}

function isRoleRelatedToAgent(role) {
	return role === AGENT || role === UNVERIFIED_AGENT || role === LMNP_AGENT;
}

function isRoleRelatedToAdmin(role) {
	return role === ADMIN || role === SUPER_USER || role === KEROS_ADMIN;
}

function isAdmin(roles) {
	return !!roles.find(isRoleRelatedToAdmin);
}

function isSuperuserAdmin(roles) {
	return !!roles.find(x => x === SUPER_USER);
}

function isAgent(roles) {
	return !!roles.find(isRoleRelatedToAgent);
}

function isLmnpAgent(roles) {
	return !!roles.find(role => role === LMNP_AGENT);
}

function isSeller(roles) {
	return !!roles.find(isRoleRelatedToSeller);
}

export default ({
	UNVERIFIED_AGENT,
	LMNP_AGENT,
	AGENT,
	SELLER,
	UNVERIFIED_SELLER,
	ADMIN,
	KEROS_ADMIN,
	SUPER_USER,
	ARCHIVED,
	asArray,
	isAdmin,
	isSuperuserAdmin,
	isAgent,
	isSeller,
	isRoleRelatedToAgent,
	isRoleRelatedToAdmin,
	isRoleRelatedToSeller,
	isLmnpAgent,
	names: { // WTF?
		[UNVERIFIED_AGENT]: 'Unverified Agent',
		[AGENT]: 'Agent',
		[SELLER]: 'Seller',
		[UNVERIFIED_SELLER]: 'Unverified Seller',
		[ADMIN]: 'Admin',
		[KEROS_ADMIN]: 'Keros admin',
		[SUPER_USER]: 'Super User',
		[ARCHIVED]: 'Archived'
	},
	propType: PropTypes.oneOf(asArray)
});
