import * as moment from 'moment';
import {HttpMethods} from '../../domain/http';
import {PropertiesUrlBuilder} from '../../api';

export const GET_PROPERTIES_LIST = 'GET_PROPERTIES_LIST';
export const RESET_PROPERTIES_LIST = 'RESET_PROPERTIES_LIST';

export const GET_PROPERTY = 'GET_PROPERTY';
export const RESET_PROPERTY = 'RESET_PROPERTY';

export const EDIT_PROPERTY = 'EDIT_PROPERTY';
export const EDIT_PROPERTY_STAGE = 'EDIT_PROPERTY_STAGE';
export const EDIT_PROPERTY_STATUS = 'EDIT_PROPERTY_STATUS';

export const GET_PROPERTY_DOCUMENTS_LIST = 'GET_PROPERTY_DOCUMENTS_LIST';
export const RESET_PROPERTY_DOCUMENTS_LIST = 'RESET_PROPERTY_DOCUMENTS_LIST';

export const GET_PROPERTY_STATUS = 'GET_PROPERTY_STATUS';

export const GET_PROPERTY_VIEWINGS_LIST = 'GET_PROPERTY_VIEWINGS_LIST';
export const RESET_PROPERTY_VIEWINGS_LIST = 'RESET_PROPERTY_VIEWINGS_LIST';
export const FILL_VIEWING_FEEDBACK = 'FILL_VIEWING_FEEDBACK';
export const CREATE_VIEWING = 'CREATE_VIEWING';
export const CANCEL_VIEWING = 'CANCEL_VIEWING';
export const EDIT_VIEWING_SCHEDULE = 'EDIT_VIEWING_SCHEDULE';

export const GET_PROPERTY_SELLERS = 'GET_PROPERTY_SELLERS';
export const RESET_PROPERTY_SELLERS = 'RESET_PROPERTY_SELLERS';

export const GET_PROPERTY_COMMENTS = 'GET_PROPERTY_COMMENTS';
export const RESET_PROPERTY_COMMENTS = 'RESET_PROPERTY_COMMENTS';

export const ADD_COMMENT_TO_PROPERTY = 'ADD_COMMENT_TO_PROPERTY';
export const DELETE_COMMENT_OF_PROPERTY = 'DELETE_COMMENT_OF_PROPERTY';

export const ADD_PROPERTY_BY_AGENT = 'ADD_PROPERTY_BY_AGENT';

export const ADD_CO_SELLER = 'ADD_CO_SELLER';
export const MAKE_SELLER_DEFAULT = 'MAKE_SELLER_DEFAULT';
export const REMOVE_SELLER_FROM_PROPERTY = 'REMOVE_SELLER';

export const GET_PROPERTY_OFFERS_LIST = 'GET_PROPERTY_OFFERS_LIST';
export const RESET_PROPERTY_OFFERS_LIST = 'RESET_PROPERTY_OFFERS_LIST';
export const GET_PROPERTY_OFFERS_DETAILS = 'GET_PROPERTY_OFFERS_DETAILS';
export const RESET_PROPERTY_OFFERS_DETAILS = 'RESET_PROPERTY_OFFERS_DETAILS';
export const CHANGE_STATUS_PROPERTY_OFFER = 'CHANGE_STATUS_PROPERTY_OFFER';

export const GET_PROPERTY_CONTACTS = 'GET_PROPERTY_CONTACTS';
export const RESET_PROPERTY_CONTACTS = 'RESET_PROPERTY_CONTACTS';

export const GET_SURVEY = 'GET_SURVEY';
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const SIGN_SURVEY = 'SIGN_SURVEY';
export const RESET_SURVEY = 'RESET_SURVEY';

export const GET_MANDAT = 'GET_MANDAT';
export const SIGN_MANDAT = 'SIGN_MANDAT';
export const RESET_MANDAT = 'RESET_MANDAT';
export const CANCEL_MANDAT = 'DELETE_MANDAT';

export const GET_REDUCE_PRICE = 'GET_REDUCE_PRICE';
export const SIGN_REDUCE_PRICE = 'SIGN_REDUCE_PRICE';
export const RESET_REDUCE_PRICE = 'RESET_REDUCE_PRICE';

export const CREATE_PROPERTY_CONTACT = 'CREATE_PROPERTY_CONTACT';
export const UPDATE_PROPERTY_CONTACT = 'UPDATE_PROPERTY_CONTACT';

export const GET_PROPERTY_DETAILS = 'GET_PROPERTY_DETAILS';
export const RESET_PROPERTY_DETAILS = 'RESET_PROPERTY_DETAILS';

export const GET_VALUATION_APPOINTMENT = 'GET_VALUATION_APPOINTMENT';
export const RESET_VALUATION_APPOINTMENT = 'RESET_VALUATION_APPOINTMENT';
export const EDIT_STATUS_VALUATION_APPOINTMENT = 'EDIT_STATUS_VALUATION_APPOINTMENT';
export const EDIT_VALUATION_APPOINTMENT = 'EDIT_VALUATION_APPOINTMENT';

export const GET_HISTORY = 'GET_HISTORY';

export const GET_PORTALS = 'GET_PORTALS';
export const UPDATE_PORTALS = 'UPDATE_PORTALS';
export const RESET_PORTALS = 'RESET_PORTALS';

export const GET_APPOINTMENT_STATUS = 'GET_APPOINTMENT_STATUS';
export const UPDATE_APPOINTMENT_STATUS = 'UPDATE_APPOINTMENT_STATUS';
export const RESET_APPOINTMENT_STATUS = 'RESET_APPOINTMENT_STATUS';

export const DOWNLOAD_OLD_TRACFIN = 'DOWNLOAD_OLD_TRACFIN';
export const DOWNLOAD_OLD_MANDATE = 'DOWNLOAD_OLD_MANDATE';
export const DOWNLOAD_OLD_REDUCE = 'DOWNLOAD_OLD_REDUCE';
export const DOWNLOAD_DOCUMENT_S3 = 'DOWNLOAD_DOCUMENT_S3';

export const GET_QUESTIONNAIRE = 'GET_GUESTIONNAIRE';

export const GET_LMNP_AREA = 'GET_LMNP_AREA';
export const RESET_LMNP_AREA = 'RESET_LMNP_AREA';

export const SEND_MANDAT_REMINDER = 'SEND_MANDAT_REMINDER';

export const GET_ACTIVITY = 'GET_ACTIVITY';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';

export const SEND_EMAIL_ACTIVITY = 'SEND_EMAIL_ACTIVITY';

export const getPropertiesList = params => ({
	type: GET_PROPERTIES_LIST,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listProperties(),
			data: params
		}
	}
});

export const resetPropertiesList = () => ({
	type: RESET_PROPERTIES_LIST
});

export const getProperty = id => ({
	id,
	type: GET_PROPERTY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.readProperty(id)
		}
	}
});

export const resetProperty = id => ({
	type: RESET_PROPERTY,
	id
});

export const editProperty = (id, data) => ({
	type: EDIT_PROPERTY,
	id,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.readProperty(id),
			data
		}
	}
});

export const editPropertyStage = (id, data) => ({
	type: EDIT_PROPERTY_STAGE,
	id,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.editPropertyStage(id),
			data
		}
	}
});
export const editPropertyStatus = (id, data) => ({
	type: EDIT_PROPERTY_STATUS,
	id,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.editPropertyStatus(id),
			data
		}
	}
});

export const createSurvey = data => ({
	type: CREATE_SURVEY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.createSurvey(),
			data
		}
	}
});

export const getMandat = propertyId => ({
	id: propertyId,
	type: GET_MANDAT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getMandat(propertyId)
		}
	}
});

export const signMandat = (propertyId, data) => ({
	id: propertyId,
	type: SIGN_MANDAT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.signMandat(propertyId),
			data
		}
	}
});

export const resetMandat = id => ({
	type: RESET_MANDAT,
	id
});

export const cancelMandat = propertyId => ({
	id: propertyId,
	type: CANCEL_MANDAT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.cancelMandat(propertyId)
		}
	}
})

export const getReducePrice = propertyId => ({
	id: propertyId,
	type: GET_REDUCE_PRICE,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getReducePrice(propertyId)
		}
	}
});

export const signReducePrice = propertyId => ({
	id: propertyId,
	type: SIGN_REDUCE_PRICE,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.signReducePrice(propertyId)
		}
	}
});

export const resetReducePrice = id => ({
	type: RESET_REDUCE_PRICE,
	id
});

export const getSurvey = propertyId => ({
	id: propertyId,
	type: GET_SURVEY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getSurvey(propertyId)
		}
	}
});

export const signSurvey = (surveyId, data) => ({
	id: surveyId,
	type: SIGN_SURVEY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.signSurvey(surveyId),
			data
		}
	}
});

export const updateSurvey = (surveyId, data) => ({
	id: surveyId,
	type: UPDATE_SURVEY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.updateSurvey(surveyId),
			data
		}
	}
});

export const resetSurvey = id => ({
	type: RESET_SURVEY,
	id
});

export const getPropertyOffersList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_OFFERS_LIST,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertyOffers(propertyId)
		}
	}
});

export const getPropertyOfferDetails = offerId => ({
	id: offerId,
	type: GET_PROPERTY_OFFERS_DETAILS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.readPropertyOffer(offerId)
		}
	}
});

export const changeStatusPropertyOffer = (offerId, status) => ({
	offerId,
	status,
	type: CHANGE_STATUS_PROPERTY_OFFER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.changeStatusPropertyOffer(offerId),
			data: {status}
		}
	}
});

export const resetPropertyOfferDetails = offerId => ({
	id: offerId,
	type: RESET_PROPERTY_OFFERS_DETAILS
});

export const resetPropertyOffersList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_OFFERS_LIST
});

export const getPropertyDocumentsList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_DOCUMENTS_LIST,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertyDocuments(propertyId)
		}
	}
});

export const resetPropertyDocumentsList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_DOCUMENTS_LIST
});

export const getPropertyViewingsList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_VIEWINGS_LIST,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertyViewings(propertyId)
		}
	}
});

export const resetPropertyViewingsList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_VIEWINGS_LIST
});

export const fillViewingFeedback = (propertyId, viewingId, data) => ({
	propertyId,
	viewingId,
	type: FILL_VIEWING_FEEDBACK,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.fillViewingFeedback(viewingId),
			data
		}
	}
});

export const createViewing = (propertyId, data) => ({
	propertyId,
	type: CREATE_VIEWING,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.createViewing(),
			data: {
				...data,
				propertyId
			}
		}
	}
});

export const cancelViewing = (propertyId, viewingId, data) => ({
	propertyId,
	viewingId,
	type: CANCEL_VIEWING,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.cancelViewing(viewingId),
			data
		}
	}
});

export const editViewingSchedule = (propertyId, viewingId, data) => ({
	propertyId,
	viewingId,
	type: EDIT_VIEWING_SCHEDULE,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.editViewingSchedule(viewingId),
			data
		}
	}
});

export const getPropertySellersList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_SELLERS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertySellers(propertyId)
		}
	}
});

export const resetPropertySellersList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_SELLERS
});

export const getPropertyCommentsList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_COMMENTS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertyComments(propertyId)
		},
		postprocess: action => {
			const {
				http: {
					response: {body}
				}
			} = action;

			action.data = body.map(({id, author, text, createdAt}) => ({
				id,
				reporter: author,
				text,
				createdAt
			}));

			return action;
		}
	}
});

export const resetPropertyCommentsList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_COMMENTS
});

export const addComment = (propertyId, text) => ({
	propertyId,
	type: ADD_COMMENT_TO_PROPERTY,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.addCommentTo(propertyId),
			data: {text}
		},
		postprocess: action => {
			const {
				http: {
					response: {body}
				}
			} = action;

			action.data = {
				...body,
				reporter: body.author
			};

			return action;
		}
	}
});

export const deleteComment = (propertyId, commentId) => ({
	propertyId,
	commentId,
	type: DELETE_COMMENT_OF_PROPERTY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.DELETE,
			url: PropertiesUrlBuilder.deleteComment(commentId)
		}
	}
});

export const addPropertyByAgent = (sellerId, property) => ({
	type: ADD_PROPERTY_BY_AGENT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.addPropertyByAgent(sellerId),
			data: property
		}
	}
});

export const getPropertyStatus = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_STATUS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			// url: PropertiesUrlBuilder.readPropertyStatus(propertyId)
			url: PropertiesUrlBuilder.readProperty(propertyId)
		},
		postprocess: action => {
			// const {
			// 	http: {response: {body: data}}
			// } = action;

			// mock data
			const data = {
				valuation: {
					status: 'valuation',
					data: {
						appointmentDate: '2019-07-23T10:31:32.237436'
					}
				},
				documents: {
					status: 'documents',
					data: {
						documents: [
							{
								key: 'identityDocument',
								seller: {id: 2, name: 'Another Seller'},
								status: 'valid'
							},
							{
								key: 'kbisExcerpt',
								seller: {id: 2, name: 'Another Seller'},
								status: 'rejected'
							},
							{
								key: 'condominiumRegulation',
								status: 'pending'
							},
							{
								key: 'fundingAppeal',
								status: ''
							},
							{
								key: 'rentInvoices',
								status: ''
							},
							{
								key: 'administrativeAuthorization',
								status: ''
							},
							{
								key: 'authorizationOrLicense',
								status: ''
							}
						]
					}
				},
				mandat: {
					status: 'mandat',
					data: {mandateNumber: 1}
				},
				sale: {
					status: 'sale',
					data: {
						offers: [
							{
								status: 'pending',
								count: 2
							},
							{
								status: 'rejected',
								count: 3
							},
							{
								status: 'accepted',
								count: 0
							}
						],
						viewings: [
							{
								status: 'sheduled',
								count: 2
							},
							{
								status: 'passed',
								count: 1
							}
						]
					}
				},
				sold: {
					status: 'sold',
					data: {}
				}
			};

			action.data = data.sale;
			// end mock data

			// action.data = data;
			return action;
		}
	}
});

export const addCoSeller = (propertyId, newSeller) => ({
	propertyId,
	type: ADD_CO_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.addCoSeller(),
			data: newSeller
		}
	}
});

export const addCoSellerById = (propertyId, sellerId) => ({
	propertyId,
	type: ADD_CO_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.addCoSellerById(propertyId),
			data: {
				sellerId
			}
		}
	}
});

export const makeSellerDefault = (propertyId, sellerId) => ({
	propertyId,
	sellerId,
	type: MAKE_SELLER_DEFAULT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.makeSellerDefault(propertyId),
			data: {
				sellerId
			}
		}
	}
});

export const removeSellerFromProperty = (propertyId, sellerId) => ({
	propertyId,
	sellerId,
	type: REMOVE_SELLER_FROM_PROPERTY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.DELETE,
			url: PropertiesUrlBuilder.removeSellerFromProperty(propertyId, sellerId)
		}
	}
});

export const getPropertyContactsList = propertyId => ({
	id: propertyId,
	type: GET_PROPERTY_CONTACTS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.listPropertyContacts(propertyId)
		}
	}
});

export const resetPropertyContactsList = propertyId => ({
	id: propertyId,
	type: RESET_PROPERTY_CONTACTS
});

export const createPropertyContact = (propertyId, data) => ({
	id: propertyId,
	type: CREATE_PROPERTY_CONTACT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.createPropertyContact(propertyId),
			data
		}
	}
});

export const updatePropertyContact = (propertyId, contactId, data) => ({
	id: propertyId,
	type: UPDATE_PROPERTY_CONTACT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.updatePropertyContact(propertyId, contactId),
			data
		}
	}
});

export const getPropertyDetails = id => ({
	id,
	type: GET_PROPERTY_DETAILS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.readPropertyDetails(id)
		}
	}
});

export const resetPropertyDetails = id => ({
	type: RESET_PROPERTY_DETAILS,
	id
});

export const getValuationAppointment = id => ({
	id,
	type: GET_VALUATION_APPOINTMENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.readValuationAppointment(id)
		},
		postprocess: action => {
			const {
				http: {response: {body: data}}
			} = action;

			action.data = {
				...data,
				startDate: moment.utc(data.startDate)
			};

			return action;
		}
	}
});

export const editValuationAppointment = (id, data) => ({
	id,
	type: EDIT_VALUATION_APPOINTMENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.PUT,
			url: PropertiesUrlBuilder.editValuationAppointment(id),
			data
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {
				...body,
				startDate: moment.utc(body.startDate)
			};

			return action;
		}
	}
});

export const editStatusValuationAppointment = (id, data) => ({
	id,
	type: EDIT_STATUS_VALUATION_APPOINTMENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.editStatusValuationAppointment(id),
			data
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {
				...body,
				startDate: moment.utc(body.startDate)
			};

			return action;
		}
	}
});

export const resetValuationAppointment = id => ({
	id,
	type: RESET_VALUATION_APPOINTMENT
});

export const getHistory = id => ({
	id,
	type: GET_HISTORY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getHistory(id)
		}
	}
});

export const getPortals = id => ({
	id,
	type: GET_PORTALS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getPortals(id)
		}
	}
});

export const updatePortals = (id, data) => ({
	id,
	type: UPDATE_PORTALS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.updatePortals(id),
			data
		}
	}
});

export const resetPortals = id => ({
	id,
	type: RESET_PORTALS
});

export const getAppointmentStatus = propertyId => ({
	id: propertyId,
	type: GET_APPOINTMENT_STATUS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getAppointmentStatus(propertyId)
		}
	}
});

export const updateAppointmentStatus = (propertyId, data) => ({
	id: propertyId,
	type: UPDATE_APPOINTMENT_STATUS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.updateAppointmentStatus(propertyId),
			data
		}
	}
});

export const resetAppointmentStatus = propertyId => ({
	id: propertyId,
	type: RESET_APPOINTMENT_STATUS
});

export const downloadOldTracfin = (propertyId, guid) => ({
	id: propertyId,
	type: DOWNLOAD_OLD_TRACFIN,
	download: {
		link: PropertiesUrlBuilder.downloadTracfinFromHistory(propertyId, guid),
		withToken: true,
		filename: 'tracfin'
	}
});

export const downloadOldMandate = (propertyId, guid) => ({
	id: propertyId,
	type: DOWNLOAD_OLD_MANDATE,
	download: {
		link: PropertiesUrlBuilder.downloadMandateFromHistory(propertyId, guid),
		withToken: true,
		filename: 'mandate'
	}
});

export const downloadOldReducePrice = (propertyId, guid) => ({
	id: propertyId,
	type: DOWNLOAD_OLD_REDUCE,
	download: {
		link: PropertiesUrlBuilder.downloadReducePriceFromHistory(propertyId, guid),
		withToken: true,
		filename: 'reduce'
	}
});

export const downloadDocumentFromS3 = (id, guid) => ({
	id,
	type: DOWNLOAD_DOCUMENT_S3,
	download: {
		link: PropertiesUrlBuilder.downloadDocumentFromS3(id, guid),
		withToken: true,
		filename: guid
	}
});

export const downloadQuestionnaire = lang => ({
	type: GET_QUESTIONNAIRE,
	download: {
		link: PropertiesUrlBuilder.getQuestionnaire(lang),
		withToken: true
	}
});

export const getLmnpArea = params => ({
	type: GET_LMNP_AREA,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getLmnpArea(),
			data: params
		}
	}
});

export const resetLmnpArea = () => ({
	type: RESET_LMNP_AREA
});

export const sendMandatReminder = propertyId => ({
	type: SEND_MANDAT_REMINDER,
	http: {
		withToken: true,
		postprocess: true,
		defaultErrorsDisabled: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.sendMandatReminder(propertyId)
		}
	}
});

export const getPropertyActivity = id => ({
	id,
	type: GET_ACTIVITY,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: PropertiesUrlBuilder.getActivity(id)
		}
	}
});

export const resetPropertyActivity = id => ({
	id,
	type: RESET_ACTIVITY
});

export const sendEmailActivity = (propertyId, data) => ({
	type: SEND_EMAIL_ACTIVITY,
	http: {
		withToken: true,
		postprocess: true,
		defaultErrorsDisabled: true,
		request: {
			method: HttpMethods.POST,
			url: PropertiesUrlBuilder.sendEmailActivity(propertyId),
			data
		}
	}
});
