import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import i18next from 'i18next';
import {isCustomForSellers} from '../custom-keys-helper';

export const ItemWithNested = React.memo(({t, classes, location, item, renderListItem}) => {

    const lang = i18next.language;

	const {
		key, Icon, disabled, hidden, divider, children
	} = item;

	if (hidden) {
		return null;
	}

	const icon = Icon && (
		<ListItemIcon classes={{root: classes.iconRoot}}>
			<Icon classes={{root: `${classes.icon} ${classes.nestedIcon}`}} />
		</ListItemIcon>
	);

	const links = React.useMemo(() => children.map(child => child.link), [children]);
	const primaryItemText = isCustomForSellers(key) ? t(`sidebar.custom-for-sellers.${key}`) : t(`sidebar.${key}`);

	const [isOpen, setIsOpen] = React.useState(false);

	const toggleList = React.useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen])

	React.useEffect(() => {
		if (links.includes(location.pathname)) {
			setIsOpen(true)
		}
	}, [])

	return (
		<div key={key} className={classes.itemContainer}>
			<ListItem
				button
				classes={{root: classes.item, selected: classes.selected}}
				onClick={toggleList}
				disabled={disabled}
			>

				{icon}
				<ListItemText
					primary={primaryItemText}
					classes={{root: classes.itemTextRoot, primary: classes.itemText}}
				/>
				<IconButton className={classes.toggleList}>
					{isOpen ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			</ListItem>
			<Collapse in={isOpen} className={classes.subContainer}>
				<List component="div">
					{React.useMemo(() => children.map(child => renderListItem(child)), [children, lang])}
				</List>
			</Collapse>
		</div>
	)
});