import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

const DEFAULT_SIZE = 40;
const LoadingIndicator = ({
	classes, scale, className, pageCenter, ...rest
}) => {
	const size = DEFAULT_SIZE * scale;

	return (
		<div className={pageCenter ? classes.pageCenter : classes.root}>
			<CircularProgress
				size={size}
				className={`${classes.indicator} ${className}`}
				{...rest}
			/>
		</div>
	);
};

LoadingIndicator.defaultProps = {
	scale: 1,
	pageCenter: false
};

LoadingIndicator.propTypes = {
	classes: PropTypes.object.isRequired,
	scale: PropTypes.number,
	className: PropTypes.string,
	pageCenter: PropTypes.bool
};

export const StyledLoadingIndicator = withStyles(styles)(LoadingIndicator);

export const scaledLoadingIndicator = (scale, pageCenter) => () => (
	<StyledLoadingIndicator scale={scale} pageCenter={pageCenter}/>
);
