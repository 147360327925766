import * as Types from '../actions/viewings';
import setIn from './helpers/set-in';

const initialState = {
	list: {
		content: [],
		orderBy: 'startDate',
		orderDirection: 'descending',
		pageNumber: 0,
		pageSize: 10
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case Types.GET_VIEWINGS:
			return setIn(state, action.data, 'list');
		default:
			return state;
	}
};
