import {HttpMethods} from '../../domain/http';
import {CalendarUrlBuilder} from '../../api';

export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const RESET_CALENDAR_EVENTS = 'RESET_CALENDAR_EVENTS';

export const CREATE_EVENT = 'CREATE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const SET_WORK_HOURS = 'SET_WORK_HOURS';
export const GET_WORK_HOURS = 'GET_WORK_HOURS';
export const RESET_WORK_HOURS = 'RESET_WORK_HOURS';

export const DOWNLOAD_CALENDAR = 'DOWNLOAD_CALENDAR';

export const getCalendarEvents = (id, timeInterval) => ({
	type: GET_CALENDAR_EVENTS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: CalendarUrlBuilder.getCalendarEvents(id, timeInterval)
		}
	}
});

export const resetCalendarEvents = () => ({
	type: RESET_CALENDAR_EVENTS
});

export const createEvent = data => ({
	type: CREATE_EVENT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: CalendarUrlBuilder.createEvent(),
			data
		}
	}
});

export const editEvent = (id, data) => ({
	type: EDIT_EVENT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: CalendarUrlBuilder.editEvent(id),
			data
		}
	}
});

export const deleteEvent = id => ({
	type: DELETE_EVENT,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.DELETE,
			url: CalendarUrlBuilder.deleteEvent(id)
		}
	}
});

export const setWorkHours = (userId, data) => ({
	type: SET_WORK_HOURS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: CalendarUrlBuilder.workHours(userId),
			data
		}
	}
})

export const getWorkHours = userId => ({
	type: GET_WORK_HOURS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: CalendarUrlBuilder.workHours(userId)
		}
	}
})

export const resetWorkHours = () => ({
	type: RESET_WORK_HOURS,
});

export const downloadCalendar = (id, token) => ({
	id,
	type: DOWNLOAD_CALENDAR,
	download: {
		link: CalendarUrlBuilder.downloadCalendar(id, token),
		contentType: 'text/calendar',
		withToken: true,
		filename: 'calendar'
	}
});

