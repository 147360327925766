import {PortalTypes} from '../../domain/property';

export default ({
  "activity": {
    "title": {
      "simple": "Mandat Simple Activité - Béta Version",
      "satisfaction": "Mandat Satisfaction Activité - Béta Version",
      "exclusive": "Mandat Exclusif Activité - Béta Version",
      "null": "Activité - Béta Version"
    },
    "title-premium": "Mandat Exclusif Activité - Béta Version",
    "views-average": "moyenne vues journalières\npour cette propriété",
    "displays-on-details": "visible sur\ndescription",
    "views-department": "moyenne vues journalières\ndans le departement",
    "email-contacts": "contacts emails",
    "phone-contacts": "contacts téléphoniques",
    "attractivity-rate": "taux d'attractivité",
    "non-premium-info": "Ce contenu est disponible pour les mandats exclusifs",
    "contact-button": "Contacter mon conseiller pour plus d'infos",
    "loading": "Chargement en cours...",
    "no-data-yet": "Données non disponibles pour l’instant",
    "email-to-agent": {
      "title": "E-mail à",
      "sent": "L'email a été envoyé"
    }
  },
  "graph": {
    "vertical-text": "Vues de votre propriété en ligne",
    "legend": {
      "online": "Vues en ligne",
      "price-reduction": "Réduction du prix",
      "viewings": "Visites physiques",
      "offers": "Offres d'achat"
    },
    "from": "de",
    "to": "à",
    "tooltip": {
      "online": "Vues en ligne",
      "offer": "offre",
      "offers": "offres",
      "viewing": "visite",
      "viewings": "visites",
      "price-reduced": "Réduction du prix"
    }
  },
  "filters": {
    "hint": "Selection des portails pour analyse",
    "hint-seller": "Analyse concernant les portails sélectionnés",
    "to-be-continued": "A suivre..."
  },
  "websites": {
    "title": "Portails immobiliers",
    "summary": "Votre propriété sera affichée sur les portails suivants",
    "no-platforms": "Vous n'avez pas encore choisi de portails",
    "edit-tooltip": "Sélection des portails"
  },
  "modal": {
    "title1": "Sélection des portails",
    "subtitle1": "Liste des portails qui publient la propriété",
    "title2": "Publier la propriété sur ces portails",
    "subtitle2": "Activer la publication du bien sur ces portails\n(L'utilisateur aura un accès direct à la localisation de la propritété sur le site Internet)",
    "view-other": "Voir tous les autres portails"
  },
  "portals": {
    [PortalTypes.FRENCH]: {
      "title": "Portails français"
    },
    [PortalTypes.INTERNATIONAL]: {
      "title": "Portails internationaux"
    },
    [PortalTypes.LUXURY]: {
      "title": "Portails de luxe"
    },
    [PortalTypes.TRADE]: {
      "title": "Portails commerces et enterprises"
    },
    [PortalTypes.OTHER]: {
      "title": "Autres portails immobiliers"
    }
  }
});
