import React, {Component} from 'react';
import PropTypes from 'prop-types';

class WithAuth extends Component {
	render() {
		const {
			areaAuth: AutorizedRoutes,
			areaNonAuth: NonAuthorizedRoutes,
			user,
			isAuthorized,
			skipLanding,
			location
		} = this.props;

		return isAuthorized
			? <AutorizedRoutes user={user} location={location} />
			: <NonAuthorizedRoutes skipLanding={skipLanding} location={location} />;
	}
}

WithAuth.propTypes = {
	user: PropTypes.object,
	location: PropTypes.object.isRequired,
	areaAuth: PropTypes.func.isRequired,
	areaNonAuth: PropTypes.func.isRequired,
	isAuthorized: PropTypes.bool.isRequired,
	skipLanding: PropTypes.bool.isRequired
};

export default WithAuth;
