import {OPEN_SALE_POPUP, CLOSE_SALE_POPUP, TOGGLE_SALE_POPUP} from '../middlewares/addSaleToKeros';

const initState = {
	isAddSalePopupOpen: false
};

const kerosReducer = (state = initState, action) => {
	const {isAddSalePopupOpen} = state;

	switch (action.type) {
		case OPEN_SALE_POPUP:
			return {isAddSalePopupOpen: true};
		case CLOSE_SALE_POPUP:
			return {isAddSalePopupOpen: false};
		case TOGGLE_SALE_POPUP:
			return {isAddSalePopupOpen: !isAddSalePopupOpen};
		default:
			return state;
	}
};

export default kerosReducer;
