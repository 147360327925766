import {SHOW_NOTIFICATION, RESET_NOTIFICATION} from '../actions/notifications';

const initialState = {
	message: '',
	settings: {},
	oldError: false,
	actionsList: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_NOTIFICATION:
			return Object.assign({}, state, {
				...action
			});
		case RESET_NOTIFICATION:
			return Object.assign({}, state, initialState);
		default:
			return state;
	}
}
