const listProperties = () => '/property';
const readProperty = propertyId => `/property/${propertyId}`;
const editPropertyStage = propertyId => `/property/${propertyId}/stage`;
const editPropertyStatus = propertyId => `/property/${propertyId}/status`;
const getMandat = propertyId => `/property/${propertyId}/mandate`;
const signMandat = propertyId => `/property/${propertyId}/mandate/sign`;
const cancelMandat = propertyId => `/property/${propertyId}/mandate/cancel`;
const getReducePrice = propertyId => `/property/${propertyId}/documents/reduce-price`;
const signReducePrice = propertyId => `/property/${propertyId}/documents/reduce-price/sign`;
const getSurvey = propertyId => `/survey?propertyId=${propertyId}`;
const createSurvey = () => '/survey';
const signSurvey = surveyId => `/survey/sign/${surveyId}`;
const updateSurvey = surveyId => `/survey/${surveyId}`;
const listPropertyDocuments = propertyId => `/property/${propertyId}/documents`;
const readPropertyStatus = propertyId => `/property/${propertyId}/status`;
const listPropertyViewings = propertyId => `/viewing/property/${propertyId}`;
const fillViewingFeedback = viewingId => `/viewing/feedback/${viewingId}`;
const createViewing = () => '/viewing';
const cancelViewing = viewingId => `/viewing/cancel/${viewingId}`;
const editViewingSchedule = viewingId => `/viewing/schedule/${viewingId}`;
const listPropertySellers = propertyId => `/property/${propertyId}/sellers`;
const listPropertyComments = propertyId => `/property/${propertyId}/comments`;
const addCommentTo = propertyId => `/property/${propertyId}/comments`;
const deleteComment = commentId => `/property/comments/${commentId}`;
const addPropertyByAgent = sellerId => `/seller/${sellerId}/properties`;
const addCoSeller = () => '/seller';
const makeSellerDefault = propertyId => `/property/${propertyId}/main-seller`;
const removeSellerFromProperty = (propertyId, sellerId) => `/property/${propertyId}/sellers/delete/${sellerId}`;
const addCoSellerById = listPropertySellers;
const listPropertyOffers = propertyId => `/property/${propertyId}/offers`;
const readPropertyOffer = offerId => `/propertyoffer/${offerId}`;
const changePropertyOffer = offerId => `/propertyoffer/${offerId}`;
const changeStatusPropertyOffer = offerId => `/propertyoffer/${offerId}/change-status`;
const listPropertyContacts = propertyId => `/property/${propertyId}/contacts`;
const createPropertyContact = propertyId => `/property/${propertyId}/contacts`;
const updatePropertyContact = (propertyId, contactId) => `/property/${propertyId}/contacts/${contactId}`;
const readPropertyDetails = propertyId => `/property/${propertyId}/details`;
const readValuationAppointment = propertyId => `/property/${propertyId}/valuation`;
const editValuationAppointment = readValuationAppointment;
const editStatusValuationAppointment = propertyId => `/property/${propertyId}/valuation/status`;
const getAppointmentStatus = propertyId => `/property/${propertyId}/appointment-confirmation`;
const updateAppointmentStatus = propertyId => `/property/${propertyId}/appointment-confirmation`;
const getHistory = propertyId => `/property/${propertyId}/history`;
const getPortals = propertyId => `/property/${propertyId}/portals`;
const updatePortals = propertyId => `/property/${propertyId}/portals`;
const downloadTracfinFromHistory = (propertyId, fileGuid) => `/property/${propertyId}/survey/history/${encodeURIComponent(fileGuid)}`;
const downloadMandateFromHistory = (propertyId, fileGuid) => `/property/${propertyId}/mandate/history/${encodeURIComponent(fileGuid)}`;
const downloadReducePriceFromHistory = (propertyId, fileGuid) => `/property/${propertyId}/documents/reduce-price/history/${encodeURIComponent(fileGuid)}`;
const downloadDocumentFromS3 = (id, fileGuid) => `/property/${id}/documents/history/${fileGuid}`;
const getQuestionnaire = lang => `/property-document/templates/questionnaire/${lang}`;
const getLmnpArea = () => '/property/lmnp-area';
const sendMandatReminder = id => `/property/${id}/mandate/send-sign-reminder`;
const getActivity = propertyId => `/property/${propertyId}/activity`;
const sendEmailActivity = id => `/property/${id}/activity/contact-to-agent`;

export default {
	listProperties,
	readProperty,
	editPropertyStage,
	editPropertyStatus,
	getMandat,
	signMandat,
	cancelMandat,
	getReducePrice,
	signReducePrice,
	getSurvey,
	signSurvey,
	updateSurvey,
	createSurvey,
	listPropertyDocuments,
	readPropertyStatus,
	listPropertyViewings,
	fillViewingFeedback,
	cancelViewing,
	editViewingSchedule,
	createViewing,
	listPropertySellers,
	listPropertyComments,
	addCommentTo,
	deleteComment,
	addPropertyByAgent,
	addCoSeller,
	addCoSellerById,
	makeSellerDefault,
	removeSellerFromProperty,
	listPropertyOffers,
	readPropertyOffer,
	changePropertyOffer,
	changeStatusPropertyOffer,
	listPropertyContacts,
	createPropertyContact,
	updatePropertyContact,
	readValuationAppointment,
	editValuationAppointment,
	editStatusValuationAppointment,
	getAppointmentStatus,
	updateAppointmentStatus,
	readPropertyDetails,
	getHistory,
	getPortals,
	updatePortals,
	downloadTracfinFromHistory,
	downloadMandateFromHistory,
	downloadReducePriceFromHistory,
	downloadDocumentFromS3,
	getQuestionnaire,
	getLmnpArea,
	sendMandatReminder,
	getActivity,
	sendEmailActivity
};
