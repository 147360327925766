import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
// import {routerReducer, routerMiddleware as routerMiddlewareBuilder} from 'react-router-redux';
import {connectRouter as routerReducer, routerMiddleware as routerMiddlewareBuilder} from 'connected-react-router';
import {resourceReducer} from 'redux-resource';
import {reset} from 'redux-resource-plugins';
import auth from './reducers/auth';
import notifications from './reducers/notification';
import properties from './reducers/properties';
import sellers from './reducers/sellers';
import calendar from './reducers/calendar';
import viewings from './reducers/viewings';
import keros from './reducers/keros';
import resource from './middlewares/resource';
import workHoursPopup from './middlewares/workHoursPopup';
import addSaleToKeros from './middlewares/addSaleToKeros';
import authMiddleware from './middlewares/auth';
import createActionProcessing from './middlewares/factories/action-processing-factory';
import httpMiddleware from './middlewares/http';
import logger from './middlewares/logger';
import history from './history';
import downloadFileMiddleware from './middlewares/download';

const routerMiddleware = routerMiddlewareBuilder(history);

const httpPreprocess = createActionProcessing(['http', 'preprocess']);
const httpPostprocess = createActionProcessing(['http', 'postprocess'], {
	defaultProcessing: action => {
		const {
			http: {response: {body: data}}
		} = action;

		action.data = data;
		return action;
	}
});

const reducer = combineReducers({
	auth,
	notifications,
	properties,
	sellers,
	calendar,
	viewings,
	keros,
	router: routerReducer(history),
	user: resourceReducer('user'),
	profile: resourceReducer('profile'),
	documents: resourceReducer('documents'),
	validation: resourceReducer('validation'),
	media: resourceReducer('media'),
	agents: resourceReducer('agents', {plugins: [reset]}),
	repositions: resourceReducer('repositions')
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
	: compose;
/* eslint-enable */

const enhancer = composeEnhancers(
	applyMiddleware(
		authMiddleware,
		resource,
		routerMiddleware,
		httpPreprocess,
		httpMiddleware,
		httpPostprocess,
		downloadFileMiddleware,
		logger,
		workHoursPopup,
		addSaleToKeros
	)
);

export default function () {
	return createStore(
		reducer,
		enhancer
	);
}
