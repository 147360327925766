export default {
	openProfile: 'openProfile',
	openProfileTree: 'openProfileTree',
	replaceGodfather: 'replaceGodfather',
	detachGodfather: 'detachGodfather',
	attachGodfather: 'attachGodfather',
	archiveAgent: 'archiveAgent',
	unarchiveAgent: 'unarchiveAgent',
	blockAgent: 'blockAgent',
	unblockAgent: 'unblockAgent',
	cardNftSold: 'cardNftSold',
	cutBranch: 'cutBranch',
	assignParentCard: 'assignParentCard'
};
