import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

function getTitleTranslation(title, t) {
	if (typeof title === 'string') {
		return title;
	}

	const {key, options} = title;

	if (options && i18next.exists(`navigation:pathNodes.${key}-with-options`)) {
		return t(`navigation:pathNodes.${key}-with-options`, options);
	}

	if (i18next.exists(`navigation:pathNodes.${key}.title`)) {
		return t(`navigation:pathNodes.${key}.title`, options);
	}

	return t(`navigation:pathNodes.${key}`, options);
}

const Breadcrumbs = props => {
	const {t, classes, list} = props;

	return (
		<Typography className={classes.breadcrumbs}>
			{list.map((node, index) => (
				<span key={index} className={classes.pathNode}>
					<Link to={node.link} className={classes.pathNodeLink}>
						<span className={classes.pathNodeLinkText}>
							{getTitleTranslation(node.title, t)}
						</span>
					</Link>
					&#47;
				</span>
			))}
		</Typography>
	);
};

Breadcrumbs.propTypes = {
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	list: PropTypes.array.isRequired
};

export default withStyles(styles)(Breadcrumbs);
