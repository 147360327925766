import React from 'react';
import PropTypes from 'prop-types';
import {Trans} from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

import {CommissionSettings} from '../../../../../domain/fields';
import {StartBoostIcon} from '../icons';

const target = CommissionSettings.START_BOOST;
const active = CommissionSettings.START_BOOST_ACTIVE;
const commissionRate = CommissionSettings.START_BOOST_COMMISSION_RATE;
const signMandatesPeriod = CommissionSettings.START_BOOST_SIGNMANDATES_PERIOD;
const discountPeriod = CommissionSettings.START_BOOST_DISCOUNT_PERIOD;
const discountMandatesLimit = CommissionSettings.START_BOOST_DISCOUNT_MANDATES_LIMIT;
const discountMandates = CommissionSettings.START_BOOST_DISCOUNT_MANDATES;
const manualMandates = CommissionSettings.START_BOOST_MANUAL_MANDATES;

const activeOptions = [true, false];
const signMandatesPeriodOptions = [...[...Array(4).keys()].map(i => (i + 1) * 30)];
const discountPeriodOptions = [null, ...[...Array(12).keys()].map(i => (i + 1) * 30)];
const discountMandatesLimitOptions = [...Array(20).keys()].map(i => i + 1); // 1,2,3...20

export const REACT_TEXTFIELD_EMPTY_VALUE = 'EMPTY';

class StartBoost extends React.Component {
	render() {
		const {classes, values, t} = this.props;
		const displayedMandates = (values[target][discountMandates] || []).slice(0, values[target][discountMandatesLimit]);
		const displayedManualMandates = values[target][manualMandates] || [];

		return (
			<div className={classes.fieldContainer}>
				<div className={classes.fieldTitle}>
					<StartBoostIcon className={classes.fieldIcon} />
					{t(`commission-settings:${target}.title`)}
				</div>
				<div className={classes.fieldWhiteContainer}>
					<Select
						className={classes.selectPrimary}
						name={active}
						value={values[target][active] || false}
						onChange={this.onChange}
						fullWidth
						MenuProps={{
							classes: {paper: classes.selectPrimaryMenu}
						}}
					>
						{activeOptions.map(key => (
							<MenuItem key={`key_${key}`} value={key}>
								{t(`commission-settings:${target}.${active}.${key}`)}
							</MenuItem>
						))}
					</Select>
				</div>
				<div className={(values[target][active] === false ? classes.sectionBlocked : '')}>
					<div className={classes.twoCol}>
						<div>
							<Typography variant="body2" className={classes.labelText}>{t(`commission-settings:${target}.${commissionRate}`)}</Typography>
							<TextField
								fullWidth
								className={classes.inputWhite}
								name={commissionRate}
								value={values[target][commissionRate]}
								variant="outlined"
								type="number"
								inputProps={{
									min: 0,
									max: 100
								}}
								InputProps={{
									endAdornment: <span className={classes.adornment}>%</span>
								}}
								onChange={this.onChange}
								onBlur={this.onBlur}
							/>
						</div>
						<div>
							<Typography variant="body2" className={classes.labelText}>{t(`commission-settings:${target}.${signMandatesPeriod}.title`)}</Typography>
							<Select
								fullWidth
								className={classes.selectWhite}
								name={signMandatesPeriod}
								value={values[target][signMandatesPeriod] || REACT_TEXTFIELD_EMPTY_VALUE}
								onChange={this.onChange}
								MenuProps={{
									classes: {paper: classes.selectWhiteMenu}
								}}
							>
								{signMandatesPeriodOptions.map(key => {
									const value = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : key;
									const transKey = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : (key > 1 ? 'values' : 'value');

									return (
										<MenuItem key={value} value={value}>
											{t(`commission-settings:${target}.${signMandatesPeriod}.${transKey}`, {value: key})}
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<div>
							<Typography variant="body2" className={classes.labelText}>
								<Trans i18nKey={`commission-settings:${target}.${discountPeriod}.title`}/>
							</Typography>
							<Select
								fullWidth
								className={classes.selectWhite}
								name={discountPeriod}
								value={values[target][discountPeriod] || REACT_TEXTFIELD_EMPTY_VALUE}
								onChange={this.onChange}
								MenuProps={{
									classes: {paper: classes.selectWhiteMenu}
								}}
							>
								{discountPeriodOptions.map(key => {
									const value = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : key;
									const transKey = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : (key > 1 ? 'values' : 'value');

									return (
										<MenuItem key={value} value={value}>
											{t(`commission-settings:${target}.${discountPeriod}.${transKey}`, {value: key})}
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<div>
							<Typography variant="body2" className={classes.labelText}>
								<Trans i18nKey={`commission-settings:${target}.${discountMandatesLimit}.title`}/>
							</Typography>
							<Select
								fullWidth
								className={classes.selectWhite}
								name={discountMandatesLimit}
								value={values[target][discountMandatesLimit] || REACT_TEXTFIELD_EMPTY_VALUE}
								onChange={this.onChange}
								MenuProps={{
									classes: {paper: classes.selectWhiteMenu}
								}}
							>
								{discountMandatesLimitOptions.map(key => {
									const value = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : key;
									const transKey = key === null ? REACT_TEXTFIELD_EMPTY_VALUE : (key > 1 ? 'values' : 'value');

									return (
										<MenuItem key={value} value={value}>
											{t(`commission-settings:${target}.${discountMandatesLimit}.${transKey}`, {value: key})}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					</div>
					<div className={classes.startBoostTableContainer}>
						<Typography variant="body2" className={classes.labelText}>{t(`commission-settings:${target}.${discountMandates}`)}</Typography>
						<div className={classes.startBoostTable}>
							{displayedMandates.map(mandate => (
								<div key={mandate}>{mandate}</div>
							))}
						</div>
					</div>
					<div className={classes.startBoostTableContainer}>
						<Typography variant="body2" className={classes.labelText}>{t(`commission-settings:${target}.${manualMandates}`)}</Typography>
						<div className={classes.manualMandateTable}>
							<div className={classes.addMandate}>
								<TextField
									fullWidth
									className={classes.inputWhite}
									placeholder="mandat number"
									variant="outlined"
									type="number"
									inputRef={this.mandateInputRef}
									InputProps={{
										endAdornment: <PlusIcon onClick={this.addManualMandate} className={classes.addMandatePlus}/>
									}}
								/>
							</div>
							{displayedManualMandates.map(mandate => (
								<div key={mandate} className={classes.removeMandate}>
									{mandate}
									<RemoveIcon onClick={() => this.removeManualMandate(mandate)} className={classes.removeMandateIcon} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	shouldComponentUpdate({values: nextValues, language: nextLanguage}) {
		const {props: {values: oldValues, language: oldLanguage}} = this;
		const oldMandates = oldValues[target][discountMandates];
		const newMandates = nextValues[target][discountMandates];
		const oldManualMandates = oldValues[target][manualMandates];
		const newManualMandates = nextValues[target][manualMandates];

		return (oldLanguage !== nextLanguage)
			|| [active, commissionRate, signMandatesPeriod, discountPeriod, discountMandatesLimit].some(key => oldValues[target][key] !== nextValues[target][key])
			|| (oldMandates.length !== newMandates.length)
			|| (oldManualMandates.length !== newManualMandates.length)
			|| oldMandates.some((mandate, index) => mandate !== newMandates[index])
			|| oldManualMandates.some((mandate, index) => mandate !== newManualMandates[index]);
	}

	onChange = (e, isOnBlur) => {
		const {onChange, values} = this.props;
		const targetValues = Object.assign({}, values[target]);

		if (e.target.name === commissionRate) {
			let val = e.target.value;

			if (isOnBlur) {
				val = parseFloat(val, 10) || 0;

				if (val < 0) val = 0;
				else if (val > 100) val = 100;
			}

			targetValues[e.target.name] = val;
		} else {
			targetValues[e.target.name] = e.target.value === REACT_TEXTFIELD_EMPTY_VALUE ? null : e.target.value;
		}
		onChange(target, targetValues);
	}

	onBlur = e => this.onChange(e, true);

	addManualMandate = e => {
		const {onChange, values} = this.props;
		const targetValues = Object.assign({}, values[target]);
		const targetArray = targetValues[manualMandates] || [];
		const value = this.mandateInputRef.current.value;

		if (value && !targetArray.find(n => n === value)) {
			targetValues[manualMandates] = [...targetArray, value];

			this.mandateInputRef.current.value = '';
			onChange(target, targetValues);
		}
	}

	removeManualMandate = mandate => {
		const {onChange, values} = this.props;
		const targetValues = Object.assign({}, values[target]);
		const targetArray = targetValues[manualMandates] || [];
		
		targetValues[manualMandates] = targetArray.filter(n => n !== mandate);
		onChange(target, targetValues);
	}

	mandateInputRef = React.createRef();
}

StartBoost.propTypes = {
	classes: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	language: PropTypes.string.isRequired
};

export default StartBoost;
