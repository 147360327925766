import PropTypes from 'prop-types';

const INPUT = 'input';
const INPUT_MONEY = 'inputMoney';
const MULTILINE = 'multiline';
const DOCUMENT = 'document';
const IMAGE = 'image';
const LINK = 'link';
const WIDGET = 'widget';
const DATE = 'date';
const SELECT = 'select';
const CHECKBOX = 'checkbox';
const RADIO = 'radio';
const SWITCH = 'switch';
const RATING = 'rating';
const EMPTY = 'empty';
const TEL = 'tel';
const NUMBER = 'number';

const asArray = [INPUT, INPUT_MONEY, MULTILINE, DATE, DOCUMENT, IMAGE, LINK, WIDGET, SELECT, CHECKBOX, RATING, SWITCH, EMPTY];

export default ({
	INPUT,
	INPUT_MONEY,
	MULTILINE,
	DOCUMENT,
	IMAGE,
	DATE,
	LINK,
	WIDGET,
	SELECT,
	CHECKBOX,
	RADIO,
	SWITCH,
	RATING,
	EMPTY,
	TEL,
	NUMBER,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
