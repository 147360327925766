import moment from 'moment';
import {HttpMethods} from '../../domain/http';
import {SellersUrlBuilder} from '../../api';

export const GET_SELLERS_POOL = 'GET_SELLERS_POOL';
export const ASSIGN_AGENT_SELLERS_POOL = 'ASSIGN_AGENT_SELLERS_POOL';
export const CANCEL_ASSIGN_AGENT_SELLERS_POOL = 'CANCEL_ASSIGN_AGENT_SELLERS_POOL';
export const RESET_SELLERS_POOL = 'RESET_SELLERS_POOL';

export const GET_SELLERS_LIST = 'GET_SELLERS_LIST';
export const RESET_SELLERS_LIST = 'RESET_SELLERS_LIST';

export const UPDATE_SELLER_ROLES = 'UPDATE_SELLER_ROLES';
export const UPDATE_ARCHIVE_SELLER = 'UPDATE_ARCHIVE_SELLER';
export const UPDATE_SELLER_BLOCK = 'UPDATE_SELLER_BLOCK';

export const GET_COMMENTS_OF_SELLER = 'GET_COMMENTS_OF_SELLER';
export const RESET_COMMENTS_OF_SELLER = 'RESET_COMMENTS_OF_SELLER';
export const ADD_COMMENT_TO_SELLER = 'ADD_COMMENT_TO_SELLER';
export const DELETE_COMMENT_OF_SELLER = 'DELETE_COMMENT_OF_SELLER';

export const RESEND_SELLER_WELCOME_EMAIL = 'RESEND_SELLER_WELCOME_EMAIL';

export const UPDATE_HIDDEN_STATUS = 'UPDATE_HIDDEN_STATUS';

export const getSellersPool = () => ({
	type: GET_SELLERS_POOL,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SellersUrlBuilder.sellersPool()
		},
		postprocess: action => {
			const {
				http: {
					response: {
						body
					}
				}
			} = action;

			action.data = body.map(x => ({
				...x, createdAt: moment.utc(x.createdAt)
			}));

			return action;
		}
	}
});

export const assignSellersPool = (propertyId, isAdmin) => ({
	type: ASSIGN_AGENT_SELLERS_POOL,
	http: {
		withToken: true,
		request: {
			method: isAdmin ? HttpMethods.PUT : HttpMethods.POST,
			url: SellersUrlBuilder.assignSellersPool(propertyId)
		},
		postprocess: action => {
			const {
				http: {
					response: {
						body
					}
				}
			} = action;

			action.data = body.map(x => ({
				...x, createdAt: moment.utc(x.createdAt)
			}));

			return action;
		}
	}
});

export const cancelSellersPool = propertyId => ({
	type: CANCEL_ASSIGN_AGENT_SELLERS_POOL,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.DELETE,
			url: SellersUrlBuilder.assignSellersPool(propertyId)
		},
		postprocess: action => {
			const {
				http: {
					response: {
						body
					}
				}
			} = action;

			action.data = body.map(x => ({
				...x, createdAt: moment.utc(x.createdAt)
			}));

			return action;
		}
	}
});

export const resetSellersPool = () => ({
	type: RESET_SELLERS_POOL
});

export const getSellersList = params => ({
	type: GET_SELLERS_LIST,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: SellersUrlBuilder.listSellers(),
			data: params
		}
	}
});

export const resetSellersList = () => ({
	type: RESET_SELLERS_LIST
});

export const updateSellerRole = (id, role) => ({
	type: UPDATE_SELLER_ROLES,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: SellersUrlBuilder.updateSellerRoles(id),
			data: {
				roles: [role]
			}
		}
	}
});

export const updateSellerArchive = (sellerId, isArchived) => ({
	sellerId,
	type: UPDATE_ARCHIVE_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.PUT,
			url: SellersUrlBuilder.updateSellerArchive(sellerId),
			data: {
				isArchived
			}
		}
	}
});

export const updateSellerBlock = (sellerId, isBlocked) => ({
	sellerId,
	type: UPDATE_SELLER_BLOCK,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: SellersUrlBuilder.updateSellerBlock(sellerId),
			data: {
				blocked: isBlocked
			}
		}
	}
});

export const getCommentsOfSeller = sellerId => ({
	sellerId,
	type: GET_COMMENTS_OF_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: SellersUrlBuilder.getCommentsOf(sellerId)
		}
	}
});

export const resetCommentsOfSeller = sellerId => ({
	sellerId,
	type: RESET_SELLERS_LIST
});

export const addCommentToSeller = (sellerId, text) => ({
	sellerId,
	type: ADD_COMMENT_TO_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.POST,
			url: SellersUrlBuilder.addCommentTo(sellerId),
			data: {text}
		}
	}
});

export const deleteCommentOfSeller = (sellerId, commentId) => ({
	sellerId,
	commentId,
	type: DELETE_COMMENT_OF_SELLER,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.DELETE,
			url: SellersUrlBuilder.deleteComment(commentId)
		}
	}
});

export const resendSellerWelcome = sellerId => ({
	id: sellerId,
	type: RESEND_SELLER_WELCOME_EMAIL,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			postprocess: true,
			url: SellersUrlBuilder.resendSellerWelcomeEmail(sellerId)
		}
	}
});

export const updateHiddenStatus = (sellerId, isHidden, successMessage) => ({
	type: UPDATE_HIDDEN_STATUS,
	id: sellerId,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.PUT,
			url: SellersUrlBuilder.updateHiddenStatus(sellerId),
			data: {
				hidden: isHidden
			},
			onSuccess: {
				notify: {
					message: successMessage,
					settings: {
						autoHideDuration: 2000
					}
				}
			}
		}
	}
});
