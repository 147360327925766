import PropTypes from 'prop-types';

const APARTMENT = 'apartment';
const MAISON = 'maison';
// const GARAGE = 'garage';
const TERRAIN = 'terrain';
const LMNP = 'lmnp';
const JOINT_OWNERSHIP_HOUSE = 'jointOwnershipHouse';
const TRADE_FUNDS_ASSIGNMENT = 'tradeFundsAssignment';
const LEASE_ASSIGNMENT = 'leaseAssignment';

const asArray = [APARTMENT, MAISON, TERRAIN, LMNP];
const forLMNPAgents = [LMNP];
const forAgents = [APARTMENT, MAISON, TERRAIN];

export const PropertyTypes = ({
	APARTMENT,
	MAISON,
	TERRAIN,
	LMNP,
	JOINT_OWNERSHIP_HOUSE,
	TRADE_FUNDS_ASSIGNMENT,
	LEASE_ASSIGNMENT,
	asArray,
	forAgents,
	forLMNPAgents,
	propType: PropTypes.oneOf(asArray)
});
