import {matchPath, generatePath} from 'react-router';
import {NavigationKeyToRoute} from '../domain/navigation';

function useLocationState(location, path) {
	if (!location || !location.state || !location.state.source) {
		return path;
	}

	const {state: {source: {title, link, position}}} = location;
	const crumb = {title, link};

	if (!position) {
		const last = path.pop();

		path.push(crumb);
		path.push(last);
	} else if (typeof position === 'number') {
		path.splice(position, 0, crumb);
	}

	return path;
}

export default function (
	location
) {
	const path = location.pathname.split('/').reduce((crumbs, x) => {
		const lastIndex = crumbs.length - 1;
		const previousRoute = crumbs[lastIndex] || {key: '', link: ''};

		if (x === '' && crumbs.length === 0) {
			crumbs.push({title: {key: 'home'}, link: '/', key: ''});
		} else {
			const key = previousRoute.key !== '' ? `${previousRoute.key}.${x}` : x;
			const route = NavigationKeyToRoute[key];

			if (route) {
				const {link} = route;

				crumbs.push({title: {key: route.i18nKey}, link, key: route.key, ignore: route.ignoreInBreadcrumbs});
			} else {
				const routeWithoutSubmodule = NavigationKeyToRoute[`${previousRoute.key}.id`];

				if (routeWithoutSubmodule) {
					crumbs.push({
						title: {key: routeWithoutSubmodule.i18nKey},
						link: routeWithoutSubmodule.link,
						key: routeWithoutSubmodule.key,
						ignore: routeWithoutSubmodule.ignoreInBreadcrumbs
					});
				}
			}
		}

		return crumbs;
	}, []);

	useLocationState(location, path);

	const currentPath = path[path.length - 1];
	const match = matchPath(location.pathname, {path: currentPath.link, exact: true});

	if (!match) {
		return path.filter(x => !x.ignore);
	}

	const filledPath = path.map(x => {
		const correctLink = generatePath(x.link, match.params);

		return {
			...x, link: correctLink
		};
	}).filter(x => !x.ignore);

	return filledPath;
}
