import PropTypes from 'prop-types';

const FRENCH = 'french';
const INTERNATIONAL = 'international';
const LUXURY = 'luxury';
const TRADE = 'trade';
const OTHER = 'other';

const asArray = [FRENCH, INTERNATIONAL, LUXURY, TRADE, OTHER];
const modalGroup = [FRENCH, INTERNATIONAL, LUXURY, TRADE];

export default ({
	FRENCH,
	INTERNATIONAL,
	LUXURY,
	TRADE,
	OTHER,
	asArray,
	modalGroup,
	propType: PropTypes.oneOf(asArray)
});
