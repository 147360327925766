import cookies from 'js-cookie';
import {AUTH_LOGIN, AUTH_LOGOUT} from '../actions/auth';

const AUTH_COOKIE_TOKEN = 'MySextantAuthToken';
const SKIP_LANDING_COOKIE = 'MySextantSkipLanding';
const INITIAL_TOKEN = cookies.get(AUTH_COOKIE_TOKEN) || null;
const INITIAL_SKIP_LANDING = cookies.get(SKIP_LANDING_COOKIE) || null;

function setToken(state, token) {
	cookies.set(AUTH_COOKIE_TOKEN, token);
	cookies.set(SKIP_LANDING_COOKIE, 'true');

	return {
		token,
		skipLanding: true
	};
}

function resetToken() {
	cookies.remove(AUTH_COOKIE_TOKEN);

	return {
		token: null,
		skipLanding: true
	};
}

const authInitialState = {
	token: INITIAL_TOKEN,
	skipLanding: INITIAL_SKIP_LANDING === 'true'
};

export default (state = authInitialState, action) => {
	switch (action.type) {
		case AUTH_LOGIN: {
			return setToken(state, action.data.access_token);
		}
		case AUTH_LOGOUT:
			return resetToken();
		default:
			return state;
	}
};
