import PropTypes from 'prop-types';

const MANDAT_FORM_TYPE_SIMPLE = 'simple';
const MANDAT_FORM_TYPE_SATISFACTION = 'satisfaction';
const MANDAT_FORM_TYPE_EXCLUSIVE = 'exclusive';
const asArray = [MANDAT_FORM_TYPE_SIMPLE, MANDAT_FORM_TYPE_SATISFACTION, MANDAT_FORM_TYPE_EXCLUSIVE];

export default ({
	MANDAT_FORM_TYPE_SIMPLE,
	MANDAT_FORM_TYPE_SATISFACTION,
	MANDAT_FORM_TYPE_EXCLUSIVE,
	asArray,
	propType: PropTypes.oneOf(asArray)
});

