import {connect} from 'react-redux';
import lodash from 'lodash';
import {
	compose, lifecycle, mapProps, withState, branch, renderComponent
} from 'recompose';
import {scaledLoadingIndicator} from '../../components/common/loading-indicator/loading-indicator';
import userManager from '../../utils/user-manager';
import ComponentSwitcher from './component-switcher';

const LoadingIndicator = scaledLoadingIndicator(4, true);
const GET_USER_INFO_TIMER = 30000; // 1 min
const userStateShouldUpdate = (prevState, nextState) => {
	const nextStateObjKeyArr = Object.keys(nextState);
	let needUpdate = false;

	// STATE:
	// token: string
	// skipLanding: bool
	// id: numeric
	// firstName: string
	// lastName: string
	// email: string
	// avatar: undefined or string
	// roles: array of strings

	for (let i = 0; i < nextStateObjKeyArr.length; i++) {
		const key = nextStateObjKeyArr[i];
		const prevTarget = prevState[key];
		const nextTarget = nextState[key];

		if (Array.isArray(prevTarget) && Array.isArray(nextTarget)) {
			if (
				(prevTarget.length !== nextTarget.length)
				|| (prevTarget.join('') !== nextTarget.join(''))
			) {
				needUpdate = true;
				break;
			}
		} else if (prevTarget !== nextTarget) {
			needUpdate = true;
			break;
		}
	}

	return needUpdate;
};
const updateUserTimerFn = async (authState, setAuthState) => {
	const newUserData = await userManager.getUserAsync(true);

	if (userStateShouldUpdate(authState.user, newUserData)) {
		// there are rare cases with undefined user.roles.
		// Usually it happens when user is reconnecting after large time-off
		if (authState.user.roles && !lodash.isEqual(authState.user.roles, newUserData.roles)) {
			console.log('The user roles have been changed. ',
				'was:', authState.user.roles,
				'become:', newUserData.roles,
				'Do automatic re-login for getting the updated token');
			await userManager.logIn();
			return false;
		}

		setAuthState({
			...authState,
			user: newUserData,
			userLoaded: true,
			skipLanding: newUserData.skipLanding
		});
	}
};

const {isAuthorized: initAuth, skipLanding: initSkip} = userManager.getStartingData();

const mapStateToProps = ({router: {location}}) => ({location});

const enhance = compose(
	connect(mapStateToProps),
	withState('authState', 'setAuthState', {
		isAuthorized: initAuth,
		skipLanding: initSkip,
		userLoaded: false,
		user: {}
	}),
	lifecycle({
		async componentDidMount() {
			const {authState, setAuthState} = this.props;

			if (authState.isAuthorized) {
				const user = await userManager.getUserAsync();

				setAuthState({
					...authState,
					user,
					userLoaded: true,
					skipLanding: user.skipLanding
				});

				if (!this.timer) {
					this.timer = setInterval(() => updateUserTimerFn(authState, setAuthState), GET_USER_INFO_TIMER);
				}
			}
		},
		async componentDidUpdate() {
			const {authState: {isAuthorized}, setAuthState} = this.props;
			const {isAuthorized: newAuth, skipLanding: newSkip} = userManager.getStartingData();

			if (isAuthorized !== newAuth) {
				const user = await userManager.getUserAsync();

				setAuthState({
					isAuthorized: newAuth,
					skipLanding: newSkip,
					user,
					userLoaded: true
				});

				if (!this.timer) {
					this.timer = setInterval(() => updateUserTimerFn(this.props.authState, setAuthState), GET_USER_INFO_TIMER);
				}
			}
		},
		componentWillUnmount() {
			clearInterval(this.timer);

			this.timer = null;
		}
	}),
	mapProps(({authState, ...rest}) => ({
		...rest,
		...authState,
		skipLanding: userManager.getStartingData().skipLanding // bugfix: clear storage, login on keros, open sextant, landing>next, see landing again instead of inner page
	})),
	branch(({isAuthorized, userLoaded}) => isAuthorized && !userLoaded, renderComponent(LoadingIndicator))
);

export default enhance(ComponentSwitcher);
