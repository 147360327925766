const VALUATION = 'valuation';
const DOCUMENTS = 'documents';
const MANDAT = 'mandat';
const SALE = 'sale';
const SOLD = 'sold';
const CANCELLED = 'canceled';
const EXCHANGE = 'exchangePending';
const asArray = [VALUATION, DOCUMENTS, MANDAT, SALE, EXCHANGE, CANCELLED, SOLD];

export default ({
	asArray,
	VALUATION,
	DOCUMENTS,
	MANDAT,
	SALE,
	SOLD,
	EXCHANGE,
	CANCELLED
});
