import {HttpMethods} from '../../domain/http';
import {ViewingsUrlBuilder} from '../../api';

export const GET_VIEWINGS = 'GET_VIEWINGS';

export const RESET_VIEWINGS = 'RESET_VIEWINGS';

export const getViewings = params => ({
	type: GET_VIEWINGS,
	http: {
		withToken: true,
		postprocess: true,
		request: {
			method: HttpMethods.GET,
			url: ViewingsUrlBuilder.getViewingsBy(params)
		}
	}
});

export const resetViewings = () => ({
	type: RESET_VIEWINGS
});
