import React from 'react';
import PropTypes from 'prop-types';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import BottomNavigationContainer from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import {isCustomForSellers} from '../custom-keys-helper';

const CUTOFF = 3;

class BottomNavigation extends React.Component {
	render() {
		const {t, classes, data, history} = this.props;
		const needSlice = data.length > (CUTOFF + 1);
		const main = needSlice ? data.slice(0, CUTOFF) : data;

		return (
			<BottomNavigationContainer
				className={classes.root}
				component="nav"
			>
				{main.map(x => {
					const label = isCustomForSellers(x.key) ? t(`bottomNavigation.custom-for-sellers.${x.key}`) : t(`bottomNavigation.${x.key}`);

					return (
						<BottomNavigationAction
							showLabel
							classes={{
								root: classes.button,
								selected: classes.selected,
								label: classes.label
							}}
							key={x.key}
							disabled={x.disabled}
							label={label}
							icon={<x.Icon classes={{root: classes.icon}}/>}
							onClick={() => history.push(x.link)}
						/>
					);
				})}
				{needSlice && this.renderOther()}
			</BottomNavigationContainer>
		);
	}

	renderOther() {
		const {t, classes, data, history} = this.props;
		const {otherAnchorEl} = this.state;
		const other = data.slice(CUTOFF);

		return ([
			<BottomNavigationAction
				showLabel
				classes={{
					root: classes.button,
					selected: classes.selected,
					label: classes.label
				}}
				key="more"
				icon={<MoreIcon className={classes.icon}/>}
				label={t('bottomNavigation.more')}
				onClick={this.handleOpenOther}
			/>,
			<Menu
				key="other-menu"
				anchorEl={otherAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={Boolean(otherAnchorEl)}
				MenuListProps={{
					classes: {
						root: classes.tabletMenuList
					}
				}}
				onClose={this.handleCloseOther}
			>
				{other.map(x => {
					const label = isCustomForSellers(x.key) ? t(`bottomNavigation.custom-for-sellers.${x.key}`) : t(`bottomNavigation.${x.key}`);

					return (
						<MenuItem
							key={x.key}
							onClick={async () => {
								await this.handleCloseOther();
								history.push(x.link);
							}}
						>
							{!!x.Icon && (
								<ListItemIcon>
									<x.Icon classes={{root: classes.icon}}/>
								</ListItemIcon>
							)}
							<ListItemText
								className={classes.otherLabel}
								disableTypography
								primary={label}
							/>
						</MenuItem>
					);
				})}
			</Menu>
		]);
	}

	handleOpenOther = event => {
		const {otherAnchorEl} = this.state;

		if (!otherAnchorEl) {
			this.setState({otherAnchorEl: event.currentTarget});
		}
	};

	handleCloseOther = () => this.setState({otherAnchorEl: null});

	state = {
		otherAnchorEl: null
	}
}

BottomNavigation.propTypes = {
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired
};

export default withStyles(styles)(BottomNavigation);
