const getCalendarEvents = (id, timeInterval) => `/calendar/user/${id}?${timeInterval}`;
const createEvent = () => '/calendar/event';
const deleteEvent = id => `/calendar/event/${id}`;
const editEvent = id => `/calendar/event/${id}`;
const workHours = id => `/user/${id}/workhours`;
const downloadCalendar = (id, token) => `/calendar/user/${id}/export/ics?token=${encodeURIComponent(token)}`;

export default {
	getCalendarEvents,
	createEvent,
	deleteEvent,
	editEvent,
	workHours,
	downloadCalendar
};
