import Route from './helpers/route';
import {UserRoles} from '../user/roles';

const {
	UNVERIFIED_SELLER, SELLER, ADMIN
} = UserRoles;

const sellerRoles = [UNVERIFIED_SELLER, SELLER];

const myProfile = new Route('my-profile', '/my-profile', 'client/profile/profile-my', {Icon: 'ProfileIcon'});
const supportGroup = new Route('supportGroup', '/support', 'client/support', {Icon: 'SupportIcon'});
const support = new Route('support', '/support', 'client/support', {
	hiddenFor: [...sellerRoles],
	Icon: 'SupportIcon'
});
const accessDenied = new Route('403', '/403', 'client/access-denied');
const notFound = new Route('404', '/404', 'client/not-found');

const dashboard = new Route('dashboard', '/', 'client/outils', {
	Icon: 'DashboardIcon'
});

const myActivity = new Route('my-activity', '/', 'client/not-found', {Icon: 'MyActivityIcon'});
const myApps = new Route('my-apps', '/outils-sextant', 'client/outils', {
	Icon: 'MyAppsIcon',
	disabledFor: [...sellerRoles],
	disabledAsHiddenInNav: true
});
const myTeam = new Route('my-team', '/', 'client/not-found', {
	Icon: 'MyTeamIcon',
	disabledFor: [...sellerRoles],
	disabledAsHiddenInNav: true
});

const myTraining = new Route('my-training', 'https://formez-vous.immo/', 'client/not-found', {
	Icon: 'MyTrainingIcon',
	target: '_blank',
	disabledFor: [...sellerRoles],
	disabledAsHiddenInNav: true
});

export {
	myProfile,
	supportGroup,
	support,
	accessDenied,
	notFound,
	dashboard,
	myActivity,
	myApps,
	myTeam,
	myTraining
};
