import React, {Component, useState} from 'react';
import {Link} from 'react-router-dom';
import SvgIcon from '@material-ui/core/SvgIcon';
import cn from 'classnames';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
// import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Breadcrumbs from './breadcrumbs';
import BackButton from './back-button';
import LanguageSwitch from './language-switch';
import {UserRoles} from '../../../../domain/user/roles';
import {UserNavigation} from '../../../../domain/navigation';
import ModulesPath from '../../../../utils/modules-path';
import Avatar from './avatar';
import styles from './styles';


class Header extends Component {
	constructor(props) {
		super(props);

		this.userMenuRef = React.createRef();
		this.$docRoot = document.querySelector('#root');

		this.state = {
			open: false
		};
	}

	componentDidMount() {
		this.$docRoot.addEventListener('click', this.closeUserMenuOnWhitespace);
	}

	componentWillUnmount() {
		this.$docRoot.removeEventListener('click', this.closeUserMenuOnWhitespace);
	}

	closeUserMenuOnWhitespace = e => {
		const {open} = this.state;

		if (!open) return;

		const $userMenu = this.userMenuRef.current;
		let el = e.target;
		let isChildOfUserMenu = false;

		if (el !== $userMenu) {
			while(el.parentNode) {
				if (el.parentNode === $userMenu) {
					isChildOfUserMenu = true;
					break;
				}
				el = el.parentNode;
			}
		}

		if (!isChildOfUserMenu) {
			// hack to close usermenu by clicking on username block otherwise toggleUserMenu fires right after menu was closed
			setTimeout(() => this.setState({open: false}), 0);
		}
	};

	toggleUserMenu = forceClose => {
		const {open} = this.state;

		this.setState({
			open: !(forceClose === true || open)
		});
	};

	render() {
		const {
			t, classes, history, location, match, hasRoleToggle, sidebarOpen, 
			userId, userName, roles, level, myCommission, godsonsCommission, avatar,
			logout, switchRole, handleOpenSidebar
		} = this.props;
		const {open} = this.state;
		const fullPath = ModulesPath(location, match);
		const {title} = fullPath[fullPath.length - 1];
		const breadcrumbsList = fullPath.slice(0, fullPath.length - 1);
		const pageName = typeof title === 'string' ? title : t(`pathNodes.${title.key}`, title.options);
		const pageNameStyles = pageName && pageName.length > 32 ? {fontSize: 16} : {};
		const userRole = roles.map(value => UserRoles.names[value]).join(', ');
		const isAgent = UserRoles.isAgent(roles);
		const myCommissionFixed = myCommission ? parseFloat(myCommission, 10).toFixed(2) : 0;
		const godsonsCommissionFixed = godsonsCommission ? parseFloat(godsonsCommission, 10).toFixed(2) : 0;

		const {myProfile, myDocuments} = UserNavigation;

		return (
			<AppBar key="header" position="absolute" className={classes.header}>
				<Toolbar
					className={cn(
						classes.toolbar,
						classes.contentShift,
						sidebarOpen ? classes.sidebarOpen : classes.sidebarClosed
					)}
				>
					<div className={classes.toolbarStart}>
						{!isMobile() && (
							<IconButton
								edge="start"
								onClick={handleOpenSidebar}
								className={cn(classes.sidebarControlButton, {[classes.hide]: sidebarOpen})}
							>
								<MenuIcon />
							</IconButton>
						)}
						<div className={classes.pageInfo}>
							<Breadcrumbs list={breadcrumbsList}/>
							{breadcrumbsList.length > 1 && (
								<BackButton
									to={breadcrumbsList[breadcrumbsList.length - 1]}
								/>
							)}
							<Typography
								variant="subheading"
								component="h4"
								className={classes.pageInfoTitle}
								style={pageNameStyles}
							>
								{pageName}
							</Typography>
						</div>
					</div>
					<div className={classes.toolbarEnd}>
						<LanguageSwitch/>
						<div className={classes.userInfoContainer} onClick={this.toggleUserMenu}>
							<div className={classes.desktopInfo}>
								<Avatar
									classes={classes}
									avatar={avatar}
									userName={userName}
								/>
								<div className={classes.userInfo}>
									<Typography variant="body2" className={classes.userName}>
										{userName}
									</Typography>
									<Typography variant="caption" className={classes.userRole}>
										{userRole}
									</Typography>
								</div>
								<div className={classes.userInfoButtonContainer}>
									<IconButton className={classes.userInfoButton}>
										{open ? <ExpandLess /> : <ExpandMore />}
									</IconButton>
								</div>
							</div>
						</div>
						<Collapse in={open} className={classes.userInfoPanel}>
							<div className={classes.userInfoPanelContainer} ref={this.userMenuRef}>
								{/*
									<div className={classes.userLevel}>
										<Typography className={classes.userLevelLetter}>A</Typography>
										<Typography className={classes.userLevelMark}>{level}</Typography>
										<Typography className={classes.userLevelText}>alpha</Typography>
									</div>
								*/}
								{level && (
									<div className={classes.userLevel}>
										<Typography className={classes.userLevelMark}>{level}</Typography>
									</div>
								)}
								{isAgent && (
									<div className={classes.userComissions}>
										<div className={classes.userComission}>
											<div className={classes.userComissionTitle}>
												<SvgIcon className={classes.userComissionIcon} style={{width: 26, height: 21}} viewBox="0 0 26 21">
													<path d="M23.716 0.142856C24.3052 0.142856 24.8097 0.352678 25.2294 0.772321C25.649 1.19196 25.8588 1.69643 25.8588 2.28571V18.5714C25.8588 19.1607 25.649 19.6652 25.2294 20.0848C24.8097 20.5045 24.3052 20.7143 23.716 20.7143H2.28739C1.6981 20.7143 1.19364 20.5045 0.773996 20.0848C0.354353 19.6652 0.144531 19.1607 0.144531 18.5714V2.28571C0.144531 1.69643 0.354353 1.19196 0.773996 0.772321C1.19364 0.352678 1.6981 0.142856 2.28739 0.142856H23.716ZM2.28739 1.85714C2.17132 1.85714 2.06864 1.90179 1.97935 1.99107C1.899 2.07143 1.85882 2.16964 1.85882 2.28571V5.28571H24.1445V2.28571C24.1445 2.16964 24.0999 2.07143 24.0106 1.99107C23.9302 1.90179 23.832 1.85714 23.716 1.85714H2.28739ZM23.716 19C23.832 19 23.9302 18.9598 24.0106 18.8795C24.0999 18.7902 24.1445 18.6875 24.1445 18.5714V10.4286H1.85882V18.5714C1.85882 18.6875 1.899 18.7902 1.97935 18.8795C2.06864 18.9598 2.17132 19 2.28739 19H23.716ZM3.5731 17.2857V15.5714H7.00167V17.2857H3.5731ZM8.71596 17.2857V15.5714H13.8588V17.2857H8.71596Z" fill="#0FBACD"/>
												</SvgIcon>
												<Typography className={classes.userComissionTitleText}>{t('navigation:header.myCommission')}</Typography>
											</div>
											<div className={classes.userComissionValue}>
												{`${myCommissionFixed} €`}
											</div>
										</div>
										<div className={classes.userComission}>
											<div className={classes.userComissionTitle}>
												<SvgIcon className={classes.userComissionIcon} style={{width: 28, height: 19}} viewBox="0 0 28 19">
													<path d="M11.2161 13.4286H16.7875V12.0357H14.9304V5.53571H13.2764L11.129 7.52344L12.2462 8.68415C12.6525 8.32626 12.9185 8.05059 13.0442 7.85714H13.0732V12.0357H11.2161V13.4286ZM18.34 7.65402C18.5431 8.35045 18.6447 9.0372 18.6447 9.71429C18.6447 10.3914 18.5431 11.0781 18.34 11.7746C18.1369 12.471 17.8467 13.119 17.4694 13.7187C17.1019 14.3185 16.6134 14.8069 16.004 15.1842C15.3947 15.5614 14.7273 15.75 14.0018 15.75C13.2764 15.75 12.609 15.5614 11.9996 15.1842C11.3902 14.8069 10.8969 14.3185 10.5197 13.7187C10.1521 13.119 9.86677 12.471 9.66364 11.7746C9.46052 11.0781 9.35896 10.3914 9.35896 9.71429C9.35896 9.0372 9.46052 8.35045 9.66364 7.65402C9.86677 6.95759 10.1521 6.30952 10.5197 5.70982C10.8969 5.11012 11.3902 4.62165 11.9996 4.24442C12.609 3.86719 13.2764 3.67857 14.0018 3.67857C14.7273 3.67857 15.3947 3.86719 16.004 4.24442C16.6134 4.62165 17.1019 5.11012 17.4694 5.70982C17.8467 6.30952 18.1369 6.95759 18.34 7.65402ZM26.0732 13.4286V6C25.0479 6 24.1726 5.63728 23.4471 4.91183C22.7217 4.18638 22.359 3.31101 22.359 2.28571H5.64467C5.64467 3.31101 5.28195 4.18638 4.5565 4.91183C3.83105 5.63728 2.95568 6 1.93039 6V13.4286C2.95568 13.4286 3.83105 13.7913 4.5565 14.5167C5.28195 15.2422 5.64467 16.1176 5.64467 17.1429H22.359C22.359 16.1176 22.7217 15.2422 23.4471 14.5167C24.1726 13.7913 25.0479 13.4286 26.0732 13.4286ZM27.9304 1.35714V18.0714C27.9304 18.3229 27.8385 18.5406 27.6547 18.7243C27.4709 18.9081 27.2533 19 27.0018 19H1.00181C0.750326 19 0.532692 18.9081 0.348912 18.7243C0.165132 18.5406 0.0732422 18.3229 0.0732422 18.0714V1.35714C0.0732422 1.10565 0.165132 0.88802 0.348912 0.70424C0.532692 0.520461 0.750326 0.428571 1.00181 0.428571H27.0018C27.2533 0.428571 27.4709 0.520461 27.6547 0.70424C27.8385 0.88802 27.9304 1.10565 27.9304 1.35714Z" fill="#0FBACD"/>
												</SvgIcon>
												<Typography className={classes.userComissionTitleText}>{t('navigation:header.godsonsCommission')}</Typography>
											</div>
											<div className={classes.userComissionValue}>
												{`${godsonsCommissionFixed} €`}
											</div>
										</div>
									</div>
								)}
								<div className={classes.userInfoLinks}>
									{!roles.find(role => myProfile.disabledFor.includes(role)) && (
										<Link to={myProfile.link} className={classes.userInfoNoTextDecoration}>
											<Button
												component="button"
												className={classes.userInfoLink}
												variant="contained"
												color="primary"
												fullWidth
											>
												{t(`navigation:pathNodes.${myProfile.i18nKey}`)}
											</Button>
										</Link>
									)}
									{!roles.find(role => myDocuments.disabledFor.includes(role)) && (
										<Link to={myDocuments.link} className={classes.userInfoNoTextDecoration}>
											<Button
												className={classes.userInfoLink}
												variant="contained"
												color="primary"
												fullWidth
											>
												{t(`navigation:pathNodes.${myDocuments.i18nKey}`)}
											</Button>
										</Link>
									)}
									{hasRoleToggle && (
										<Button
											className={classes.userInfoLogout}
											variant="contained"
											color="secondary"
											fullWidth
											onClick={switchRole}
										>
											{t('role-switch')}
										</Button>
									)}
									<Button
										className={classes.userInfoLogout}
										variant="contained"
										color="secondary"
										fullWidth
										onClick={logout}
									>
										{t('signOut')}
									</Button>
								</div>
							</div>
						</Collapse>
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}

Header.propTypes = {
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	userId: PropTypes.number,
	userName: PropTypes.string,
	roles: PropTypes.array.isRequired,
	logout: PropTypes.func.isRequired,
	hasRoleToggle: PropTypes.bool.isRequired,
	switchRole: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	avatar: PropTypes.string,
	handleOpenSidebar: PropTypes.func.isRequired,
	sidebarOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(Header);
