import {showError, showWorkHours} from '../actions/notifications';
import {EDIT_PROPERTY_STAGE} from '../actions/properties';
import makeRequest from '../../utils/http/make-request';
import Stages from '../../domain/property/stage';

const {SOLD} = Stages;

export const TOGGLE_SALE_POPUP = 'TOGGLE_SALE_POPUP';
export const OPEN_SALE_POPUP = 'OPEN_SALE_POPUP';
export const CLOSE_SALE_POPUP = 'CLOSE_SALE_POPUP';

const addSaleToKeros = store => next => action => {
	if (action.type === EDIT_PROPERTY_STAGE && action.http.request.data.stage === SOLD) {
		store.dispatch({type: OPEN_SALE_POPUP});
	}

	next(action);
};

export default addSaleToKeros;
