export default theme => ({
	root: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		height: theme.spacing.bottomNavigation,
		zIndex: 100,
		boxShadow: theme.shadows[2],
		paddingBottom: 'env(safe-area-inset-bottom)'
	},
	icon: {
		width: '32px',
		height: '32px'
	},
	button: {
		borderLeftColor: theme.palette.divider,
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',

		'&:first-child': {
			border: 0
		}
	},
	label: {
		fontSize: '13px'
	},
	otherLabel: {
		fontSize: 16
	}
});
