import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const style = {
	width: 24,
	height: 24,
	fill: '#fff',
	display: 'block',
	float: 'left',
	cursor: 'pointer',
	marginRight: 8
};

const BackButton = ({to, history}) => (
	<SvgIcon
		viewBox="0 0 24 24"
		style={style}
		onClick={() => history.push(to.link)}
	>
		<path d="M12.707 5.707a1 1 0 0 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L7.414 13H19a1 1 0 1 0 0-2H7.414l5.293-5.293z"/>
	</SvgIcon>
);

BackButton.propTypes = {
	to: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default BackButton;
