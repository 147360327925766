import {compose, mapProps} from 'recompose';
import {withRouter} from 'react-router';
import {withTranslation as translate} from 'react-i18next';
import BottomNavigation from './bottom-nav';

const enhance = compose(
	withRouter,
	mapProps(({data, ...rest}) => ({
		...rest,
		data: data.filter(x => !x.disabled)
	})),
	translate('navigation')
);

export default enhance(BottomNavigation);
